import React from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import Home from './components/Home/Home';
//import Signup from './components/Signup/Signup';
import Login from './components/Login/Login';
import Contact from './components/Contact/Contact';
import RequestLesson from './components/RequestLesson/RequestLesson';
import GeneralCondition from './components/GeneralCondition/GeneralCondition';
import GeneralTeacherCondition from './components/GeneralTeacherCondition/GeneralTeacherCondition';
import Notfound from './components/Notfound/Notfound';
import TeacherLesson from './components/TeacherLesson/TeacherLesson';
import StudentLesson from './components/StudentLesson/StudentLesson';
import LessonDetails from './components/LessonDetails/LessonDetails';
import StudentAccount from './components/StudentAccount/StudentAccount';
import TeacherAccount from './components/TeacherAccount/TeacherAccount';
import LessonReport from './components/LessonReport/LessonReport';
import AdhocLesson from './components/AdhocLesson/AdhocLesson';
import FrontLogin from './components/FrontLogin/FrontLogin';
import Header from './components/Header/Header';
//import Message from './components/Message/Message';

const Routes = () => (
  <HashRouter>
  <div>
    <Switch>
      <Route exact path='/' component={Home} />
      {/* <Route path='/signup' component={Signup} /> */}
      <Route path='/login' component={Login} />
      <Route path='/contact' component={Contact} />
      <Route path='/requestlesson/:id' component={RequestLesson} />
      <Route path='/requestlesson' component={RequestLesson} />
      <Route path='/generalcondition' component={GeneralCondition} />
      <Route path='/generalteachercondition' component={GeneralTeacherCondition} />
      <Route path='/teacherlesson' component={TeacherLesson} />
      <Route path='/studentlesson' component={StudentLesson} />
      <Route path='/lessondetails/:id' component={LessonDetails} />
      <Route path='/studentaccount' component={StudentAccount} />
      <Route path='/teacheraccount' component={TeacherAccount} />
      <Route path='/lessonreport' component={LessonReport} />
      <Route path='/adhoclesson' component={AdhocLesson} />
      <Route path='/frontlogin/:id' component={FrontLogin} />
      <Route path='*' component={Home} />
    </Switch>
	</div>
  </HashRouter>
)

export default Routes;