import React, { Component } from 'react';

class HomeSlider extends Component {
  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    let sliders;
    var activeslider;
    //console.log(this.props.sliders);

    if(this.props.sliders) {
      sliders = this.props.sliders.map((slider,index) => {
      if(index<1) {
        activeslider = 'carousel-item active';
      } else {
        activeslider = 'carousel-item';
      }
        return (
          <div className={activeslider}>
            <img className="d-block w-100" src={slider.image} />
            <div className="overlay-dark text-center">
              <div className="desc_content">
                <p>{language_arr.slider_description}</p>
                <h1>{language_arr.slider_title}</h1>
                <a href="#/requestlesson" className="btn bnnr-btn">{language_arr.request_lesson_now}</a>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="wrap">
        <div className="header-slider">
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              {sliders}
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div> 
    );

  }
}

export default HomeSlider;
