import React, { Component } from 'react';
import './LessonDetails.css';
import $ from 'jquery';
import Moment from 'react-moment';

class DetailsBody extends Component {
	
	constructor(props) {
		super(props);	
		this.state = {
			job_id : ''
		};
		this.getSingleLesson = this.getSingleLesson.bind(this);
	  }
	  
	  componentDidMount() {
		this.getSingleLesson(); 
	  }
	  
	  getSingleLesson(){
		  
	  }
  render() {
	  
	  var job_id = this.props.data.id;
	  var job_title = this.props.data.title;
	  var job_description = this.props.data.description;
	  var job_proposal_start = this.props.data.proposal_start;
	  var job_proposal_end = this.props.data.proposal_end;
	  var job_start = this.props.data.start;
	  var job_end = this.props.data.end;
	  var job_nr_person = this.props.data.nr_person;
	  var job_price = this.props.data.cost;
	  var job_region = this.props.data.id_region;
	  var job_location = this.props.data.location;
	  var job_id_service = this.props.data.id_service;
	  var creator_name = this.props.data.job_user_creator;
	  var creator_family_name = this.props.data.family_name;
	  var user_email = this.props.data.user_email;
	  var user_tel = this.props.data.tel;
	  
	  let jobnrList = [];
	  for (var i = 1; i <= 25; i++) {
		jobnrList.push(<option value={i} selected={i == job_nr_person}>{i}</option>);
	  }
    return (
		<div className="main">
          <div className="lession-bg">
            <div className="container">
                <div className="lession-body">
                    <div className="row">
                      <div className="col-md-12 text-center lession-title">
                        <h2>Lesson Details</h2>
                      </div>
                    </div>
                    <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <a className="nav-item nav-link active" id="nav-Basic-tab" data-toggle="tab" href="#nav-Basic" role="tab">Basic lesson info</a>
                      <a className="nav-item nav-link" id="nav-Messages-tab" data-toggle="tab" href="#nav-Messages" role="tab">Messages</a>
                      <a className="nav-item nav-link" id="nav-Feedback-tab" data-toggle="tab" href="#nav-Feedback" role="tab">Feedback</a>
                    </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-Basic" role="tabpanel" aria-labelledby="nav-Basic-tab">
                    <form>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-md-6 text-left">
                            <label className="label-name">Job ID : </label>
                            <label className="label-data"><p>{this.state.job_id}</p></label>
                          </div>
                          <div className="col-md-6 text-right">
                            <label className="label-name">Job Title : </label>
                            <label className="label-data"><p>{job_title}</p> </label>
                          </div>
                          <div className="col-md-12">
                            <label className="label-name">Job Description : </label>
                            <label className="label-data"><p>{job_description}</p></label>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">								
                          <div className="col-md-6">
                            <label className="label-name">Job Proposal Start : </label>
                            <label className="label-data"><p><Moment format="DD/MM/YYYY HH:mma">{job_proposal_start}</Moment></p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">Job Proposal End : </label>
                            <label className="label-data"><p><Moment format="DD/MM/YYYY HH:mma">{job_proposal_end}</Moment></p></label>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">		
                          <div className="col-md-6">
                            <label className="label-name">Job Start : </label>
                            <label className="label-data"><p><Moment format="DD/MM/YYYY HH:mma">{job_start}</Moment></p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">Job End : </label>
                            <label className="label-data"><p><Moment format="DD/MM/YYYY HH:mma">{job_end}</Moment></p></label>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-md-4">
                            <label className="label-name">Job Nr Person : </label>
                            <label className="label-data">
                              <select className="form-control">
							  {jobnrList}
                              </select>
                              </label>
                            <i className="fa fa-pencil" aria-hidden="true"></i>	
                            
                          </div>
                          <div className="col-md-4">
                            <label className="label-name">Job Price : </label>
                            <label className="label-data"><p>{job_price} /- </p></label>
                          </div>
                          <div className="col-md-4">
                            <label className="label-name">Job ID Region : </label>
                            <label className="label-data"><p>{job_region}</p></label>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="label-name">Job Location : </label>
                            <label className="label-data">									
                            <input className="form-control" type="text" placeholder="Phone No" value={job_location} />
                            </label>
                            <i className="fa fa-pencil" aria-hidden="true"></i>	
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">Job ID Service : </label>
                            <label className="label-data"><p>{job_id_service} </p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">User Name( Name of Student ) : </label>
                            <label className="label-data"><p>{creator_name}</p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">User Family Name ( Of Student ) : </label>
                            <label className="label-data"><p>{creator_family_name}</p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">User Email (of student) : </label>
                            <label className="label-data"><p>{user_email} </p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">Job tel : </label>
                            <label className="label-data">									
                            <input className="form-control" type="text" placeholder="Phone No" value={user_tel} />
                            </label>
                            <i className="fa fa-pencil" aria-hidden="true"></i>									
                          </div>			
                          </div>
                        </div>
                        <div className="details-box">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <button type="submit" className="btn btn_dtlsupdate">Update</button>
                          </div>
                        </div>
                        </div>
                      </form>
                      </div>
                      <div className="tab-pane fade" id="nav-Messages" role="tabpanel" aria-labelledby="nav-Messages-tab">						
                        <div className="message-box">
                            <div className="message-content">
                              <div className="row">
                                  <div className="col-md-2">
                                    <label className="message-name">rajib@gmail.com</label>
                                  </div>
                                  <div className="col-md-7">
                                    <label className="message-data"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p></label>
                                  </div>
                                  <div className="col-md-2">													
                                    <label className="message-data"><p>12/05/2018 09:15am</p></label>
                                  </div>
                                  <div className="col-md-1">
                                    <a href="#" className="show_dtls"><i className="fa fa-eye" aria-hidden="true"></i></a>									
                                  </div>
                              </div>
                            </div>
                            <div className="message-content">
                              <div className="row">
                                  <div className="col-md-2">
                                    <label className="message-name">rajib@gmail.com</label>
                                  </div>
                                  <div className="col-md-7">
                                    <label className="message-data"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p></label>
                                  </div>
                                  <div className="col-md-2">													
                                    <label className="message-data"><p>12/05/2018 09:15am</p></label>
                                  </div>
                                  <div className="col-md-1">
                                    <a href="#" className="show_dtls"><i className="fa fa-eye" aria-hidden="true"></i></a>									
                                  </div>
                              </div>
                            </div>
                            <div className="message-content">
                              <div className="row">
                                  <div className="col-md-2">
                                    <label className="message-name">rajib@gmail.com</label>
                                  </div>
                                  <div className="col-md-7">
                                    <label className="message-data"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p></label>
                                  </div>
                                  <div className="col-md-2">													
                                    <label className="message-data"><p>12/05/2018 09:15am</p></label>
                                  </div>
                                  <div className="col-md-1">
                                    <a href="#" className="show_dtls"><i className="fa fa-eye" aria-hidden="true"></i></a>									
                                  </div>
                              </div>
                            </div>
                        </div>
                        
                        <div className="message-details-box">		
                          <div className="message-details-content">
                            <div className="row">
                            <div className="col-md-12 text-left">
                                <div className="btnback">
                                  <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                </div>
                              </div>
                              <div className="col-md-6 text-left">
                                <label className="message-name">Sender :</label>
                                <label className="message-data"><p>rajib@gmail.com</p></label>
                              </div>
                              <div className="col-md-6 text-right">
                                <label className="message-name">Receiver :</label>
                                <label className="message-data"><p>jhone@gmail.com</p></label>
                              </div>
                              <div className="col-md-12 text-left">
                                <label className="message-name">Date and Time :</label>
                                <label className="message-data"><p>12/05/2018 09:15am</p></label>
                              </div>
                              <div className="col-md-12">
                                <label className="message-data"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></label>
                              </div>
                              <div className="col-md-12">
                                <form>
                                  <div className="form-group">
                                    <label>Reply</label>
                                    <textarea className="form-control" rows="3"></textarea>
                                    </div>
                                    <div className="form-group">
                                  <button type="submit" className="btn btn_message_send">Send</button>
                                  </div>
                                </form>											
                              </div>
                              </div>
                            </div>
                          </div>
                        
                      </div>
                      <div className="tab-pane fade" id="nav-Feedback" role="tabpanel" aria-labelledby="nav-Feedback-tab">
                        <div className="feedback-content">
                              <div className="feedback-box">
                                <div className="row">
                                <div className="col-md-6 text-left">
                                  <label className="feedback-data"><i className="fa fa-share" aria-hidden="true"></i></label>
                                  <label className="feedback-data"><p>rajib@gmail.com</p></label>
                                </div>
                                <div className="col-md-6 text-right">
                                  <label className="feedback-data"><i className="fa fa-calendar" aria-hidden="true"></i></label>
                                  <label className="feedback-data"><p>12/05/2019 08:30pm</p></label>
                                </div>
                                <div className="col-md-12 text-justify">
                                  <label className="feedback-data"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></label>										
                                </div>
                                </div>
                              </div>
                              
                              <div className="feedback-box">
                                <div className="row">
                                <div className="col-md-6 text-left">
                                  <label className="feedback-data"><i className="fa fa-share" aria-hidden="true"></i></label>
                                  <label className="feedback-data"><p>rajib@gmail.com</p></label>
                                </div>
                                <div className="col-md-6 text-right">
                                  <label className="feedback-data"><i className="fa fa-calendar" aria-hidden="true"></i></label>
                                  <label className="feedback-data"><p>12/05/2019 08:30pm</p></label>
                                </div>
                                <div className="col-md-12 text-justify">
                                  <label className="feedback-data"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></label>										
                                </div>
                                </div>
                              </div>
                              
                              <div className="feedback-box">
                                <div className="row">
                                <div className="col-md-6 text-left">
                                  <label className="feedback-data"><i className="fa fa-share" aria-hidden="true"></i></label>
                                  <label className="feedback-data"><p>rajib@gmail.com</p></label>
                                </div>
                                <div className="col-md-6 text-right">
                                  <label className="feedback-data"><i className="fa fa-calendar" aria-hidden="true"></i></label>
                                  <label className="feedback-data"><p>12/05/2019 08:30pm</p></label>
                                </div>
                                <div className="col-md-12 text-justify">
                                  <label className="feedback-data"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></label>										
                                </div>
                                </div>
                              </div>
                        </div>
                      </div>
                    </div>             
                </div>
            </div>
          </div>
        </div>
    );
  }
}

export default DetailsBody;