import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import './Login.css';
import Header from '../Header/Header';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
        'email':'',
        'femail':'',
        'password':'',
        'error':'',
        'success':'',
        'ferror':'',
        'fsuccess':'',
        'email_error':'',
        'femail_error':'',
        'password_error':'',
		'current_language':''
    }
    this.change = this.change.bind(this);
    this.formValidate = this.formValidate.bind(this);
    this.forgotValidate = this.forgotValidate.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.forgotSubmit = this.forgotSubmit.bind(this);
	this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
	this.getPageTitle = this.getPageTitle.bind(this);
  }

	componentDidMount() {
		this.getPageTitle();
		this.getCurrentLanguage();
	}
	
	getCurrentLanguage(e){
		$.ajax({
          url:  window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            var current_language = data.records[0].value;
			{ /* this.setState({'current_language':current_language}, function() {
				this.getCurrentLanguage();
			}); */ }
			this.setState({'current_language':current_language});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
	}
	
	change(e) {
		this.setState({[e.target.name]: e.target.value});
	}
	
	getPageTitle(){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj); 
		document.title = language_arr.page_title;
	  }

  forgotValidate(e) {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    var formValid = true;
    if(this.state.femail==='') {
        formValid = false;
        this.setState({'femail_error': '*'+language_arr.email_err});
    } else {
        this.setState({'femail_error': ''});
    }

    return formValid;
  }

  formValidate(e) {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    var formValid = true;
    if(this.state.email==='') {
        formValid = false;
        this.setState({'email_error': '*'+language_arr.email_err});
    } else {
        this.setState({'email_error': ''});
    }
    if(this.state.password==='') {
        formValid = false;
        this.setState({'password_error': '*'+language_arr.password_err});
    } else {
        this.setState({'password_error': ''});
    }

    return formValid;
  }

  forgotSubmit(e) {
    e.preventDefault();
    this.setState({'fsuccess': ''});
    this.setState({'ferror': ''});
    console.log(this.state);

    if(this.forgotValidate()) {
      const fd = new FormData();
      fd.append('email', this.state.femail);
	  fd.append('current_language', this.state.current_language);

      axios.post( window.$apiBaseUrl+'user/exist.php', fd)
        .then(res => {
          console.log(res);
          if(res.data.status == '200') {
            this.setState({'fsuccess':res.data.message}, function() {
              this.setState({'femail':''});
            });
          } else {
            this.setState({'ferror': ReactHtmlParser(res.data.message)});
          }
        }).catch(error => {
           console.log(error);
        });
    }
  }

  loginSubmit(e) {
    e.preventDefault();
    this.setState({'success': ''});
    this.setState({'error': ''});
    //console.log(this.state);

    if(this.formValidate()) {
      const fd = new FormData();
      fd.append('email', this.state.email);
      fd.append('password', this.state.password);
	  fd.append('current_language', this.state.current_language);

      axios.post( window.$apiBaseUrl+'user/exist.php', fd)
        .then(res => {
          //console.log(res.data);
          if(res.data.status == '200') {
            this.setState({'success':res.data.message}, function() {
              this.setState({'email':''});
              this.setState({'password':''});
              var userString =  JSON.stringify(res.data.records);
              localStorage.setItem("loggedInUser", userString);
			  var user_type = res.data.records.type;
			  if(user_type=="teacher"){
				setTimeout(function(){ 
					window.location.href='#/teacherlesson';
				}, 1500);
			  }
			  if(user_type=="student"){
				setTimeout(function(){ 
					window.location.href='#/studentlesson';
				}, 1500);
			  }
              
            });
          } else {
            this.setState({'error': ReactHtmlParser(res.data.message)});
          }
        }).catch(error => {
           console.log(error);
        });
    }
  }

  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    return (
	<div>
	<Header />
      <div className="Login">
        <div className="main">
          <div className="login-bg">
            <div className="login-bg-overlay">
              <div className="login-form">
                <h3>{language_arr.login_now}</h3>
                <form onSubmit={this.loginSubmit}>
                  <div className="form-group">
                    <input type="email" name="email" id="email" placeholder={language_arr.enter_your_email} className="form-control" value={this.state.email} onChange={this.change} />
                    <div className="wqerror">{this.state.email_error}</div>
                  </div>
                  <div className="form-group">
                    <input type="password" name="password" id="password" placeholder={language_arr.enter_password} className="form-control" value={this.state.password} onChange={this.change} />
                    <div className="wqerror">{this.state.password_error}</div>
                  </div>	
                  <div className="form-group">
                    <input type="submit" value={language_arr.login_btn} className="login-btn" />
                    <div className="wqerror text-center">{this.state.error}</div>
                    <div className="wqsuccess text-center">{this.state.success}</div>
                  </div>
                  <div className="form-group nomarg">
                    <div className="forget-pass">
                      <p>
                        {/* Don't have an account? <a href="/signup">Sign Up</a><br/> */}
                        <a href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal">{language_arr.forgot_your_pass}</a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{language_arr.forgot_password}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.forgotSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                  <input type="email" name="femail" id="femail" placeholder={language_arr.enter_your_email} className="form-control" value={this.state.femail} onChange={this.change} />
                  <div className="wqerror">{this.state.femail_error}</div>
                  </div>
                  <div className="form-group">
                      <input type="submit" value={language_arr.send} className="login-btn" />
                      <div className="wqerror text-center">{this.state.ferror}</div>
                      <div className="wqsuccess text-center">{this.state.fsuccess}</div>
                  </div>
                </div>
              </form>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">{language_arr.close}</button>
              </div>
            </div>
          </div>
        </div>

      </div>
	  </div>
    );
  }
}

export default Login;
