import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import HomeSlider from './HomeSlider';
import HomeTestimonial from './HomeTestimonial';
import './Home.css';
import {Link} from "react-router-dom"
import RequestLesson from '../RequestLesson/RequestLesson';
import Header from '../Header/Header';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'sliders':[],
      'testimonials':[],
	  'teacher_number': 0,
	  'student_number': 0,
	  'done_tasks': 0,
	  'total_duaration': 0,
	  'GeneralOptions': ''
    }
    this.getSliders = this.getSliders.bind(this);
    this.getTestimonials = this.getTestimonials.bind(this);
    this.getTeacherNumber = this.getTeacherNumber.bind(this);
    this.getDoneTasks = this.getDoneTasks.bind(this);
    this.requestLesson = this.requestLesson.bind(this);
	this.getGeneralOptions = this.getGeneralOptions.bind(this);
	this.getPageTitle = this.getPageTitle.bind(this);
  }

  getSliders() {
    $.ajax({
      url: window.$apiBaseUrl+'banner/read.php',
      type: 'GET',
      dataType: 'json',
      cache: false,
      contentType: false,
      processData:false,
      success: function(data) {
        this.setState({'sliders':data.records}, function() {
        })
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
      }
    });
  }
  
  getGeneralOptions() {
    const fd = new FormData();
    fd.append('id_option', '14');

    axios.post(window.$apiBaseUrl+'options/read.php', fd)
    .then(res => {
      this.setState({'GeneralOptions':res.data.records[0].option_value});
      //console.log(res);
    }).catch(error => {
       console.log(error);
    });
  }


  getTestimonials() {
    $.ajax({
      url: window.$apiBaseUrl+'testimonial/read.php',
      type: 'GET',
      dataType: 'json',
      cache: false,
      contentType: false,
      processData:false,
      success: function(data) {
        this.setState({'testimonials':data.records}, function() {
          //console.log(this.state);
        })
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
      }
    });
  }
  
  getTeacherNumber(){
	const fd = new FormData();
    fd.append('user_role', 'teacher');
	$.ajax({
      url: window.$apiBaseUrl+'user/read_all_user_by_role.php',
      type: 'POST',
	  data: fd,
      dataType: 'json',
      cache: false,
      contentType: false,
      processData:false,
      success: function(data) {
		  { /* this.setState({'teacher_number':data.records.length}, function() {
			this.getTeacherNumber();
		  }); */ }
		  this.setState({'teacher_number':data.records.length});
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
      }
    });
  }
  
  getStudentNumber(){
	const fd = new FormData();
    fd.append('user_role', 'student');
	$.ajax({
      url: window.$apiBaseUrl+'user/read_all_user_by_role.php',
      type: 'POST',
	  data: fd,
      dataType: 'json',
      cache: false,
      contentType: false,
      processData:false,
      success: function(data) {
		this.setState({'student_number':data.records.length}, function() {
			this.getTeacherNumber();
		});
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
      }
    });  
  }

  getDoneTasks(){
	const fd = new FormData();
	fd.append('status', 'passed');
	fd.append('sector', '');
	fd.append('subsector', '');
	fd.append('service', '');
	$.ajax({
		url: window.$apiBaseUrl+'job/read.php',
		type: 'POST',
		data: fd,
		dataType: 'json',
		cache: false,
		contentType: false,
		processData:false,
		success: function(data) {
			var task_number = data.records.length;
			this.setState({'done_tasks':data.records.length});
			var total_duaration = 0;
			for(var i=0; i<task_number; i++){
				var single_lesson = data.records[i];
				
				var start_time = new Date(single_lesson.start).getTime();
				var end_time = new Date(single_lesson.end).getTime();
				var lesson_duaration = (end_time - start_time)/(1000*60*60);
				//console.log(single_lesson.id+"=="+lesson_duaration);
				total_duaration = total_duaration + lesson_duaration;
				this.setState({'total_duaration':Math.round(total_duaration)});
				
			}
			
		}.bind(this),
		error: function(xhr, status, err) {
			console.log(err);
		}
	});
  }
  
  componentWillMount() {
    this.getSliders();
    this.getTestimonials();
	this.getTeacherNumber();
    this.getStudentNumber();
    this.getDoneTasks();
	this.getGeneralOptions();
  }

  componentDidMount() {
	//document.title = "Home";
    this.getPageTitle();
    this.getSliders();
    this.getTestimonials();
    this.getTeacherNumber();
    this.getDoneTasks();
	this.getGeneralOptions();
  }
  
  getPageTitle(){
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj); 
	document.title = language_arr.page_title;
  }
  
  requestLesson(e){
		e.preventDefault();
		var lesson_value = e.target.getAttribute('data-value');
		
		return (<div>
		  <RequestLesson lesson_value={ lesson_value } />
		  </div>
		);
		
  }

  render() {
	//alert(this.state.teacher_number);
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    return (
	<div>
	<Header />
      <div className="Home">
		
        <HomeSlider sliders={this.state.sliders} />       

        <div className="sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center help-headline-txt">
                <h2>{language_arr.need_help}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="sec bggray">
	        <div className="container">
	          <div className="row">
		          <div className="col-md-4">		
		            <div className="help-item math-color">
		              <a href="#/requestlesson/1">
						<img src="images/help1.png" className="hoverimg" />
						`<div className="help-item-caption text-center">
						  <h1>{language_arr.mathematics}</h1>
						</div>
		              </a>
		            </div>
		          </div>
		          <div className="col-md-4">		
		            <div className="help-item german-color">
		              <a href="#/requestlesson/4">
						<img src="images/help2.png"  className="hoverimg" />
						`<div className="help-item-caption text-center">
						  <h1>{language_arr.german}</h1>
						</div>
		              </a>
		            </div>
		          </div>	
              <div className="col-md-4">		
                <div className="help-item physics-color">
                  <a href="#/requestlesson/3">
		                <img src="images/help3.png"  className="hoverimg" />
			              `<div className="help-item-caption text-center">
				              <h1>{language_arr.physics}</h1>
			              </div>
		              </a>
		            </div>
		          </div>
	          </div>

            <div className="row">
		          <div className="col-md-4">		
		            <div className="help-item english-color">
		              <a href="#/requestlesson/5">
                    <img src="images/help4.png" className="hoverimg" />
                    `<div className="help-item-caption text-center">
                      <h1>{language_arr.english}</h1>
                    </div>
		              </a>
		            </div>
		          </div>
		          <div className="col-md-4">		
		            <div className="help-item chemistry-color">
		              <a href="#/requestlesson/2">
                    <img src="images/help5.png"  className="hoverimg" />
                    `<div className="help-item-caption text-center">
                      <h1>{language_arr.chemistry}</h1>
                    </div>
		              </a>
		            </div>
		          </div>	
              <div className="col-md-4">		
                <div className="help-item more-color">
                  <a href="#/requestlesson">
		                <img src="images/help6.png"  className="hoverimg" />
			              `<div className="help-item-caption text-center">
				              <h1>{language_arr.more}</h1>
			              </div>
		              </a>
		            </div>
		          </div>
	          </div>
	        </div>
        </div>   

       {/* <div className="sec">
          <div className="special-events">
    	      <div className="special-events-overlay">
        	    <div className="triangle-bottomright">
            	  <div className="special-events-content">
					<h2>{language_arr.become_a_teacher}</h2>
					{ReactHtmlParser(this.state.GeneralOptions, true)}
					<a href="http://associazione-mise.mozello.ch/lezioni-private/docenti-candidati/" target="_blank">
					<button type="button" className="btn btn-Teacher">{language_arr.become_teacher}</button>
					</a>
                </div>
              </div>
            </div>
          </div>
        </div>  

         <div className="sec bglight">
          <div className="container">
	          <div className="row">
	            <div className="col-md-5">
	              <div className="request_side_img">
	                <img src="/images/qualified.png" className="" />
	              </div>
	            </div>
	            <div className="col-md-7">
		            <div className="course-form">
                  <h1><span className="cont_txt">Request</span> Your Course </h1>
                  <form>
                    <div className="form-group">
                      <select id="inputState" className="form-control">
                        <option selected>Select School</option>
                        <option>...</option>
                      </select>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <select id="inputState" className="form-control">
                          <option selected>Level</option>
                          <option>...</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <input type="phone" className="form-control" placeholder="Phone" />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <select id="inputState" className="form-control">
                          <option selected>Subject</option>
                          <option>...</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <input type="date" className="form-control" placeholder="Date of Birth" />
                      </div>
                    </div> 
                    <div className="form-row">
                      <div className="form-group col-md-12">				 
                        <button type="submit" className="btn btn-request">REQUEST A LESSON NOW</button>
                      </div>
                    </div>
                  </form>
		            </div>
	            </div>
	          </div>
          </div>
		</div> 

        <div className="sec">
	        <div className="teamnum">
		        <div className="teamnum-overlay">
			        <div className="container">
			          <div className="row">
			            <div className="col-md-3 text-center">
			              <div className="count">
			                <h2 className="numscroller" data-min='0' data-max={this.state.teacher_number} data-delay='8' data-increment='2'> {this.state.teacher_number} 63</h2>
			                <img src="images/after-team.png" />
			                <p>{language_arr.teacher}</p>
			              </div>
			            </div>
						<div className="col-md-3 text-center">
							<div className="count">
								<h2 className="numscroller" data-min='0' data-max={this.state.student_number} data-delay='8' data-increment='2'>{this.state.student_number} 1876</h2>
								<img src="images/after-team.png" />
								<p>{language_arr.students}</p>
							</div>
						</div>	
						<div className="col-md-3 text-center">
							<div className="count">
								<h2 className="numscroller" data-min='0' data-max={this.state.done_tasks} data-delay='8' data-increment='2'> {this.state.done_tasks} 16</h2>
								<img src="images/after-team.png" />
								<p>{language_arr.lesson_done}</p>
							</div>
						</div>	
						<div className="col-md-3 text-center">
							<div className="count">
								<h2 className="numscroller" data-min='1' data-max={this.state.total_duaration} data-delay='8' data-increment='10'>{this.state.total_duaration}}8</h2>
								<img src="images/after-team.png" />
								<p>{language_arr.lesson_done_hours}</p>
							</div>
						</div>		
			          </div>
			        </div>
		        </div>
	        </div>
        </div>
        
        <HomeTestimonial testimonials={this.state.testimonials} />*/}
      </div>
	  </div>
    );
  }
}

export default Home;
