import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Moment from 'react-moment';

class LessonList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			'wqfeedback': '',
			'wqpstatus': '',
			'wqjobid': '',
			'wqworker': '',
			'wqgiver': '',
			'wq_start_class': 'start-date',
			'wq_start': '',
			'wq_end': '',
			'current_language':'',
			
			'current_user_id':'',
			'current_user_email':'',
			'lessonId':'',
			'offer_id':'',
			'worker_id':'',
			'worker_email':'',
			'receiver_email':'',
			'receiver_id':'',
			'message':'',
			'final_message':''
		}
		this.change = this.change.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.showPopup = this.showPopup.bind(this);
		this.feedbackSubmit = this.feedbackSubmit.bind(this);
		this.modalClose = this.modalClose.bind(this);
		this.showMessageModal = this.showMessageModal.bind(this);
		this.closeMessageModal = this.closeMessageModal.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
	}

	showPopup(e) {
		e.preventDefault();
		var userDetail = JSON.parse(localStorage.getItem("loggedInUser"));
		var wqpstatus = e.target.getAttribute('data-status');
		var wqjobid = e.target.getAttribute('data-id');
		var wqworker = e.target.getAttribute('data-worker');
		if(typeof userDetail !== 'undefined' && userDetail != null) {
			var wqgiver = userDetail.id_user;
		} else {
			var wqgiver = e.target.getAttribute('data-giver');
		}

		this.setState({ 'wqpstatus': wqpstatus });
		this.setState({ 'wqjobid': wqjobid });
		this.setState({ 'wqworker': wqworker });
		this.setState({ 'wqgiver': wqgiver });

		$('.custom-pop-wrap').fadeIn();	
		$('.custom-pop').show();
	}
	
	modalClose(){
		$('.custom-pop-wrap').fadeOut();	
		$('.custom-pop').hide();
	}

	feedbackSubmit(e) {
		const fd = new FormData();
		fd.append('id_job', this.state.wqjobid);
		fd.append('action', this.state.wqpstatus);
		fd.append('id_worker', this.state.wqworker);
		fd.append('id_giver', this.state.wqgiver);
		fd.append('feedback', this.state.wqfeedback);

		$.ajax({
			url: window.$apiBaseUrl+'job-log/create.php',
			type: 'POST',
			data: fd,
			dataType: 'json',
			cache: false,
			contentType: false,
			processData:false,
			success: function(data) {
				$('.custom-pop-wrap').fadeOut();	
				$('.custom-pop').hide();
				this.setState({'wqjobid':''});
				this.setState({'wqpstatus':''});
				this.setState({'wqworker':''});
				this.setState({'wqgiver':''});
				this.setState({'wqfeedback':''});
				$('#wqfeedback').val('');
			}.bind(this),
			error: function(xhr, status, err) {
				console.log(err);
			}
		});
	}
	
	showMessageModal(e){
		e.preventDefault();
		var lessonId = e.target.getAttribute('lesson-id');
		var userDetail = localStorage.getItem("loggedInUser");
		var user_obj = JSON.parse(userDetail);
		var current_user_type = user_obj.type;
		var current_user_email = user_obj.email;
		var current_user_id = user_obj.id_user;
		const fd = new FormData();
		fd.append('jobid', lessonId);
		$.ajax({
			url: window.$apiBaseUrl+'job/read.php',
			type: 'POST',
			dataType: 'json',
			data: fd,
			cache: false,
			contentType: false,
			processData:false,
			success: function(data) {
				var response = data.records[0];
				var offer_id = response.offer_id;
				var worker_id = response.id_worker;
				var worker_email = response.worker_email;
				var user_email = response.user_email;
				var id_giver = response.id_giver;
				if(current_user_type == 'teacher'){
					this.setState({receiver_id : id_giver});
					this.setState({receiver_email : user_email});
				}else{
					this.setState({receiver_id : worker_id});
					this.setState({receiver_email : worker_email});
				}
				this.setState({message : ''});
				this.setState({current_user_id : current_user_id});
				this.setState({current_user_email : current_user_email});
				this.setState({lessonId : lessonId});
				this.setState({offer_id : offer_id});
				//this.setState({worker_id : worker_id});
				//this.setState({worker_email : worker_email});
				$('#message').val('');
				$('#messagemodel').fadeIn();	
				}.bind(this),
			error: function(xhr, status, err) {
				console.log(err);
			}
        });
		//console.log(this.state);
		
		
	}

	closeMessageModal(e){
		e.preventDefault();
		$('#messagemodel').fadeOut();	
	}
	
	sendMessage(e){
		var messageValid = true;
		var sender_id = this.state.current_user_id;
		var receiver_id = this.state.receiver_id;
		var offer_id = this.state.offer_id;
		var message = this.state.message;
		if(message == ''){
			messageValid = false;
			$("#message").css('border','2px solid red');
		}else {
            $("#message").css('border','1px solid #ced4da');
        }
		if(messageValid){
			const fd = new FormData();
			fd.append('senderid', sender_id);
			fd.append('receiverid', receiver_id);
			fd.append('message', message);
			fd.append('offerid', offer_id);
			fd.append('current_language', this.state.current_language);
			$.ajax({
				type: "POST",
				enctype: 'multipart/form-data',
				url: window.$apiBaseUrl+"email/create.php",
				data: fd,
				processData: false,
				contentType: false,
				cache: false,
				success: function (data) {
					this.setState({message : ''});
					this.setState({final_message : data.message});
					$('#message').val('');
					setTimeout(function(){ 
						$('#messagemodel').fadeOut();
					}, 1500);	
					//console.log(data);
				}.bind(this),
				error: function (e) {
					console.log("ERROR : ", e);
				}
			}); 
		}
	}
	
    change(e) {
        this.setState({[e.target.name]: e.target.value});
    }
	
	componentDidMount() {
		this.getCurrentLanguage();
	}
	
	getCurrentLanguage(e){
		$.ajax({
          url: window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            var current_language = data.records[0].value;
			{ /* this.setState({'current_language':current_language}, function() {
				this.getCurrentLanguage();
			}); */ }
			this.setState({'current_language':current_language});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
	}

	changeStatus(e) {
		e.preventDefault();
		var userDetail = JSON.parse(localStorage.getItem("loggedInUser"));

		var wqsector = e.target.getAttribute('data-sector');
		var wqsubsector = e.target.getAttribute('data-subsector');
		var wqservice = e.target.getAttribute('data-service');
		var wqtitle = e.target.getAttribute('data-title');
		var wqdescription = e.target.getAttribute('data-description');

		var wqpstatus = e.target.getAttribute('data-status');
		var wqjobid = e.target.getAttribute('data-id');
		var wqworker = e.target.getAttribute('data-worker');

		var wqstart = e.target.getAttribute('data-start');
		var wqend = e.target.getAttribute('data-end');
		var wqsplitstart = wqstart.split(" ");
		var wqsplitend = wqend.split(" ");

		var sudipcheck = false;

		if(wqpstatus == 'pending') {
			if(typeof userDetail !== 'undefined' && userDetail != null) {
				var wqgiver = userDetail.id_user;
			} else {
				var wqgiver = e.target.getAttribute('data-giver');
			}
		} else if(wqpstatus == 'approved') {
			if(typeof userDetail !== 'undefined' && userDetail != null) {
				var wqgiver = userDetail.id_user;
			} else {
				var wqgiver = e.target.getAttribute('data-giver');
			}
		} else {
			var wqgiver = e.target.getAttribute('data-giver');
		}
		var wqadmin = e.target.getAttribute('data-admin');

		const fd = new FormData();
		fd.append('id_job', wqjobid);
		fd.append('action', wqpstatus);
		fd.append('id_worker', wqworker);
		fd.append('id_giver', wqgiver);
		fd.append('id_admin', wqadmin);

		fd.append('job_sector', wqsector);
		fd.append('job_subsector', wqsubsector);
		fd.append('job_service', wqservice);
		fd.append('title', wqtitle);
		fd.append('description', wqdescription);

		if(wqpstatus == 'approved') {
			if(wqsplitstart[0] == "0000-00-00" && wqsplitend[0] == "0000-00-00") {
				confirmAlert({
					title: 'Confirm Start Date & End Date',
					message: 'You must enter lesson start date & lesson end date before approve this lesson',
					buttons: [
						{
							label: 'OK'
						}
					]
				})
				sudipcheck = false;
			} else {
				sudipcheck = true;
			}
		} else {
			sudipcheck = true;
		}

		if(sudipcheck) {
			$.ajax({
				url: window.$apiBaseUrl+'job-log/create.php',
				type: 'POST',
				data: fd,
				dataType: 'json',
				cache: false,
				contentType: false,
				processData:false,
				success: function(data) {
					//console.log(data);
				}.bind(this),
				error: function(xhr, status, err) {
					console.log(err);
				}
			});
		}
	}

  render() {
    let lessons;
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    if(this.props.lessons) {
			lessons = this.props.lessons.map((lesson,index) => {
				if(lesson.job_log_latest_status == 'pool') {
					return (
						<tr>
							<td className="text-center"><Moment format="DD.MM.YYYY HH:mm:ss">{lesson.creation_time}</Moment></td>
							<td className="text-center">{lesson.job_sector}</td>
							<td className="text-center">{lesson.job_subsector}</td>
							<td className="text-center">{lesson.job_service}</td>
							<td className="text-center">{lesson.nr_person}</td>
							<td className="text-center"><Moment format="DD.MM.YYYY HH:mm">{lesson.proposal_start}</Moment></td>
							<td className="text-center"><Moment format="DD.MM.YYYY HH:mm">{lesson.proposal_end}</Moment></td>
							<td className="text-center">{lesson.job_user_giver}</td>
							<td className="text-center status"><span>{lesson.job_log_latest_status}</span></td>
							{/* <td className="text-center"><a data-sector={lesson.job_sector} data-subsector={lesson.job_subsector} data-service={lesson.job_service} data-title={lesson.title} data-description={lesson.description} data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="pending" onClick={(e) => { if (window.confirm('are you sure you want to assign to this lesson?')) this.changeStatus(e) } } href="javascript:void(0);">Assign</a></td> */}
							<td className="text-center">N/A</td>
							{/* <td className="text-center"><a href={"http://localhost:3000/lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td> */}
							<td className="text-center"><a href={window.$siteBaseUrl+"lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>
							{/* <td className="text-center"><a href="#"><i className="fa fa-pencil-square" aria-hidden="true"></i></a></td>
							<td className="text-center"><a href="javascript:void(0);"><i data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="deleted" onClick={(e) => { if (window.confirm('Are you sure you want to change this lesson status?')) this.changeStatus(e) } } className="fa fa-times-circle" aria-hidden="true"></i></a></td> */ }
						</tr>
					);
				} if(lesson.job_log_latest_status == 'pending') {
					return (
						<tr>
							<td className="text-center"><Moment format="DD.MM.YYYY HH:mm:ss">{lesson.creation_time}</Moment></td>
							<td className="text-center">{lesson.job_sector}</td>
							<td className="text-center">{lesson.job_subsector}</td>
							<td className="text-center">{lesson.job_service}</td>
							<td className="text-center">{lesson.nr_person}</td>
							<td className="text-center"><Moment format="DD.MM.YYYY HH:mm">{lesson.proposal_start}</Moment></td>
							<td className="text-center"><Moment format="DD.MM.YYYY HH:mm">{lesson.proposal_end}</Moment></td>
							<td className="text-center">{lesson.job_user_giver}</td>
							<td className="text-center status"><span>{lesson.job_log_latest_status}</span></td>
							{/* <td className="text-center"><a data-start={lesson.start} data-end={lesson.end} data-sector={lesson.job_sector} data-subsector={lesson.job_subsector} data-service={lesson.job_service} data-title={lesson.title} data-description={lesson.description} data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="approved" onClick={(e) => { if (window.confirm('are you sure you want to approve to this lesson?')) this.changeStatus(e) } } href="javascript:void(0);">Approve</a></td>*/}
							<td className="text-center"><a href="javascript:void(0);"><i className="fa fa-commenting" aria-hidden="true" lesson-id={lesson.id}  onClick={this.showMessageModal}></i></a></td>
							{/* <td className="text-center"><a href={"http://localhost:3000/lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>*/}
							<td className="text-center"><a href={window.$siteBaseUrl+"lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>
							{/* <td className="text-center"><a href="#"><i className="fa fa-pencil-square" aria-hidden="true"></i></a></td>
							<td className="text-center"><a href="javascript:void(0);"><i data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="deleted" onClick={(e) => { if (window.confirm('Are you sure you want to change this lesson status?')) this.changeStatus(e) } } className="fa fa-times-circle" aria-hidden="true"></i></a></td>*/}
						</tr>
					);
				} if(lesson.job_log_latest_status == 'approved') {
					var myDateTime = new Date().getTime();
					var jobDateTime = new Date(lesson.start).getTime();
					var tr_class = '';
					//console.log(lesson.id+"=="+jobDateTime+"=="+myDateTime+"=="+lesson.payment_status);
					if(jobDateTime < myDateTime) {
						
						if(lesson.payment_status<1) {
								tr_class = 'wqred';
							} else {
								tr_class = 'wqnormal';
							}
						var start_date = lesson.start;
						var end_date = lesson.end;
						var date_class = 'text-center start-date';
						if(start_date == '0000-00-00 00:00:00'){
							start_date = lesson.proposal_start;
							date_class = 'text-center';
						}
						if(end_date == '0000-00-00 00:00:00'){
							end_date = lesson.proposal_end;
							date_class = 'text-center';
						}
						return (
							<tr className={tr_class}>
								<td className="text-center"><Moment format="DD.MM.YYYY HH:mm:ss">{lesson.creation_time}</Moment></td>
								<td className="text-center">{lesson.job_sector}</td>
								<td className="text-center">{lesson.job_subsector}</td>
								<td className="text-center">{lesson.job_service}</td>
								<td className="text-center">{lesson.nr_person}</td>
								<td className={date_class}><Moment format="DD.MM.YYYY HH:mm">{start_date}</Moment></td>
								<td className={date_class}><Moment format="DD.MM.YYYY HH:mm">{end_date}</Moment></td>
								<td className="text-center">{lesson.job_user_giver}</td>
								<td className="text-center status"><span>{lesson.job_log_latest_status}</span></td>
								<td className="text-center"><a href="javascript:void(0);"><i className="fa fa-commenting" aria-hidden="true" lesson-id={lesson.id}  onClick={this.showMessageModal}></i></a></td>
								{/* <td className="text-center"><a href={"http://localhost:3000/lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td> */}
								<td className="text-center"><a href={window.$siteBaseUrl+"lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td> 
								{/*<td className="text-center"><a href="#"><i className="fa fa-pencil-square" aria-hidden="true"></i></a></td>
								<td className="text-center"><a href="javascript:void(0);"><i data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="deleted" onClick={(e) => { if (window.confirm('Are you sure you want to change this lesson status?')) this.changeStatus(e) } } className="fa fa-times-circle" aria-hidden="true"></i></a></td> */}
							</tr>
						);
					}else{
						return (
							<tr>
								<td className="text-center"><Moment format="DD.MM.YYYY HH:mm:ss">{lesson.creation_time}</Moment></td>
								<td className="text-center">{lesson.job_sector}</td>
								<td className="text-center">{lesson.job_subsector}</td>
								<td className="text-center">{lesson.job_service}</td>
								<td className="text-center">{lesson.nr_person}</td>
								<td className="text-center start-date"><Moment format="DD.MM.YYYY HH:mm">{lesson.start}</Moment></td>
								<td className="text-center start-date"><Moment format="DD.MM.YYYY HH:mm">{lesson.end}</Moment></td>
								<td className="text-center">{lesson.job_user_giver}</td>
								<td className="text-center status"><span>{lesson.job_log_latest_status}</span></td>
								{/* <td className="text-center"><a data-sector={lesson.job_sector} data-subsector={lesson.job_subsector} data-service={lesson.job_service} data-title={lesson.title} data-description={lesson.description} data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="passed" onClick={(e) => { if (window.confirm('Attention, are you sure want to close this lesson? After changes this field, you cannot change anymore this lesson')) this.showPopup(e) } } href="javascript:void(0);">Close</a></td> */ }
								<td className="text-center"><a href="javascript:void(0);"><i className="fa fa-commenting" aria-hidden="true" lesson-id={lesson.id}  onClick={this.showMessageModal}></i></a></td>
								{/* <td className="text-center"><a href={"http://localhost:3000/lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>*/}
								<td className="text-center"><a href={window.$siteBaseUrl+"lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>
								{/* <td className="text-center"><a href="#"><i className="fa fa-pencil-square" aria-hidden="true"></i></a></td>
								<td className="text-center"><a href="javascript:void(0);"><i data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="deleted" onClick={(e) => { if (window.confirm('Are you sure you want to change this lesson status?')) this.changeStatus(e) } } className="fa fa-times-circle" aria-hidden="true"></i></a></td>*/}
							</tr>
						);
					}
				} if(lesson.job_log_latest_status == 'deleted') {
					var start_date = lesson.start;
					var end_date = lesson.end;
					var date_class = 'text-center start-date';
					if(start_date == '0000-00-00 00:00:00'){
						start_date = lesson.proposal_start;
						date_class = 'text-center';
					}
					if(end_date == '0000-00-00 00:00:00'){
						end_date = lesson.proposal_end;
						date_class = 'text-center';
					}
					return (
						<tr>
							<td className="text-center"><Moment format="DD.MM.YYYY HH:mm:ss">{lesson.creation_time}</Moment></td>
							<td className="text-center">{lesson.job_sector}</td>
							<td className="text-center">{lesson.job_subsector}</td>
							<td className="text-center">{lesson.job_service}</td>
							<td className="text-center">{lesson.nr_person}</td>
							<td className={date_class}><Moment format="DD.MM.YYYY HH:mm">{start_date}</Moment></td>
							<td className={date_class}><Moment format="DD.MM.YYYY HH:mm">{end_date}</Moment></td>
							<td className="text-center status"><span>{lesson.job_log_latest_status}</span></td>
							<td className="text-center">{lesson.job_user_giver}</td>
							<td className="text-center">N/A</td>
							{/* <td className="text-center"><a href={"http://localhost:3000/lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>*/}
							<td className="text-center"><a href={window.$siteBaseUrl+"lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>
							{/* <td className="text-center"><a href="#"><i className="fa fa-pencil-square" aria-hidden="true"></i></a></td>
							<td className="text-center"><a href="javascript:void(0);"><i data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="deleted" onClick={(e) => { if (window.confirm('Are you sure you want to change this lesson status?')) this.changeStatus(e) } } className="fa fa-times-circle" aria-hidden="true"></i></a></td>*/}
						</tr>
					);
				} {/* if(lesson.job_log_latest_status == 'passed') {
						var myDateTime = new Date().getTime();
						var jobDateTime = new Date(lesson.start).getTime();
						var tr_class = '';
						if(jobDateTime < myDateTime) {
							if(lesson.payment_status<1) {
								tr_class = 'wqred';
							} else {
								tr_class = 'wqnormal';
							}
						var start_date = lesson.start;
						var end_date = lesson.end;
						var date_class = 'text-center start-date';
						if(start_date == '0000-00-00 00:00:00'){
							start_date = lesson.proposal_start;
							date_class = 'text-center';
						}
						if(end_date == '0000-00-00 00:00:00'){
							end_date = lesson.proposal_end;
							date_class = 'text-center';
						}
							return (
								<tr className={tr_class}>
									<td className="text-center"><Moment format="DD.MM.YYYY HH:mm:ss">{lesson.creation_time}</Moment></td>
									<td className="text-center">{lesson.job_sector}</td>
									<td className="text-center">{lesson.job_subsector}</td>
									<td className="text-center">{lesson.job_service}</td>
									<td className="text-center">{lesson.nr_person}</td>
									<td className={date_class}><Moment format="DD.MM.YYYY HH:mm:ss">{start_date}</Moment></td>
									<td className={date_class}><Moment format="DD.MM.YYYY HH:mm:ss">{end_date}</Moment></td>
									<td className="text-center">N/A</td>
									<td className="text-center status"><span>{lesson.job_log_latest_status}</span></td>
									<td className="text-center">N/A</td>
									<td className="text-center"><a href="#"><i className="fa fa-commenting" aria-hidden="true"></i></a></td>
									<td className="text-center"><a href={"http://localhost:3000/lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td> 
									<td className="text-center"><a href={"https://mise1.area-test.ch/#/lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>
									<td className="text-center"><a href="#"><i className="fa fa-pencil-square" aria-hidden="true"></i></a></td>
									<td className="text-center"><a href="javascript:void(0);"><i data-admin={lesson.id_admin} data-worker={lesson.id_worker} data-giver={lesson.id_giver} data-id={lesson.id} data-status="deleted" onClick={(e) => { if (window.confirm('Are you sure you want to change this lesson status?')) this.changeStatus(e) } } className="fa fa-times-circle" aria-hidden="true"></i></a></td>
								</tr>
							);
						}
			}  */}
    	});
    }
    
    return (
		<div className="mylesson-details">
		<table className="table table-bordered table-secondary mylesson-custom-table">
			<tr>
				<th width="10%" className="text-center">{language_arr.creation_time}</th>
				<th width="10%" className="text-center" >{language_arr.school}</th>
				<th width="5%" className="text-center">{language_arr.level}</th>
				<th width="15%" className="text-center">{language_arr.subject}</th>
				<th width="5%" className="text-center">{language_arr.no_of_student}</th>
				<th width="10%" className="text-center">{language_arr.start} </th>
				<th width="10%" className="text-center">{language_arr.end}</th>
				<th width="10%" className="text-center">{language_arr.student_name}</th>
				<th width="5%" className="text-center">{language_arr.status}</th>
				{ /* <th width="5%" className="text-center">Action</th> */ }
				<th width="3%" className="text-center">{language_arr.chat}</th>
				<th width="3%" className="text-center">{language_arr.details}</th>
				{ /* <th width="3%" className="text-center">Edit</th>
				<th width="3%" className="text-center">Cancel</th> */ }
			</tr>
			{lessons}
		</table>
		<div className="custom-pop-wrap">
			<div className="custom-pop">
			<button type="button" className="close" onClick={this.modalClose} data-dismiss="modal">&times;</button>
				<h2>Feedback</h2>  
				<div className="custom-pop-btn">
					<textarea rows="4" placeholder="Enter Feedback" name="wqfeedback" id="wqfeedback" className="form-control" onChange={this.change}>{this.state.wqfeedback}</textarea> 
					<p>&nbsp;</p>
					<button type="button" onClick={this.feedbackSubmit} className="btn btn-success" id="pop-confirm">Submit</button>
				</div>
			</div>
		</div>
		<div className="modal fadeIn" id="messagemodel" tabindex="-1" role="dialog" aria-labelledby="messagemodelLabel" aria-hidden="true">
		  <div className="modal-dialog" role="document">
			<div className="modal-content">
			  <div className="modal-header message-header">
				<h5 className="modal-title" id="exampleModalLabel">{language_arr.send_messgae}</h5>
				<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeMessageModal}>
				  <span aria-hidden="true">&times;</span>
				</button>
			  </div>
			  <form>
			  <div className="modal-body">
				<div className="message-box">
					<div className="form-group">
						<label><span className="title-label">{language_arr.sender_email} :</span><span className="text-label"> {this.state.current_user_email}</span></label>
						<label><span className="title-label">{language_arr.receiver_email} :</span><span className="text-label"> {this.state.receiver_email}</span></label>
					</div>
					<div className="form-group">
						<textarea className="form-control" onChange={this.change} name="message" id="message" placeholder={language_arr.message_here} rows="5">{this.state.message}</textarea>
					</div>
					<div className="wqsuccess text-center">{this.state.final_message}</div>
				</div>
			  </div>
			  <div className="modal-footer">		
				<button type="button" className="btn btn-send" onClick={this.sendMessage}>{language_arr.send}</button>
				<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.closeMessageModal}>{language_arr.close}</button>
			  </div>
			  </form>
			</div>
		  </div>
		</div>
		</div>
    );
  }
}

export default LessonList;
