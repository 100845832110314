import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Language from './Language';
window.$apiBaseUrl = 'https://mise.evobyte.ch/miseapi/';
window.$siteBaseUrl = 'https://ticino.swiss-mise.info/#/';
//window.$siteBaseUrl = 'http://localhost:3000/#/';
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Language />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
