import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import './Contact.css';
import Header from '../Header/Header';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
        'name':'',
        'email':'',
        'subject':'',
        'message':'',
        'error':'',
        'success':'',
        'name_error':'',
        'email_error':'',
        'subject_error':'',
        'message_error':'',

        'toemail':'',
        'contact_email':'',
        'contact_phone':'',
        'contact_address':'',
		'current_language':''
    }
    this.change = this.change.bind(this);
    this.formValidate = this.formValidate.bind(this);
    this.contactSubmit = this.contactSubmit.bind(this);
    this.getContactSettings = this.getContactSettings.bind(this);
	this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
	this.getPageTitle = this.getPageTitle.bind(this);
  }

  getContactSettings() {
    $.ajax({
      url: window.$apiBaseUrl+'settings/read.php',
      type: 'POST',
      dataType: 'json',
      cache: false,
      contentType: false,
      processData:false,
      success: function(data) {
        //console.log(data.records);
        this.setState({'toemail': data.records[9].value});
        this.setState({'contact_email': data.records[3].value});
        this.setState({'contact_phone': data.records[2].value});
        this.setState({'contact_address': data.records[1].value});
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
      }
    });
  }

  componentWillMount() {
    this.getContactSettings();
  }

  componentDidMount() {
	this.getPageTitle();
    this.getContactSettings();
	this.getCurrentLanguage();
  }
  
  getPageTitle(){
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj); 
	document.title = language_arr.page_title;
  }
  
  getCurrentLanguage(e){
		$.ajax({
          url: window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            var current_language = data.records[0].value;
			{ /* this.setState({'current_language':current_language}, function() {
				this.getCurrentLanguage();
			}); */ }
			this.setState({'current_language':current_language});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
	}

  change(e) {
    this.setState({[e.target.name]:e.target.value});
  }

  formValidate(e) {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    var formValid = true;
    if(this.state.name=='') {
        formValid = false;
        this.setState({'name_error': '*'+language_arr.name_err});
    } else {
        this.setState({'name_error': ''});
    }
    if(this.state.email==='') {
        formValid = false;
        this.setState({'email_error': '*'+language_arr.email_err});
    } else {
        this.setState({'email_error': ''});
    }
    if(this.state.subject==='') {
        formValid = false;
        this.setState({'subject_error': '*'+language_arr.subject_err});
    } else {
        this.setState({'subject_error': ''});
    }
    if(this.state.message==='') {
        formValid = false;
        this.setState({'message_error': '*'+language_arr.msg_err});
    } else {
        this.setState({'message_error': ''});
    }

    return formValid;
  }

  contactSubmit(e) {
    e.preventDefault();
    this.setState({'success': ''});
    this.setState({'error': ''});
    //console.log(this.state);

    if(this.formValidate()) {
      const fd = new FormData();
      fd.append('toemail', this.state.toemail);
      fd.append('name', this.state.name);
      fd.append('email', this.state.email);
      fd.append('subject', this.state.subject);
      fd.append('message', this.state.message);
	  fd.append('current_language', this.state.current_language);

      axios.post(window.$apiBaseUrl+'contact/contact.php', fd)
        .then(res => {
          console.log(res);
          if(res.data.status == '200') {
            this.setState({'success':res.data.message}, function() {
              this.setState({'name':''});
              this.setState({'email':''});
              this.setState({'subject':''});
              this.setState({'message':''});
              $('#message').val('');
            });
          } else {
            this.setState({'error': res.data.message});
          }
        }).catch(error => {
           console.log(error);
        });
    }
  }

  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    return (
	<div>
	<Header />
      <div className="Contact">
        <div className="main">
            <div className="inner-banner">
              <img src="images/contact-us.jpg" />	
                <div className="inner-banner-overlay">
                  <h1>{language_arr.contact_us}</h1>
                </div>
            </div>
            <div className="contact-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="contact-form">
                                <form onSubmit={this.contactSubmit}>
                                <div className="form-group">
                                    <label className="feild-title">{language_arr.name}</label>
                                    <input type="text" placeholder={language_arr.enter_your_name} name="name" id="name" className="form-control" value={this.state.name} onChange={this.change} />
                                    <div className="wqerror">{this.state.name_error}</div>
                                </div>
                                <div className="form-group">
                                    <label className="feild-title">{language_arr.email}</label>
                                    <input type="email" placeholder={language_arr.enter_your_email} name="email" id="email" className="form-control" value={this.state.email} onChange={this.change} />
                                    <div className="wqerror">{this.state.email_error}</div>
                                </div>
                                <div className="form-group">
                                    <label className="feild-title">{language_arr.subject}</label>
                                    <input type="text" placeholder={language_arr.enter_subject} name="subject" id="subject" className="form-control" value={this.state.subject} onChange={this.change} />
                                    <div className="wqerror">{this.state.subject_error}</div>
                                </div> 
                                <div className="form-group">
                                    <label className="feild-title">{language_arr.message}</label>
                                    <textarea rows="5" placeholder={language_arr.enter_message} name="message" id="message" className="form-control" onChange={this.change}>{this.state.message}</textarea>
                                    <div className="wqerror">{this.state.message_error}</div>
                                </div>
                                <div className="form-group">
                                    <input type="submit" value={language_arr.submit} className="login-btn submit-btn" />
                                    <div className="wqerror text-center">{this.state.error}</div>
                                    <div className="wqsuccess text-center">{this.state.success}</div>
                                </div>  
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="contact-dtls">
                              <h3>{language_arr.contact_details}</h3>
                              <ul>
                                  <li><span><i className="fa fa-envelope" aria-hidden="true"></i></span><font>{this.state.contact_email}</font></li>
                                  <li><span><i className="fa fa-phone" aria-hidden="true"></i></span><font>{this.state.contact_phone}</font></li>
                                  <li><span><i className="fa fa-map-marker" aria-hidden="true"></i></span><font>{this.state.contact_address}</font></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
          </div>
      </div>
	</div>
    );
  }
}

export default Contact;
