import React, { Component } from 'react';
import './Notfound.css';
import Header from '../Header/Header';

class Notfound extends Component {
	constructor(props) {
		super(props);
		this.state = {}
		this.getPageTitle = this.getPageTitle.bind(this);
	}
	
	getPageTitle(){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj); 
		document.title = language_arr.page_title;
	  }
	
  componentDidMount(){
    this.getPageTitle();
  }
  render() {
    return (
	<div>
	<Header />
      <div className="Notfound">
        <div className="sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center help-headline-txt">
                <h2>404 Page</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default Notfound;
