import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import './GeneralCondition.css';
import Header from '../Header/Header';

class GeneralCondition extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'id_option': '',
      'GeneralOptions': ''
    }
    this.getGeneralOptions = this.getGeneralOptions.bind(this);
	this.getPageTitle = this.getPageTitle.bind(this);
  }

  getGeneralOptions() {
    const fd = new FormData();
    fd.append('id_option', '13');

    axios.post(window.$apiBaseUrl+'options/read.php', fd)
    .then(res => {
      this.setState({'GeneralOptions':res.data.records[0].option_value});
      //console.log(res);
    }).catch(error => {
       console.log(error);
    });
  }
  
  getPageTitle(){
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj); 
	document.title = language_arr.page_title;
  }

  componentWillMount() {
    this.getGeneralOptions();
  }

  componentDidMount() {
	this.getPageTitle();
    this.getGeneralOptions();
  }

	
  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    return (
	<div>
	<Header />
      <div className="GeneralCondition">
        <div className="main">
          <div className="inner-banner">
              <img src="images/Terms-and-Conditions.jpg" />	
                <div className="inner-banner-overlay">
                  <h1>{language_arr.stu_condition}</h1>
                </div>
            </div>
            <div className="trms-condition-content">
              <div className="container">{ReactHtmlParser(this.state.GeneralOptions, true)}</div>
            </div>
        </div>
      </div>
	</div>
    );
  }
}

export default GeneralCondition;
