import React, { Component } from 'react';
import $ from 'jquery';
import LessonList from './LessonList';
import './StudentLesson.css';
import Header from '../Header/Header';


class TeacherLesson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'sectors':[],
            'subsectors':[],
            'services':[],
            'lessons':[],
            'wqstatus':'pool,pending',
            'sector':'',
            'subsector':'',
            'service':''
        }
        this.getSector = this.getSector.bind(this);
        this.getSubsector = this.getSubsector.bind(this);
        this.getService = this.getService.bind(this);
        this.getStudentLesson = this.getStudentLesson.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getSubsectorResult = this.getSubsectorResult.bind(this);
        this.getServiceResult = this.getServiceResult.bind(this);
		this.getPageTitle = this.getPageTitle.bind(this);
    }

    getStatus(e) {
        e.preventDefault();
        var wqstatus = e.target.getAttribute('data-status');
        this.setState({'wqstatus': wqstatus});
		setTimeout(()=> this.getStudentLesson(), 500);
    }

    getSubsectorResult(e) {
        e.preventDefault();
        this.setState({'subsector':e.target.value});
        setTimeout(()=> this.getStudentLesson(), 500);
    }

    getServiceResult(e) {
        e.preventDefault();
        this.setState({'service':e.target.value});
        setTimeout(()=> this.getStudentLesson(), 500);
    }

    getStudentLesson() {
        var userDetail = JSON.parse(localStorage.getItem("loggedInUser"));
		if(typeof userDetail !== 'undefined' && userDetail != null){
			const fd = new FormData();
			fd.append('creatorstatus', this.state.wqstatus);
			fd.append('sector', this.state.sector);
			fd.append('subsector', this.state.subsector);
			fd.append('service', this.state.service);
			fd.append('creator_id', userDetail.id_user);
			$.ajax({
				url: window.$apiBaseUrl+'job/read.php',
				type: 'POST',
				data: fd,
				dataType: 'json',
				cache: false,
				contentType: false,
				processData:false,
				success: function(data) {
					//console.log(data);
					{ /* this.setState({'lessons':data.records}, function() {
						//console.log(this.state);
						this.getStudentLesson();
					}) */ }
					this.setState({'lessons':data.records});
					//setTimeout(()=> this.getStudentLesson(), 300);
				}.bind(this),
				error: function(xhr, status, err) {
					console.log(err);
				}
			});
		}else{
			window.location.href='#/';
		}
    }

    getSector() {
        $.ajax({
        url: window.$apiBaseUrl+'sector/read.php',
        type: 'POST',
        dataType: 'json',
        cache: false,
        contentType: false,
        processData:false,
        success: function(data) {
			{ /*  this.setState({'sectors':data.records}, function() {
                //console.log(this.state);
            }) */ }
			this.setState({'sectors':data.records});
        }.bind(this),
        error: function(xhr, status, err) {
            console.log(err);
        }
        });
    }

    getSubsector(e) {
        e.preventDefault();
        this.setState({'sector': e.target.value});
        if(e.target.value != '') {
            var fd = new FormData();
            fd.append('sector_id', e.target.value);

            $.ajax({
                url: window.$apiBaseUrl+'subsector/read.php',
                type: 'POST',
                data: fd,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData:false,
                success: function(data) {
                    { /* this.setState({'subsectors':data.records}, function() {
                        //console.log(this.state);
                        this.getStudentLesson();
                    }) */ }
					this.setState({'subsectors':data.records});
					//setTimeout(()=> this.getStudentLesson(), 300);
                }.bind(this),
                error: function(xhr, status, err) {
                console.log(err);
                }
            });
        } else {
            this.setState({'subsectors': ''});
            this.setState({'subsector': ''});
        }
    }

    getService() {
        $.ajax({
          url: window.$apiBaseUrl+'service/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
			  { /* this.setState({'services':data.records}, function() {
              //console.log(this.state);
			  }) */}
			  this.setState({'services':data.records});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }

    componentWillMount() {
        this.getSector();
        this.getService();
        this.getStudentLesson();
    }
	
	getPageTitle(){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj); 
		document.title = language_arr.page_title;
	  }

    componentDidMount() {
		this.getPageTitle();
        this.getSector();
        this.getService();
        this.getStudentLesson();
    }

  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    let sectors;
    if(this.state.sectors) {
        sectors = this.state.sectors.map((sector,index) => {
            if(sector.is_active == '1') {
                return (
                    <option value={sector.id}>{sector.sector}</option>
                );
            }
        });
    }

    let subsectors;
    if(this.state.subsectors) {
        subsectors = this.state.subsectors.map((subsector,index) => {
            if(subsector.is_active == '1') {
                return (
                    <option value={subsector.id}>{subsector.subsector}</option>
                );
            }
        });
    }

    let services;
    if(this.state.services) {
        services = this.state.services.map((service,index) => {
            if(service.is_active == '1') {
                return (
                    <option value={service.id}>{service.service}</option>
                );
            }
        });
    }

    return (
	<div>
	  <Header />
        <div className="TeacherLesson">
            <div className="main">
                <div className="mylesson-back">
                    <div className="container-fluid">
                        <div className="mylesson-hdng">
                            <h3>{language_arr.student_area}</h3>
                        </div>
                        { /* <div className="mylesson-order-btn">
                            <a href="#">Order by proposal</a>
                        </div> */ }
                        <div className="mylesson-tab">
                            <div className="mylesson-nav">
                                <ul>
                                    <li><a href="javascript:void(0)" data-status="pool,pending" onClick={this.getStatus} className="tab-menu-active">{language_arr.pending_lesson}</a></li>
                                    <li><a href="javascript:void(0)" data-status="approved" onClick={this.getStatus}>{language_arr.approved_lesson}</a></li>
                                    <li><a href="javascript:void(0)" data-status="deleted" onClick={this.getStatus}>{language_arr.deleted_lesson}</a></li>
                                    <li><a href="javascript:void(0)" data-status="passed" onClick={this.getStatus}>{language_arr.passed_lesson}</a></li>
                                </ul>
                            </div>
                            <div className="mylesson-contentarea mylesson-nt">
                                <div className="mylesson-content-box tab-active">
                                    <div className="filter">
                                        <table className="table">
                                            <tr>
                                                <td width="33.3333333333%">
                                                    <select className="form-control" onChange={this.getSubsector} autofocus>
                                                        <option value="">{language_arr.select_school}</option>
                                                        {sectors}
                                                    </select>
                                                </td>
                                                <td width="33.3333333333%">
                                                    <select className="form-control" onChange={this.getSubsectorResult} autofocus>
                                                        <option value="">{language_arr.select_level}</option>
                                                        {subsectors}
                                                    </select>
                                                </td>
                                                <td width="33.3333333333%">
                                                    <select className="form-control" onChange={this.getServiceResult} autofocus>
                                                        <option value="">{language_arr.select_subject}</option>
                                                        {services}
                                                    </select>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <LessonList lessons={this.state.lessons} />
                                </div>	
                            </div>
                        </div>	
                    </div>
                </div>	
            </div>
        </div>
	</div>
    );
  }
}

export default TeacherLesson;
