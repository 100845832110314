import React, { Component } from 'react';
import $ from 'jquery';
import App from './App';
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";

const override = css`
	background: #28a745 !important;
	display: block;
	margin: auto;
	border-color: #28a745;
	width: 110px;
	height: 110px;
	border-color: #28a745;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

class Language extends Component {
	constructor(props) {
		super(props);
        this.state = {
			current_language:'',
			language_arr: [],
			loading: true
		}
		
		this.getLanguageArr = this.getLanguageArr.bind(this);
		this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
	}
	
	componentDidMount() {
		
		this.getCurrentLanguage();
		
	}
	
	getCurrentLanguage(e){
		$.ajax({
          url: window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
			  //console.log(data);
            var current_language = data.records[0].value;
			this.setState({'current_language':current_language}, function() {
				
				//this.getCurrentLanguage();
				this.getLanguageArr();
			});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
	}
	
	getLanguageArr(e){
		var fd = new FormData();
		fd.append('language', this.state.current_language);
		$.ajax({
          url: window.$apiBaseUrl+'language/read.php',
          type: 'POST',
		  data: fd,
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
			  //console.log(data);
			var language_arr =  JSON.stringify(data);
			localStorage.setItem("language_arr", language_arr);
			
			/* var newArr = Object.keys(data);
			var mappedArr = newArr.map(function(i) {
				return [i, data[i]];
			});
			var arr_len = mappedArr.length;
			for(var i=0; i < arr_len; i++){
				var single_record = mappedArr[i];
				this.setState({[single_record[0]]:single_record[1]});
			} */
            //console.log(this.state);
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
		
	}
	
	render() {
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
		if(language_obj!=null){
			return (
				<div>
					<App />
				</div>
			);
		}else{
			this.getCurrentLanguage();
			return(
				<div className="">
					<ClipLoader
					  css={override}
					  size={150} // or 150px
					  color={"#123abc"}
					  loading={this.state.loading}
					/>
				</div>
			);
		}
	}
}
export default Language;