import React, { Component } from 'react';
import $ from 'jquery';

class FrontLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {}
		this.frontloginById = this.frontloginById.bind(this);
	}
	componentDidMount() {
		this.frontloginById();
	}
	frontloginById(e){
		let user_id = this.props.match.params.id;
		if(user_id){
			//localStorage.clear();
			localStorage.removeItem("loggedInUser");
			const fd = new FormData();
			fd.append('userid', user_id);
			$.ajax({
			  url: window.$apiBaseUrl+'user/read.php',
			  type: 'POST',
			  dataType: 'json',
			  data: fd,
			  cache: false,
			  contentType: false,
			  processData:false,
			  success: function(data) {
				var user_details = data.records[0];
				var user_type = user_details.type;
				var userString =  JSON.stringify(user_details);
				localStorage.setItem("loggedInUser", userString);
				if(user_type=="teacher"){
					setTimeout(function(){ 
						window.location.href='#/teacherlesson';
					}, 500);
				}
				if(user_type=="student"){
					setTimeout(function(){ 
						window.location.href='#/studentlesson';
					}, 500);
				}
			  }.bind(this),
			  error: function(xhr, status, err) {
				console.log(err);
			  }
			});
		}
	}
	render() {
	  return (<div></div>);
	}
}
export default FrontLogin;