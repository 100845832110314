import React, { Component } from 'react';

class StepNumber extends Component {
  render() {
	  var language_obj = localStorage.getItem("language_arr");
	  var language_arr = JSON.parse(language_obj);
    //console.log(this.props);
    if(this.props.stepStatus) {
      return (
        <tr>
            <td width="10%" className="text-right"><span>{language_arr.step} 1</span></td>
            <td width="80%" className="text-center"><div className="step-line step-line-one"></div></td>
            <td width="10%" className="text-left"><span className="complete"><i className="fa fa-check" aria-hidden="true"></i></span></td>
        </tr>
      );
    } else {
      return (
        <tr>
            <td width="10%" className="text-right"><span>{language_arr.step} 1</span></td>
            <td width="37.5%" className="text-center"><div className="step-line step-line-one"></div></td>
            <td width="5%" className="text-center"><span>{language_arr.step} 2</span></td>
            <td width="37.5%" className="text-center"><div className="step-line step-line-two"></div></td>
            <td width="10%" className="text-left"><span className="complete"><i className="fa fa-check" aria-hidden="true"></i></span></td>
        </tr>
      );
    }

  }
}

export default StepNumber;
