import React, { Component } from 'react';
import './AdhocLesson.css';
import * as EmailValidator from 'email-validator';
import * as $ from 'jquery';
import axios from 'axios';
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import enGB from "date-fns/locale/en-GB";
import Header from '../Header/Header';
registerLocale("en-GB", enGB);

class AdhocLesson extends Component {
	constructor(props) {
        super(props);
		this.state = {
			'wqemail':'',
			'wqmessage':'',
			'student_id':'',
			'loggedInStatus':'',
			'sectors':[],
			'subsectors':[],
			'services':[],
			'regions':[],
			'wqsector':'',
            'wqsubsector':'',
            'wqservice':'',
			'wqtitle':'',
            'wqdescription':'',
			'wqstart_date': new Date(),
			'wqstart_hour':10,
            'wqstart_minutes':'00',
            'wqend_duration':60,
            'wqtelphone':'',
            'wqstudents_no':1,
            'wqregion':'',
            'wqlocation':'',
			'wqdiscount':0,
			'wqprice':0,
			'wqsettings_discount':'',
            'wqprice_per_hour':'',
            'wqurgent_cost':'',
            'urgent_day':'',
			'final_urgent_price':0,
			'final_message':'',
			'current_language':'',
			'error_message':'',
			
			'wqsetschool':'',
			'wqsetlevel':'',
			'wqsetregion':'',
		}
		this.emailCheck = this.emailCheck.bind(this);
		this.change = this.change.bind(this);
		this.getSector = this.getSector.bind(this);
		this.getSubsector = this.getSubsector.bind(this);
		this.getService = this.getService.bind(this);
		this.getRegion = this.getRegion.bind(this);
		this.getUserOptions = this.getUserOptions.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.calculatePrice = this.calculatePrice.bind(this);
		this.getSettings = this.getSettings.bind(this);
		this.formSubmit = this.formSubmit.bind(this);
		this.formValidate = this.formValidate.bind(this);
		this.lessonSubmit = this.lessonSubmit.bind(this);
		this.convert = this.convert.bind(this);
		this.convert_with_time = this.convert_with_time.bind(this);
		this.modalClose = this.modalClose.bind(this);
		this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
		this.getPageTitle = this.getPageTitle.bind(this);
		this.getUserLastInfo = this.getUserLastInfo.bind(this);
		this.setSubsector = this.setSubsector.bind(this);
	}
	
	componentWillMount() {
		this.getSettings();
        this.getSector();
        this.getService();
        this.getRegion();
        this.getUserOptions();
    }
    
    componentDidMount() {
		this.getPageTitle();
		this.getSettings();
        this.getSector();
        this.getService();
        this.getRegion();
        this.getUserOptions();
		this.getCurrentLanguage();
    }
	
	getPageTitle(){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj); 
		document.title = language_arr.page_title;
	  }
	  
	getCurrentLanguage(e){
		$.ajax({
          url: window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            var current_language = data.records[0].value;
			{ /* this.setState({'current_language':current_language}, function() {
				this.getCurrentLanguage();
			}); */ }
			this.setState({'current_language':current_language});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
	}
	
	change(e) {
        this.setState({[e.target.name]: e.target.value});
		var wqdiscount = '';
        var wqstudent = 1;
		if(e.target.name == 'wqstudents_no') {
            if(e.target.value > 1) {
                wqstudent = e.target.value
                wqdiscount = (e.target.value * this.state.wqsettings_discount);
            } else {
                wqdiscount = 0;
            }
            //console.log(wqdiscount);
            this.setState({'wqdiscount': wqdiscount});
            this.calculatePrice(this.state.final_urgent_price, this.state.wqend_duration, wqstudent, wqdiscount);
        }
		
		if(e.target.name == 'wqend_duration') {
			//alert(this.state.final_urgent_price+'=='+e.target.value+'=='+this.state.wqstudents_no+'=='+this.state.wqdiscount);
            this.calculatePrice(this.state.final_urgent_price, e.target.value, this.state.wqstudents_no, this.state.wqdiscount);
        }
    }
	
	handleChange(date) {
        this.setState({ wqstart_date: date });
		this.setState({ 'is_urgent': 0 });
		this.setState({ 'final_urgent_price': 0 });
		this.calculatePrice(0, this.state.wqend_duration, this.state.wqstudents_no, this.state.wqdiscount);
    }
	
	emailCheck(e) {
        e.preventDefault();
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
        this.setState({'wqemail': e.target.value});
        var fd = new FormData();
        fd.append('email', e.target.value);

        if (EmailValidator.validate(e.target.value)) {
            $.ajax({
                url: window.$apiBaseUrl+'user/exist_user.php',
                type: 'POST',
                data: fd,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,
                success: function(data) {
                    var email_status = data.status;
					if(email_status == 404 || email_status == 400 || email_status == 300){
						this.setState({'wqmessage': language_arr.adhoc_email_err});
					}else{
						this.setState({'wqmessage': ''});
						this.setState({'student_id': data.student_id});
						setTimeout(()=> this.getUserLastInfo(), 300)
						$('.step-one-form').hide();
						$('.step-two-form').show();
						$('.step-line-one').addClass("step-line-animation");
						$("html, body").scrollTop(0);
					}
                }.bind(this),
                error: function(xhr, status, err) {
                    console.log(err);
                }
            });
        }
    }
	
	getUserLastInfo(){
		const fd = new FormData();
        fd.append('job_status', '');
        fd.append('job_id', '');
        fd.append('user_id', this.state.student_id);
        fd.append('job_start', '');
		axios.post(window.$apiBaseUrl+'job/read_filtered_jobs.php', fd)
        .then(res => {
			var single_record = res.data.records[0];
			console.log(single_record);
			this.setState({ wqsetschool: single_record.id_sector });
			this.setState({ wqsetlevel: single_record.id_subsector });
			this.setState({ wqsetregion: single_record.id_region });
			this.setState({ wqsector: single_record.id_sector });
			this.setState({ wqsubsector: single_record.id_subsector });
			this.setState({ wqregion: single_record.id_region });
			this.setSubsector(single_record.id_sector)
        }).catch(error => {
           console.log(error);
		});
		
	}
	
	setSubsector(sector_id){
		var fd = new FormData();
        fd.append('sector_id', sector_id);

        $.ajax({
            url: window.$apiBaseUrl+'subsector/read.php',
            type: 'POST',
            data: fd,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData:false,
            success: function(data) {
              this.setState({'subsectors':data.records}, function() {
                //console.log(this.state);
              })
            }.bind(this),
            error: function(xhr, status, err) {
              console.log(err);
            }
        });
	}
	
	getUserOptions() {
        var userDetail = localStorage.getItem("loggedInUser");
        if(typeof userDetail !== 'undefined' && userDetail != null) {
          this.setState({ loggedInStatus: true });
        }else{
			window.location.href='#/';
		}
    }

	getSettings() {
        var wqprice = 0;
        $.ajax({
          url: window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            //console.log(data.records);
            this.setState({'urgent_day': data.records[15].value});
            this.setState({'wqsettings_discount': data.records[4].value});
            this.setState({'wqprice_per_hour': data.records[14].value});
            this.setState({'wqurgent_cost': data.records[13].value});

			{/* var myDate = new Date(new Date().getTime() + (this.state.urgent_day*24*60*60*1000));
            var d = myDate.getDate();
            var m = myDate.getMonth() + 1;
            var y = myDate.getFullYear();
            var wqdate =  ''+y+'-'+(m<=9 ? '0' + m : m)+'-'+(d <= 9 ? '0' + d : d);
            this.setState({'wqstart_date': wqdate});
            var myDate1 = new Date(new Date().getTime() + ((this.state.urgent_day - 1)*24*60*60*1000));
            var d1 = myDate1.getDate();
            var m1 = myDate1.getMonth() + 1;
            var y1 = myDate1.getFullYear();
            var wqdate1 =  ''+y1+'-'+(m1<=9 ? '0' + m1 : m1)+'-'+(d1 <= 9 ? '0' + d1 : d1);
            this.setState({'wqcheck_date': wqdate1}); */}

            wqprice = (((1 * this.state.wqprice_per_hour * 1) + 0) - 0)
            this.setState({'wqprice': wqprice});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }
	
    getSector() {
        $.ajax({
          url: window.$apiBaseUrl+'sector/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            this.setState({'sectors':data.records}, function() {
              //console.log(this.state);
            })
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }

    getSubsector(e) {
        e.preventDefault();
        this.change(e);
        var fd = new FormData();
        fd.append('sector_id', e.target.value);

        $.ajax({
            url: window.$apiBaseUrl+'subsector/read.php',
            type: 'POST',
            data: fd,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData:false,
            success: function(data) {
              this.setState({'subsectors':data.records}, function() {
                //console.log(this.state);
              })
            }.bind(this),
            error: function(xhr, status, err) {
              console.log(err);
            }
        });
    }

    getService() {
        $.ajax({
          url: window.$apiBaseUrl+'service/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            this.setState({'services':data.records}, function() {
              //console.log(this.state);
            })
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }

    getRegion() {
        $.ajax({
          url: window.$apiBaseUrl+'region/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            this.setState({'regions':data.records}, function() {
              //console.log(this.state);
            })
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }
	
	calculatePrice(wqurgent, wqendduration, wqstudent, wqdiscount) {
        var wqprice = 0;
        var wqduration = 0;
        wqduration = parseFloat(parseFloat(wqendduration) / 60);
        //wqprice = (((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour) * parseFloat(wqduration)) + parseFloat(wqurgent)) - parseFloat(wqdiscount))
        wqprice = (((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour) * parseFloat(wqduration)) + parseFloat(wqurgent)) - (parseFloat(wqdiscount) * parseFloat(wqduration)))
        this.setState({'wqprice': wqprice});
    }
  
	formValidate(e){
		var formValid = true;
        if(this.state.wqsector=='') {
            formValid = false;
            $("#wqsector").css('border','2px solid red');
        } else {
            $("#wqsector").css('border','1px solid #ced4da');
        }
        if(this.state.wqsubsector=='') {
            formValid = false;
            $("#wqsubsector").css('border','2px solid red');
        } else {
            $("#wqsubsector").css('border','1px solid #ced4da');
        }
        if(this.state.wqservice=='') {
            formValid = false;
            $("#wqservice").css('border','2px solid red');
        } else {
            $("#wqservice").css('border','1px solid #ced4da');
        }
        if(this.state.wqtitle=='') {
            formValid = false;
            $("#wqtitle").css('border','2px solid red');
        } else {
            $("#wqtitle").css('border','1px solid #ced4da');
        }
        if(this.state.wqdescription==='') {
            formValid = false;
            $("#wqdescription").css('border','2px solid red');
        } else {
            $("#wqdescription").css('border','1px solid #ced4da');
        }
        if(this.state.wqregion==='') {
            formValid = false;
            $("#wqregion").css('border','2px solid red');
        } else {
            $("#wqregion").css('border','1px solid #ced4da');
        }
    
        return formValid;
	}
	
	formSubmit(e){
		e.preventDefault();
		if(this.formValidate()) {
			$('#messagemodel').fadeIn();
			//this.lessonSubmit();
		} else {
            console.log("Validation Error");
        }
		
	}
	
	modalClose(e){
		e.preventDefault();
		$('#messagemodel').fadeOut();
	}
	
	lessonSubmit() {
		this.setState({'final_message': ''});
		this.setState({'error_message': ''});
		var str = '';
        str = this.convert(this.state.wqstart_date)+" "+this.state.wqstart_hour+":"+this.state.wqstart_minutes+":00";
		var str_time = new Date(str).getTime();
        var end_time = (str_time + (this.state.wqend_duration*60*1000));
        var end_date = new Date(end_time);
		var end_datetime = this.convert_with_time(end_date);
		var userDetail = JSON.parse(localStorage.getItem("loggedInUser"));
		var wqcreator = userDetail.id_user;
		//console.log(this.state);
		//console.log(this.state.wqtitle+"=="+this.state.wqdescription+"=="+str+"=="+end_datetime+"=="+this.state.wqtelphone+"=="+this.state.wqstudents_no+"=="+this.state.wqregion+"=="+this.state.wqlocation+"==-0=="+this.state.final_urgent_price+"=="+this.state.wqprice+"=="+this.state.wqservice+"=="+this.state.wqsector+"=="+this.state.wqsubsector+"=="+wqcreator+"=="+this.state.student_id);
		const fd = new FormData();
        fd.append('title', this.state.wqtitle);
        fd.append('description', this.state.wqdescription);
        fd.append('proposal_start', str);
        fd.append('proposal_end', end_datetime);
        fd.append('proposal_fascia', '');
        fd.append('proposal_mandatory', '0');
        fd.append('start', str);
        fd.append('end', end_datetime);
        fd.append('creator_user_type', 'teacher');
        fd.append('tel', this.state.wqtelphone);
        fd.append('nr_person', this.state.wqstudents_no);
        fd.append('payment_status', '0');
        fd.append('id_region', this.state.wqregion);
        fd.append('location', this.state.wqlocation);
        fd.append('is_urgent', '0');
        fd.append('final_urgent_price', this.state.final_urgent_price);
        fd.append('price', this.state.wqprice);
        fd.append('cost', '');
        fd.append('creation_time', '');
        fd.append('id_service', this.state.wqservice);
        fd.append('id_sector', this.state.wqsector);
        fd.append('id_subsector', this.state.wqsubsector);
        fd.append('id_creator', wqcreator);
        fd.append('id_giver', this.state.student_id);
        fd.append('id_worker', wqcreator);
        fd.append('id_admin', '');
        fd.append('feedback', '');
		fd.append('current_language', this.state.current_language);

        axios.post(window.$apiBaseUrl+'job/create.php', fd)
        .then(res => {
			var response = res.data.response;
          if(response == 0){
			 this.setState({'error_message': res.data.message});
		  }else{
			$('.step-line-two').addClass("step-line-animation");
			$("html, body").scrollTop(0);
			$('#messagemodel').fadeOut();
			this.setState({'final_message': res.data.message});
			setTimeout(
				function() {
					window.location.href='#/teacherlesson';
				}
				.bind(this),
				1000
			);
		  }
        }).catch(error => {
           console.log(error);
		});
	}
	
	convert(str) {
		var date = new Date(str),
			mnth = ("0" + (date.getMonth()+1)).slice(-2),
			day  = ("0" + date.getDate()).slice(-2);
		var full_date = [ date.getFullYear() , mnth,  day].join("-");
		//var full_time = [ date.getHours() , date.getMinutes(),  date.getSeconds() ].join(":");
		return full_date;
	}
	convert_with_time(str){
		var date = new Date(str),
			mnth = ("0" + (date.getMonth()+1)).slice(-2),
			day  = ("0" + date.getDate()).slice(-2);
		var full_date = [ date.getFullYear() , mnth,  day].join("-");
		var full_time = [ date.getHours() , date.getMinutes(),  date.getSeconds() ].join(":");
		return [full_date, full_time].join(" ");
	}
  
  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
	let sectors;
    if(this.state.sectors) {
        sectors = this.state.sectors.map((sector,index) => {
            if(sector.is_active == '1') {
                return (
                    <option value={sector.id} selected={sector.id == this.state.wqsetschool}>{sector.sector}</option>
                );
            }
        });
    }

    let subsectors;
    if(this.state.subsectors) {
        subsectors = this.state.subsectors.map((subsector,index) => {
            if(subsector.is_active == '1') {
                return (
                    <option value={subsector.id} selected={subsector.id == this.state.wqsetlevel}>{subsector.subsector}</option>
                );
            }
        });
    }

    let services;
    if(this.state.services) {
        services = this.state.services.map((service,index) => {
            if(service.is_active == '1') {
                return (
                    <option value={service.id}>{service.service}</option>
                );
            }
        });
    }

    let regions;
    if(this.state.regions) {
        regions = this.state.regions.map((region,index) => {
            if(region.is_active == '1') {
                return (
                    <option value={region.id_region} selected={region.id_region == this.state.wqsetregion}>{region.region}</option>
                );
            }
        });
    }  
	
	let total_students;
    total_students = Array(25).fill(0).map((e,i) => {
        return (
            <option value={i+1}>{i+1}</option>
        );
    });
	  
    return (
	<div>
	<Header />
      <div className="AdhocLesson">
        <div className="main">
          <div className="request-lesson">
              <div className="container">
                  <div className="form-wizard">
                      <table className="table">
                          <tr>
								<td width="10%" className="text-right"><span>{language_arr.step} 1</span></td>
                                <td width="37.5%" className="text-center"><div className="step-line step-line-one"></div></td>
                                <td width="5%" className="text-center"><span>{language_arr.step} 2</span></td>
                                <td width="37.5%" className="text-center"><div className="step-line step-line-two"></div></td>
                                <td width="10%" className="text-left"><span className="complete"><i className="fa fa-check" aria-hidden="true"></i></span></td>
                            </tr>
                        </table>
                    </div>
					<div className="step-one-form">
						<form>
							<div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.email}<span>*</span></label>
                                        <input type="email" placeholder={language_arr.enter_email} className="form-control" name="wqemail" id="wqemail" value={this.state.wqemail} onChange={this.emailCheck} />
										<div className="wqmessage">{this.state.wqmessage}</div>
									</div>
                                </div>
								
                            </div>
                        </form>
                    </div>
                    <div className="step-two-form">
						<form>
							<div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.school}<span>*</span></label>
                                        <select className="form-control" name="wqsector" id="wqsector" onChange={this.getSubsector}>
                                            <option value=''>{language_arr.select_school}</option>
                                            {sectors}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.level}<span>*</span></label>
                                        <select className="form-control" name="wqsubsector" id="wqsubsector" onChange={this.change} required>
                                            <option value=''>{language_arr.select_level}</option>
                                            {subsectors}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.subject}<span>*</span></label>
                                        <select className="form-control" name="wqservice" id="wqservice" onChange={this.change} required>
                                            <option value="">{language_arr.select_subject}</option>
                                            {services}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.title}<span>*</span></label>
                                        <input type="text" className="form-control"  name="wqtitle" id="wqtitle" className="form-control" value={this.state.wqtitle} onChange={this.change} required />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.description}<span>*</span> </label>
                                        <textarea rows="4" className="form-control" name="wqdescription" id="wqdescription" className="form-control" onChange={this.change}>{this.state.wqdescription}</textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.start_date}<span>*</span></label>
										<DatePicker locale="en-GB" selected={this.state.wqstart_date} onChange={this.handleChange} dateFormat="dd.MM.yyyy"/>
                                        { /* <form>
											<div className='input-group date' id='datepicker'>
												  <input type='text' className="form-control" />
												  <span className="input-group-addon">
													  <span className="glyphicon glyphicon-calendar"></span>
												  </span>
											</div>
										</form> */ }
                                    </div>
                                </div> 
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.start_hour}<span>*</span></label>
                                        <select className="form-control" name="wqstart_hour" id="wqstart_hour" onChange={this.change} required>
											
											<option value="11">6</option>
											<option value="11">7</option>
											<option value="11">8</option>
											<option value="11">9</option>
                                            <option value="10" selected>10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.start_minutes}</label>
                                        <select className="form-control" name="wqstart_minutes" id="wqstart_minutes" onChange={this.change} required>
                                            <option value="0">00 min</option>
                                            <option value="15">15 min</option>
                                            <option value="30">30 min</option>
                                            <option value="45">45 min</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.end_duration}<span>*</span></label>
                                        <select className="form-control" name="wqend_duration" id="wqend_duration" onChange={this.change} required>
                                            <option value="60">60 min</option>
                                            <option value="90">90 min</option>
                                            <option value="120">120 min</option>
                                            <option value="150">150 min</option>
                                            <option value="180">180 min</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.telephone}</label>
                                        <input type="text" placeholder={language_arr.enter_telephone} name="wqtelphone" id="wqtelphone" className="form-control" value={this.state.wqtelphone} onChange={this.change} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.student_number}<span>*</span></label>
                                        <select className="form-control" name="wqstudents_no" id="wqstudents_no" onChange={this.change} required>
                                          {total_students}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.region}<span>*</span></label>
                                        <select className="form-control" name="wqregion" id="wqregion" onChange={this.change} required>
                                            <option value="">{language_arr.select_region}</option>
                                            {regions}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="field-title">{language_arr.prf_location}</label>
                                      <input type="text" placeholder={language_arr.enter_location} name="wqlocation" id="wqlocation" className="form-control" value={this.state.wqlocation} onChange={this.change} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 text-center">
                                    <div className="form-group">
                                      <p className="price">{language_arr.price}: <span>CHF {this.state.wqprice}</span></p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
										<div className="next-btn">
											<button type="button" id="next-step" onClick={this.formSubmit}>{language_arr.next}</button>
                                        </div>
										<div className="wqsuccess text-center">{this.state.final_message}</div>
										<div className="wqerror text-center">{this.state.error_message}</div>
                                    </div>
                                </div>
                            </div>
                        </form>		
                    </div>
                </div>	
            </div>	
        </div>
      </div>
	  <div className="modal fadeIn" id="messagemodel" tabindex="-1" role="dialog" aria-labelledby="messagemodelLabel" aria-hidden="true">
		  <div className="modal-dialog" role="document">
			<div className="modal-content">
			  <div className="modal-header message-header">
				<h5 className="modal-title" id="exampleModalLabel">{language_arr.confirm_submit}</h5>
				<button type="button" className="close" onClick={this.modalClose} data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			  </div>
			  <form>
			  <div className="modal-body">
				<div className="message-box">
					<div className="form-group text-center">
						<label><span className="title-label">{language_arr.adhoc_conf_msg}</span></label>
					</div>
				</div>
			  </div>
			  <div className="modal-footer text-center">		
				<a href="javascript:void(0);" className="btn btn-send" onClick={this.lessonSubmit}>{language_arr.confirm}</a>
				<a href="javascript:void(0);" type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.modalClose} >{language_arr.cancel}</a>
			  </div>
			  </form>
			</div>
		  </div>
		</div>
	</div>
    );
  }
}

export default AdhocLesson;
