import React, { Component } from 'react';

class HomeTestimonial extends Component {
  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    let testimonials;
    //console.log(this.props.testimonials);

    if(this.props.testimonials) {
      testimonials = this.props.testimonials.map((testimonial,index) => {
				return (
					<div className="item">
						<div className="col-xs-12 col-sm-12 col-md-12">
							<div className="container">
								<div className="card">
									<div className="card-body">
										<img src="/images/cotation.png" className="center-block" />		
										<p>{testimonial.description}</p>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-md-2">
												<img src={testimonial.author_image} className="rounded-circle block-left" />
											</div>
											<div className="col-md-10 text-left c-details">
												<p>{testimonial.author_name}</p>
												<p>{testimonial.author_role}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>    
					</div>
				);
      });
    }
    
    return (
			<div className="sec bgteam">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-left">
							<div className="client">
								<h2>{language_arr.what}  <span className="cont_txt">{language_arr.people}</span> {language_arr.say}</h2>
								<p>{language_arr.stu_par_opinion}</p>
							</div>
						</div>
					</div>
				</div>
	
				<div className="container">
					<div className="row">
    				<div className="col-md-12">
							<div className="owl-carousel owl-theme clien-review">
								{testimonials}
							</div>
							<a className="carousel-control-prev" href="#demo" data-slide="prev">
								<span className="carousel-control-prev-icon"></span>
							</a>
							<a className="carousel-control-next" href="#demo" data-slide="next">
								<span className="carousel-control-next-icon"></span>
							</a>
						</div>
   				</div>
				</div>
			</div>
    );
  }
}

export default HomeTestimonial;
