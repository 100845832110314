import React, { Component } from 'react';
import * as $ from 'jquery';
import axios from 'axios';
import StepNumber from './StepNumber';
import './RequestLesson.css';
import DatePicker, { registerLocale} from "react-datepicker";
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactHtmlParser from 'react-html-parser';
import * as EmailValidator from 'email-validator';
import enGB from "date-fns/locale/en-GB";
import Header from '../Header/Header';
registerLocale("en-GB", enGB);

class RequestLesson extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            'sectors':[],
            'subsectors':[],
            'services':[],
            'regions':[],
            'urgent_day':'',
            'wqsector':'',
            'wqsubsector':'',
            'wqservice':'',
            'wqtitle':'',
            'wqdescription':'',
            'wqstart_date': new Date(),
            'wqcheck_date': new Date(),
            'wqstart_hour':10,
            'wqstart_minutes':'00',
            'wqend_duration':60,
            'wqtelphone':'',
            'wqstudents_no':1,
            'wqregion':'',
            'wqlocation':'',
			
			'wqsetschool':'',
			'wqsetlevel':'',
			'wqsetregion':'',

            'wqsettings_discount':'',
            'wqdiscount':0,
            'wqprice_per_hour':'',
            'wqurgent_cost':'',
            'wqprice':0,

            'loggedInStatus': false,

            'wqname':'',
            'wqfamily_name':'',
            'wqphone':'',
            'wqemail':'',
            'wqpassword':'',
            'wqcpassword':'',
            'wqcondition':false,
            'wqcondition_error':'',
            'wqcreator':'',
            'is_urgent':0,
            'final_urgent_price':0,
            'wqmessage':'',
            'user_check':'',
            'final_message':'',
            'current_language':'',
			'subject_id':'',
			'error_message':'',
        }
        this.change = this.change.bind(this);
        this.getSector = this.getSector.bind(this);
        this.getSubsector = this.getSubsector.bind(this);
        this.setSubsector = this.setSubsector.bind(this);
        this.getService = this.getService.bind(this);
        this.getSettings = this.getSettings.bind(this);
        this.getRegion = this.getRegion.bind(this);

        this.firstValidate = this.firstValidate.bind(this);
        this.firstStep = this.firstStep.bind(this);

        this.getUserOptions = this.getUserOptions.bind(this);

        this.conditioncheck = this.conditioncheck.bind(this);
        this.secondValidate = this.secondValidate.bind(this);
        this.secondStep = this.secondStep.bind(this);

        this.calculatePrice = this.calculatePrice.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.emailCheck = this.emailCheck.bind(this);
        this.forgotEmail = this.forgotEmail.bind(this);
        this.convert = this.convert.bind(this);
        this.convert_with_time = this.convert_with_time.bind(this);
		this.loginSubmit = this.loginSubmit.bind(this);
		this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
		this.getPageTitle = this.getPageTitle.bind(this);
    }

    emailCheck(e) {
        e.preventDefault();
        this.setState({'wqemail': e.target.value});
        var fd = new FormData();
        fd.append('email', e.target.value);
		fd.append('current_language', this.state.current_language);

        if (EmailValidator.validate(e.target.value)) {
            $.ajax({
                url:  window.$apiBaseUrl+'user/exist_user.php',
                type: 'POST',
                data: fd,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,
                success: function(data) {
                    console.log(data);
                    if(data.status == '404') {
                        this.setState({'wqmessage': ''});
                        this.setState({'user_check': 0});
                        $(".wqexist_email").show();
                        $(".wqnotexist_email").show();
                        $(".wqexist_pass").hide();
                    } else if(data.status == '400' || data.status == '300') {
                        this.setState({'wqmessage': data.message});
                        $(".wqexist_email").hide();
                        $(".wqnotexist_email").hide();
                        $(".wqexist_pass").hide();
                    } else {
                        this.setState({'wqmessage': ''});
                        this.setState({'user_check': 1});
                        $(".wqexist_email").show();
                        $(".wqexist_pass").show();
                        $(".wqnotexist_email").hide();
                    }
                }.bind(this),
                error: function(xhr, status, err) {
                    console.log(err);
                }
            });
        } else {
            $(".wqexist_email").hide();
            $(".wqnotexist_email").hide();
            $(".wqexist_pass").hide();
        }
    }

    forgotEmail(e) {
        e.preventDefault();
        const fd = new FormData();
        fd.append('email', this.state.wqemail);

        axios.post( window.$apiBaseUrl+'user/exist.php', fd)
        .then(res => {
            //console.log(res);
            if(res.data.status == '200') {
                this.setState({'wqmessage': res.data.message});
            } else {
                this.setState({'wqmessage': ReactHtmlParser(res.data.message)});
            }
        }).catch(error => {
            console.log(error);
        });
    }

    handleChange(date) {
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
        this.setState({ wqstart_date: date });
        var myDateTime = new Date(this.state.wqcheck_date).getTime();
        var newDateTime = date.getTime();
        if(newDateTime < myDateTime) {
            confirmAlert({
                title: language_arr.conf_start_date,
                message: language_arr.urgent_msg1+this.state.wqurgent_cost+language_arr.urgent_msg2,
                buttons: [
                  {
                    label: language_arr.yes,
                    onClick: () => this.setUrgent()
                  },
                  {
                    label: language_arr.no,
                    onClick: () => this.setDisUrgent()
                  }
                ]
            })
        } else {
            this.setState({ 'is_urgent': 0 });
            this.setState({ 'final_urgent_price': 0 });
            this.calculatePrice(0, this.state.wqend_duration, this.state.wqstudents_no, this.state.wqdiscount);
        }
    }

    setUrgent() {
        this.setState({ 'is_urgent': 1 });
        this.setState({ 'final_urgent_price': this.state.wqurgent_cost });
        this.calculatePrice(this.state.wqurgent_cost, this.state.wqend_duration, this.state.wqstudents_no, this.state.wqdiscount);
    }

    setDisUrgent() {
        this.setState({ 'is_urgent': 0 });
        this.setState({ 'final_urgent_price': 0 });

        var myDate = new Date(new Date().getTime() + (this.state.urgent_day*24*60*60*1000));
        var d = myDate.getDate();
        var m = myDate.getMonth() + 1;
        var y = myDate.getFullYear();
        var wqdate =  ''+y+'-'+(m<=9 ? '0' + m : m)+'-'+(d <= 9 ? '0' + d : d);
        this.setState({'wqstart_date': wqdate});

        this.calculatePrice(0, this.state.wqend_duration, this.state.wqstudents_no, this.state.wqdiscount);
    }

    change(e) {
        this.setState({[e.target.name]: e.target.value});
        var wqdiscount = '';
        var wqstudent = 1;
        if(e.target.name == 'wqstudents_no') {
            if(e.target.value > 1) {
                wqstudent = e.target.value
                wqdiscount = (e.target.value * this.state.wqsettings_discount);
            } else {
                wqdiscount = 0;
            }
            //console.log(wqdiscount);
            this.setState({'wqdiscount': wqdiscount});
            this.calculatePrice(this.state.final_urgent_price, this.state.wqend_duration, wqstudent, wqdiscount);
        }

        if(e.target.name == 'wqend_duration') {
            this.calculatePrice(this.state.final_urgent_price, e.target.value, this.state.wqstudents_no, this.state.wqdiscount);
        }
    }

    calculatePrice(wqurgent, wqendduration, wqstudent, wqdiscount) {
        var wqprice = 0;
        var wqduration = 0;
        wqduration = parseFloat(parseFloat(wqendduration) / 60);
        //wqprice = (((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour) * parseFloat(wqduration)) + parseFloat(wqurgent)) - parseFloat(wqdiscount))
        wqprice = (((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour) * parseFloat(wqduration)) + parseFloat(wqurgent)) - (parseFloat(wqdiscount) * parseFloat(wqduration)))
        this.setState({'wqprice': wqprice});
    }

    conditioncheck(e) { 
        this.setState({'wqcondition': e.target.checked});
    }
	
	getPageTitle(){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj); 
		document.title = language_arr.page_title;
	  }

    componentWillMount() {
        this.getSector();
        this.getService();
        this.getSettings();
        this.getRegion();
        this.getUserOptions();
    }
    
    componentDidMount() {
		this.getPageTitle();
        this.getSector();
        this.getService();
        this.getSettings();
        this.getRegion();
        this.getUserOptions();
        this.getCurrentLanguage();
    }

    getUserOptions() {
        var userDetail = localStorage.getItem("loggedInUser");
			var pre_lesson_creds = localStorage.getItem("pre_lesson_creds");
			if(typeof userDetail !== 'undefined' && userDetail != null) {
			  this.setState({ loggedInStatus: true });
			}
			if(typeof pre_lesson_creds !== 'undefined' && pre_lesson_creds != null) {
				var creds_arr = JSON.parse(pre_lesson_creds);
				this.setState({ wqsetschool: creds_arr.id_sector });
				this.setState({ wqsector: creds_arr.id_sector });
				this.setState({ wqsetlevel: creds_arr.id_subsector });
				this.setSubsector(creds_arr.id_sector);
				this.setState({ wqsubsector: creds_arr.id_subsector });
				this.setState({ wqsetregion: creds_arr.id_region });
				this.setState({ wqregion: creds_arr.id_region });
			}
    }
	
	
	getCurrentLanguage(e){
		$.ajax({
          url:  window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            var current_language = data.records[0].value;
			{ /* this.setState({'current_language':current_language}, function() {
				this.getCurrentLanguage();
			}); */ }
			this.setState({'current_language':current_language});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
	}

    getSettings() {
        var wqprice = 0;
        $.ajax({
          url:  window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            //console.log(data.records);
            this.setState({'urgent_day': data.records[15].value});
            this.setState({'wqsettings_discount': data.records[4].value});
            this.setState({'wqprice_per_hour': data.records[14].value});
            this.setState({'wqurgent_cost': data.records[13].value});

            var myDate = new Date(new Date().getTime() + (this.state.urgent_day*24*60*60*1000));
            var d = myDate.getDate();
            var m = myDate.getMonth() + 1;
            var y = myDate.getFullYear();
            var wqdate =  ''+y+'-'+(m<=9 ? '0' + m : m)+'-'+(d <= 9 ? '0' + d : d);
            this.setState({'wqstart_date': wqdate});
            var myDate1 = new Date(new Date().getTime() + ((this.state.urgent_day - 1)*24*60*60*1000));
            var d1 = myDate1.getDate();
            var m1 = myDate1.getMonth() + 1;
            var y1 = myDate1.getFullYear();
            var wqdate1 =  ''+y1+'-'+(m1<=9 ? '0' + m1 : m1)+'-'+(d1 <= 9 ? '0' + d1 : d1);
            this.setState({'wqcheck_date': wqdate1});

            wqprice = (((1 * this.state.wqprice_per_hour * 1) + 0) - 0)
            this.setState({'wqprice': wqprice});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }

    getSector() {
        $.ajax({
          url:  window.$apiBaseUrl+'sector/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            this.setState({'sectors':data.records}, function() {
              //console.log(this.state);
            })
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }
	
	setSubsector(sector_id){
		var fd = new FormData();
        fd.append('sector_id', sector_id);

        $.ajax({
            url:  window.$apiBaseUrl+'subsector/read.php',
            type: 'POST',
            data: fd,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData:false,
            success: function(data) {
              this.setState({'subsectors':data.records}, function() {
                //console.log(this.state);
              })
            }.bind(this),
            error: function(xhr, status, err) {
              console.log(err);
            }
        });
	}

    getSubsector(e) {
        e.preventDefault();
        this.change(e);
        var fd = new FormData();
        fd.append('sector_id', e.target.value);

        $.ajax({
            url:  window.$apiBaseUrl+'subsector/read.php',
            type: 'POST',
            data: fd,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData:false,
            success: function(data) {
              this.setState({'subsectors':data.records}, function() {
                //console.log(this.state);
              })
            }.bind(this),
            error: function(xhr, status, err) {
              console.log(err);
            }
        });
    }

    getService() {
		let subject_id = this.props.match.params.id;
        $.ajax({
          url:  window.$apiBaseUrl+'service/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            this.setState({'services':data.records});
			var records = data.records;
			var record_length = data.records.length;
			if(typeof subject_id !== 'undefined' && subject_id != null) {
				for(var i=0;i<record_length;i++){
					var single_record = records[i];
					var record_id = single_record.id;
					var is_active = single_record.is_active;
					//console.log(record_id++subject_id++);
					if(record_id == subject_id && is_active == 1){
						subject_id = subject_id;
						this.setState({'wqservice':subject_id});
					}
				}
			}else{
				subject_id = '';
			}
			this.setState({'subject_id':subject_id});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }

    getRegion() {
        $.ajax({
          url:  window.$apiBaseUrl+'region/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            this.setState({'regions':data.records}, function() {
              //console.log(this.state);
            })
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }

    firstValidate(e) {
        var firstValid = true;
        if(this.state.wqsector=='') {
            firstValid = false;
            $("#wqsector").css('border','2px solid red');
        } else {
            $("#wqsector").css('border','1px solid #ced4da');
        }
        if(this.state.wqsubsector=='') {
            firstValid = false;
            $("#wqsubsector").css('border','2px solid red');
        } else {
            $("#wqsubsector").css('border','1px solid #ced4da');
        }
        if(this.state.wqservice=='') {
            firstValid = false;
            $("#wqservice").css('border','2px solid red');
        } else {
            $("#wqservice").css('border','1px solid #ced4da');
        }
        if(this.state.wqtitle=='') {
            firstValid = false;
            $("#wqtitle").css('border','2px solid red');
        } else {
            $("#wqtitle").css('border','1px solid #ced4da');
        }
        if(this.state.wqdescription==='') {
            firstValid = false;
            $("#wqdescription").css('border','2px solid red');
        } else {
            $("#wqdescription").css('border','1px solid #ced4da');
        }
        if(this.state.wqregion==='') {
            firstValid = false;
            $("#wqregion").css('border','2px solid red');
        } else {
            $("#wqregion").css('border','1px solid #ced4da');
        }
    
        return firstValid;
    }

    firstStep(e) {
        e.preventDefault();
        if(this.firstValidate()) {
            //console.log(this.state);
            $('.step-line-one').addClass("step-line-animation");
            if(this.state.loggedInStatus) {
                // Final Submit Function Call Korte Hobe
                this.lessonSubmit();
            } else {
                $('.step-one-form').hide();
                $('.step-two-form').show();
            }
            $("html, body").scrollTop(0);
        } else {
            console.log("Validation Error");
        }
    }

    secondValidate(e) {
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
        var secondValid = true;
        if(this.state.user_check != 1) {
            if(this.state.wqemail=='') {
                secondValid = false;
                $("#wqemail").css('border','2px solid red');
            } else {
                $("#wqemail").css('border','1px solid #ced4da');
            }
            if(this.state.wqpassword==='') {
                secondValid = false;
                $("#wqpassword").css('border','2px solid red');
            } else {
                $("#wqpassword").css('border','1px solid #ced4da');
            }
            if(this.state.wqcpassword==='') {
                secondValid = false;
                $("#wqcpassword").css('border','2px solid red');
            } else {
                if(this.state.wqpassword === this.state.wqcpassword) {
                    $("#wqcpassword").css('border','1px solid #ced4da');
                } else {
                    secondValid = false;
                    $("#wqcpassword").css('border','2px solid red');
                }
            }
            if(this.state.wqcondition===false) {
                secondValid = false;
                this.setState({'wqcondition_error': '*'+language_arr.checkbox_confirm});
            } else {
                this.setState({'wqcondition_error': ''});
            }
        } else {
            if(this.state.wqemail=='') {
                secondValid = false;
                $("#wqemail").css('border','2px solid red');
            } else {
                $("#wqemail").css('border','1px solid #ced4da');
            }
            if(this.state.wqpassword==='') {
                secondValid = false;
                $("#wqpassword").css('border','2px solid red');
            } else {
                $("#wqpassword").css('border','1px solid #ced4da');
            }
            if(this.state.wqcondition===false) {
                secondValid = false;
                this.setState({'wqcondition_error': '*'+language_arr.checkbox_confirm});
            } else {
                this.setState({'wqcondition_error': ''});
            }            
        }
    
        return secondValid;
    }

    secondStep(e) {
        e.preventDefault();
        if(this.secondValidate()) {
            //console.log(this.state);
            //Submit Function Call Korte Hobe
            //this.lessonSubmit();
            this.signupStudent();
            $('.step-line-two').addClass("step-line-animation");
            $("html, body").scrollTop(0);
        } else {
            console.log("Validation Error");
        }
    }
	
	convert(str) {
		var date = new Date(str),
			mnth = ("0" + (date.getMonth()+1)).slice(-2),
			day  = ("0" + date.getDate()).slice(-2);
		var full_date = [ date.getFullYear() , mnth,  day].join("-");
		//var full_time = [ date.getHours() , date.getMinutes(),  date.getSeconds() ].join(":");
		return full_date;
	}
	convert_with_time(str){
		var date = new Date(str),
			mnth = ("0" + (date.getMonth()+1)).slice(-2),
			day  = ("0" + date.getDate()).slice(-2);
		var full_date = [ date.getFullYear() , mnth,  day].join("-");
		var full_time = [ date.getHours() , (date.getMinutes()< 10 ? '0' : '')+date.getMinutes(),  (date.getSeconds()< 10 ? '0' : '')+date.getSeconds() ].join(":");
		return [full_date, full_time].join(" ");
	}

    lessonSubmit() {
        //console.log(this.state);
		this.setState({'final_message': ''});
		this.setState({'error_message': ''});
        var tprice = 0;
        if(this.state.final_urgent_price!=0) {
            tprice = (parseFloat(this.state.wqprice) - parseFloat(this.state.final_urgent_price));
        } else {
            tprice = this.state.wqprice;
        }

        var str = '';
        str = this.convert(this.state.wqstart_date)+" "+this.state.wqstart_hour+":"+this.state.wqstart_minutes+":00";
        var str_time = new Date(str).getTime();
        var end_time = (str_time + (this.state.wqend_duration*60*1000));
        var end_date = new Date(end_time);
		//console.log(this.convert_with_time(end_date));
        var userDetail = JSON.parse(localStorage.getItem("loggedInUser"));
        if(typeof userDetail !== 'undefined' && userDetail != null) {
            //this.setState({'wqcreator': userDetail.id_user});
            var wqcreator = userDetail.id_user;
        } else {
            var wqcreator = this.state.wqcreator;
        }
        
        const fd = new FormData();
        fd.append('title', this.state.wqtitle);
        fd.append('description', this.state.wqdescription);
        fd.append('proposal_start', str);
        fd.append('proposal_end', this.convert_with_time(end_date));
        fd.append('proposal_fascia', '');
        fd.append('proposal_mandatory', '0');
        fd.append('start', '0000-00-00 00:00:00');
        fd.append('end', '0000-00-00 00:00:00');
        fd.append('creator_user_type', 'student');
        fd.append('tel', this.state.wqtelphone);
        fd.append('nr_person', this.state.wqstudents_no);
        fd.append('payment_status', '0');
        fd.append('id_region', this.state.wqregion);
        fd.append('location', this.state.wqlocation);
        fd.append('is_urgent', this.state.is_urgent);
        fd.append('final_urgent_price', this.state.final_urgent_price);
        fd.append('price', tprice);
        fd.append('cost', '0.00');
        //fd.append('creation_time', '');
        fd.append('id_service', this.state.wqservice);
        fd.append('id_sector', this.state.wqsector);
        fd.append('id_subsector', this.state.wqsubsector);
        fd.append('id_creator', wqcreator);
        fd.append('id_giver', wqcreator);
        fd.append('id_worker', '0');
        fd.append('id_admin', '0');
        fd.append('feedback', '');
        fd.append('new_lesson', 'new');
		fd.append('current_language', this.state.current_language);
		var pre_lesson_creds = '{"id_sector":"'+this.state.wqsector+'","id_subsector":"'+this.state.wqsubsector+'","id_region":"'+this.state.wqregion+'"}';
        axios.post( window.$apiBaseUrl+'job/create.php', fd)
        .then(res => {
			console.log(res.data);
          var response = res.data.response;
		  if(response == 0){
			 this.setState({'error_message': res.data.message});
		  }else{
			this.setState({'final_message': res.data.message});
			localStorage.setItem("pre_lesson_creds", pre_lesson_creds);
			if(typeof userDetail !== 'undefined' && userDetail != null) {
				setTimeout(function(){ 
					window.location.href='#/studentlesson';
				}, 1500);
			}else{
				this.loginSubmit();
			}
		  }
        }).catch(error => {
           console.log(error);
        });
    }

    signupStudent() {
        //console.log(this.state);

        const fd = new FormData();
        fd.append('name', this.state.wqname);
        fd.append('family_name', this.state.wqfamily_name);
        fd.append('phone', this.state.wqphone);
        fd.append('email', this.state.wqemail);
        fd.append('password', this.state.wqpassword);
        fd.append('maturity_value', '0');
        fd.append('mail_notify', '1');
        fd.append('app_notify', '0');
        fd.append('is_active', '1');
        fd.append('status', 'default');
        fd.append('type', 'student');

        if(this.state.user_check != 1) {
            axios.post( window.$apiBaseUrl+'user/create.php', fd)
            .then(res => {
                //console.log(res);
                if(res.data.status == '200') {
                    this.setState({'wqcreator': res.data.records.id_user});
                    this.lessonSubmit();
                } else {
                    console.log("Oops! User Already Exist!");
                }
            }).catch(error => {
                console.log(error);
            });
        } else {
            axios.post( window.$apiBaseUrl+'user/exist.php', fd)
            .then(res => {
              //console.log(res.data);
              if(res.data.status == '200') {
                this.setState({'wqcreator': res.data.records.id_user});
                this.lessonSubmit();
              } else {
                console.log("Oops! User Not Exist!");
                this.setState({'wqmessage': ReactHtmlParser(res.data.message)});
              }
            }).catch(error => {
               console.log(error);
            });
        } 
    }
	
	loginSubmit() {
		const fd = new FormData();
		fd.append('email', this.state.wqemail);
		fd.append('password', this.state.wqpassword);

		axios.post( window.$apiBaseUrl+'user/exist.php', fd)
		.then(res => {
		  //console.log(res.data);
		  if(res.data.status == '200') {
			var userString =  JSON.stringify(res.data.records);
            localStorage.setItem("loggedInUser", userString);
			setTimeout(function(){ 
				window.location.href='#/studentlesson';
			}, 1500);  
		  } else {
			this.setState({'error': ReactHtmlParser(res.data.message)});
		  }
		}).catch(error => {
		   console.log(error);
		});
	}

  render() {
	
    var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    let sectors;
    if(this.state.sectors) {
        sectors = this.state.sectors.map((sector,index) => {
            if(sector.is_active == '1') {
                return (
                    <option value={sector.id} selected={sector.id == this.state.wqsetschool}>{sector.sector}</option>
                );
            }
        });
    }

    let subsectors;
    if(this.state.subsectors) {
        subsectors = this.state.subsectors.map((subsector,index) => {
            if(subsector.is_active == '1') {
                return (
                    <option value={subsector.id} selected={subsector.id == this.state.wqsetlevel}>{subsector.subsector}</option>
                );
            }
        });
    }

    let services;
    if(this.state.services) {
        services = this.state.services.map((service,index) => {
            if(service.is_active == '1') {
                return (
                    <option value={service.id} selected={service.id == this.state.subject_id}>{service.service}</option>
                );
            }
        });
    }

    let regions;
    if(this.state.regions) {
        regions = this.state.regions.map((region,index) => {
            if(region.is_active == '1') {
                return (
                    <option value={region.id_region} selected={region.id_region == this.state.wqsetregion}>{region.region}</option>
                );
            }
        });
    }

    let total_students;
    total_students = Array(25).fill(0).map((e,i) => {
        return (
            <option value={i+1}>{i+1}</option>
        );
    });

    let step_button;
    if(this.state.loggedInStatus) {
        step_button = language_arr.submit;
    } else {
        step_button = language_arr.next;
    }

    return (
	<div>
	<Header />
      <div className="RequestLesson">
        <div className="main">
          <div className="request-lesson">
              <div className="container">
                  <div className="form-wizard">
                      <table className="table">
                            <StepNumber stepStatus={this.state.loggedInStatus} /> 
                        </table>
                    </div>
                    <div className="step-one-form">
                          <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.school}<span>*</span></label>
                                        <select className="form-control" name="wqsector" id="wqsector" onChange={this.getSubsector}>
                                            <option value="">{language_arr.select_school}</option>
                                            {sectors}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.level}<span>*</span></label>
                                        <select className="form-control" name="wqsubsector" id="wqsubsector" onChange={this.change} required>
                                            <option value="">{language_arr.select_level}</option>
                                            {subsectors}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.subject}<span>*</span></label>
                                        <select className="form-control" name="wqservice" id="wqservice" onChange={this.change} required>
                                            <option value="">{language_arr.select_subject}</option>
                                            {services}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.title}<span>*</span></label>
                                        <input type="text" placeholder={language_arr.enter_title} name="wqtitle" id="wqtitle" className="form-control" value={this.state.wqtitle} onChange={this.change} required />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.description}<span>*</span> </label>
                                        <textarea rows="4" placeholder={language_arr.enter_description} name="wqdescription" id="wqdescription" className="form-control" onChange={this.change}>{this.state.wqdescription}</textarea>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.start_date}<span>*</span></label>
											<DatePicker locale="en-GB"  selected={this.state.wqstart_date} minDate={new Date()} onChange={this.handleChange}  dateFormat="dd.MM.YYYY"/>
										
                                        {/* <form>
                                        <div className='input-group date' id='datepicker'>
                                              <input type='text' name="wqstart_date" id="wqstart_date" className="wqdatepicker form-control" value={this.state.wqstart_date} onChange={this.change} required />
                                              <span className="input-group-addon">
                                                  <span className="glyphicon glyphicon-calendar"></span>
                                              </span>
                                        </div>
                                        </form> */}
                                    </div>
                                </div> 
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.start_hour}<span>*</span></label>
                                        <select className="form-control" name="wqstart_hour" id="wqstart_hour" onChange={this.change} required>
										
											<option value="11">6</option>
											<option value="11">7</option>
											<option value="11">8</option>
											<option value="11">9</option>
                                            <option value="10" selected>10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.start_minutes}</label>
                                        <select className="form-control" name="wqstart_minutes" id="wqstart_minutes" onChange={this.change} required>
                                            <option value="0">00 min</option>
                                            <option value="15">15 min</option>
                                            <option value="30">30 min</option>
                                            <option value="45">45 min</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.end_duration}<span>*</span></label>
                                        <select className="form-control" name="wqend_duration" id="wqend_duration" onChange={this.change} required>
                                            <option value="60">60 min</option>
                                            <option value="90">90 min</option>
                                            <option value="120">120 min</option>
                                            <option value="150">150 min</option>
                                            <option value="180">180 min</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.telephone}</label>
                                        <input type="text" placeholder={language_arr.enter_telephone} name="wqtelphone" id="wqtelphone" className="form-control" value={this.state.wqtelphone} onChange={this.change} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.student_number}<span>*</span></label>
                                        <select className="form-control" name="wqstudents_no" id="wqstudents_no" onChange={this.change} required>
                                            {total_students}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label className="field-title">{language_arr.region}<span>*</span></label>
                                        <select className="form-control" name="wqregion" id="wqregion" onChange={this.change} required>
                                            <option value="">{language_arr.select_region}</option>
                                            {regions}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="field-title">{language_arr.prf_location}</label>
                                      <input type="text" placeholder={language_arr.enter_location} name="wqlocation" id="wqlocation" className="form-control" value={this.state.wqlocation} onChange={this.change} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 text-center">
                                    <div className="form-group">
                                      <p className="price">{language_arr.price}: <span>CHF {this.state.wqprice}</span></p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <div className="next-btn">
                                            <button onClick={this.firstStep} type="button">{step_button}</button>
                                        </div>
                                        <div className="wqsuccess text-center">{this.state.final_message}</div>
                                        <div className="wqerror text-center">{this.state.error_message}</div>
                                    </div>
                                </div>
                            </div>	
                    </div>

                    <div className="step-two-form">
                        <div className="row">
							<div className="col-md-6 col-sm-6">
								<div className="form-group">
									<label className="field-title">{language_arr.email}<span>*</span></label>
									<input type="email" placeholder={language_arr.enter_email} className="form-control" name="wqemail" id="wqemail" value={this.state.wqemail} onChange={this.emailCheck} />
									<div className="wqmessage">{this.state.wqmessage}</div>
								</div>
							</div>
							<div className="wqnotexist_email col-md-6 col-sm-6">
								<div className="form-group">
									<label className="field-title">{language_arr.name}</label>
									<input type="text" placeholder={language_arr.enter_name} className="form-control" name="wqname" id="wqname" value={this.state.wqname} onChange={this.change} />
								</div>
							</div>
							<div className="wqexist_email col-md-6 col-sm-6">
								<div className="form-group">
									<label className="field-title">{language_arr.password}<span>*</span></label>
									<input type="password" placeholder={language_arr.enter_password} className="form-control" name="wqpassword" id="wqpassword" value={this.state.wqpassword} onChange={this.change} />
								</div>
							</div>
							<div className="wqexist_email col-md-6 col-sm-6 wqexist_pass">
								<div className="form-group">
									<a href="javascript:void(0)" onClick={this.forgotEmail}>{language_arr.forgot_your_pass}</a>
								</div>
							</div>
							<div className="wqnotexist_email col-md-6 col-sm-6">
								<div className="form-group">
									<label className="field-title">{language_arr.family_name}</label>
									<input type="text" placeholder={language_arr.enter_family_name} className="form-control" name="wqfamily_name" id="wqfamily_name" value={this.state.wqfamily_name} onChange={this.change} />
								</div>
							</div>
							<div className="wqnotexist_email col-md-6 col-sm-6">
								<div className="form-group">
									<label className="field-title">{language_arr.retype_password}<span>*</span></label>
									<input type="password" placeholder={language_arr.enter_conf_password} className="form-control" name="wqcpassword" id="wqcpassword" value={this.state.wqcpassword} onChange={this.change} />
								</div>
							</div> 
							<div className="wqnotexist_email col-md-6 col-sm-6">
								<div className="form-group">
									<label className="field-title">{language_arr.phone}</label>
									<input type="number" placeholder={language_arr.enter_phone} className="form-control" name="wqphone" id="wqphone" value={this.state.wqphone} onChange={this.change} />
								</div>
							</div>
							<div className="col-md-12 col-sm-12">
							  <div className="form-group">
								<label className="container-checkbox agree-with">{language_arr.agree_with} <a href="#/generalcondition" target='_blank'>{language_arr.stu_condition}</a><span className="mendatory">*</span>
									<input type="checkbox" name="wqcondition" id="wqcondition" onChange={this.conditioncheck} defaultChecked={false} /><span className="checkmark"></span>
									<div className="wqerror">{this.state.wqcondition_error}</div>
								</label>
							</div>
							</div>
							<div className="col-md-12 col-sm-12">
								<div className="form-group">
								  <div className="row">
										<div className="col-md-6 col-sm-6 text-right">
											<div className="requst-lesson-previous-btn">
												<button type="button">{language_arr.previous}</button>
											</div>	
										</div>
										<div className="col-md-6 col-sm-6 text-left">
											<div className="requst-lesson-submit-btn">
												<button onClick={this.secondStep} type="button">{language_arr.submit}</button>
											</div>
										</div>
										<div className="wqsuccess text-center without-succ">{this.state.final_message}</div>
									</div>
								</div>
							</div>
						</div>
                    </div>
                </div>	
            </div>	
        </div>
      </div>
    </div>
    );
  }
}

export default RequestLesson;