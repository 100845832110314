import React, { Component } from 'react';
import $ from 'jquery';
import './Footer.css';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'footerlogo': '',
      'footerText': '',
      'footerAddress': '',
      'footerPhone': '',
      'footerEmail': '',
      'footerWebsite': '',
      'facebookLink': '',
      'twitterLink': '',
      'linkedinLink': '',
      'googleplusLink': '',
      'instagramLink': ''
    }
    this.getFooterOptions = this.getFooterOptions.bind(this);
  }

  getFooterOptions() {
    $.ajax({
      url: window.$apiBaseUrl+'options/read.php',
      type: 'GET',
      dataType: 'json',
      cache: false,
      contentType: false,
      processData:false,
      success: function(data) {
        //console.log(data.records);
        this.setState({'footerlogo': data.records[12].option_value});
        this.setState({'footerText': data.records[11].option_value});
        this.setState({'footerAddress': data.records[10].option_value});
        this.setState({'footerPhone': data.records[9].option_value});
        this.setState({'footerEmail': data.records[8].option_value});
        this.setState({'footerWebsite': data.records[7].option_value});
        this.setState({'facebookLink': data.records[6].option_value});
        this.setState({'twitterLink': data.records[5].option_value});
        this.setState({'linkedinLink': data.records[10].option_value});
        this.setState({'googleplusLink': data.records[3].option_value});
        this.setState({'instagramLink': data.records[2].option_value});
        //console.log(this.state);
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
      }
    });
  }

  componentWillMount() {
    this.getFooterOptions();
  }

  componentDidMount() {
    this.getFooterOptions();
  }

  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    return (
      <div className="Footer">
        <footer>
          <div className="bg-footer">
	          <div className="container">
	            <div className="row">
		            <div className="col-md-4">
			            <div className="content-footer text-left">
				            <div className="foot_contact_sec">
								{ /* <p><a href="javascript:void(0)"><i className="fa fa-phone" aria-hidden="true"></i> {this.state.footerAddress}</a></p> */ }
								<p><a href="javascript:void(0)"><i className="fa fa-envelope" aria-hidden="true"></i> {this.state.footerEmail}</a></p>
								{ /* <p><a href="javascript:void(0)"><img src="images/earth.png" /> {this.state.footerEmail}</a></p> */ }			
								<p><a href="javascript:void(0)"><img src="images/earth.png" /> {this.state.footerWebsite}</a></p>
								<p><a href="javascript:void(0)"><i className="fa fa-copyright" aria-hidden="true"></i>{this.state.footerText}</a></p>
								<ul className="footer-social">
									<li><a target="_blank" href={this.state.facebookLink}><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
									<li><a target="_blank" href={this.state.twitterLink}><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
									<li><a target="_blank" href={this.state.googleplusLink}><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
									<li><a target="_blank" href={this.state.instagramLink}><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
									<li><a target="_blank" href={this.state.linkedinLink}><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
								</ul>
				            </div>
		  	          </div>		  
		            </div>
		            <div className="col-md-4 offset-md-4">
				          <div className="content-footer">
				            <div className="use-header">		
				              <h2>{language_arr.menu_links}</h2>
				            </div>
				            <div className="use-links">
                      <ul className="footer-link">
                        <li><a href="#/">{language_arr.home}</a></li>
                        <li><a href="#/requestlesson">{language_arr.request_lesson}</a></li>
                        { /* <li><a href="javascript:void(0)">BECOME TEACHER</a></li> */ }
                        <li><a href="#/contact">{language_arr.contact_us}</a></li>
                        <li><a href="#/generalcondition">{language_arr.stu_condition}</a></li>
                        <li><a href="#/generalteachercondition">{language_arr.teach_condition}</a></li>
                      </ul>
				            </div>
				          </div>
		            </div>
	            </div>
            </div>
          </div>
          <div className="copyright_sec"> 
			<div className="container text-center">
			  <p>mise.ch tutti i diritti riservati. Svizzera</p>
			  <span>develop by <a href="https://www.evobyte.ch/" target="_blank">Evobyte Software</a></span>    
			</div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
