import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import './TeacherAccount.css';
import Header from '../Header/Header';

class TeacherAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'loggedInStatus': false,
      'userid': '',
      'currentUser': '',
      'name': '',
      'family_name': '',
      'phone': '',
      'password': '',
      'maturity_value': '',
      'mail_notify': '',
      'app_notify': '',
      'error':'',
      'success':'',
	  'current_language':''
    }
    this.change = this.change.bind(this);
    this.getUserOptions = this.getUserOptions.bind(this);
    this.conditionCheck = this.conditionCheck.bind(this);
    this.mconditionCheck = this.mconditionCheck.bind(this);
    this.taccountSubmit = this.taccountSubmit.bind(this);
	this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
	this.getPageTitle = this.getPageTitle.bind(this);
  }

  change(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  conditionCheck(e) {    
    var i = 0;
    if(e.target.checked) { i = 1; }
    this.setState({[e.target.name]: i});
  }

  mconditionCheck(e) {    
    var j = 5;
    if(e.target.checked) { j = 0; }
    this.setState({[e.target.name]: j});
  }
  
  getCurrentLanguage(e){
		$.ajax({
          url: window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            var current_language = data.records[0].value;
			{ /* this.setState({'current_language':current_language}, function() {
				this.getCurrentLanguage();
			}); */ }
			this.setState({'current_language':current_language});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
	}

  getUserOptions() {
    var userDetail = localStorage.getItem("loggedInUser");
    if(typeof userDetail !== 'undefined' && userDetail != null) {
      this.setState({ loggedInStatus: true,  currentUser: JSON.parse(userDetail)});
      this.setState({ 'userid': this.state.currentUser.id_user });
      this.setState({ 'name': this.state.currentUser.name });
      this.setState({ 'family_name': this.state.currentUser.family_name });
      this.setState({ 'phone': this.state.currentUser.phone });
      this.setState({ 'maturity_value': this.state.currentUser.maturity_value });
      this.setState({ 'mail_notify': this.state.currentUser.mail_notify });
      this.setState({ 'app_notify': this.state.currentUser.app_notify });
    }
    //console.log(this.state);
  }

  componentWillMount() {
    this.getUserOptions();
  }

  componentDidMount() {
	this.getPageTitle();
    this.getUserOptions();
	this.getCurrentLanguage();
  }
  
  getPageTitle(){
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj); 
	document.title = language_arr.page_title;
  }

  taccountSubmit(e) {
    e.preventDefault();
    this.setState({'success': ''});
    this.setState({'error': ''});
    console.log(this.state);

    const fd = new FormData();
    fd.append('userid', this.state.userid);
    fd.append('name', this.state.name);
    fd.append('family_name', this.state.family_name);
    fd.append('phone', this.state.phone);
    fd.append('password', this.state.password);
    fd.append('maturity_value', this.state.maturity_value);
    fd.append('mail_notify', this.state.mail_notify);
    fd.append('app_notify', this.state.app_notify);
    fd.append('is_active', 1);
	fd.append('current_language', this.state.current_language);

    axios.post(window.$apiBaseUrl+'user/update.php', fd)
      .then(res => {
        console.log(res);
        if(res.data.status == '200') {
          this.setState({'success':res.data.message}, function() {
            localStorage.clear();
            var userString =  JSON.stringify(res.data.records);
            localStorage.setItem("loggedInUser", userString);
          });
        } else {
          this.setState({'error': res.data.message});
        }
      }).catch(error => {
          console.log(error);
    });
  }

  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    let wqmail_notify;
    if(this.state.mail_notify == '1') {
      wqmail_notify = true;
    } else {
      wqmail_notify = false;
    }

    let wqapp_notify;
    if(this.state.app_notify == '1') {
      wqapp_notify = true;
    } else {
      wqapp_notify = false;
    }

    let wqmaturity_value;
    if(this.state.maturity_value == '0') {
      wqmaturity_value = true;
    } else {
      wqmaturity_value = false;
    }

    if(this.state.loggedInStatus) {
      if(this.state.currentUser.type != 'student'){
        return (
		<div>
		<Header />
          <div className="TeacherAccount">
            <div className="main">
              <div className="techreg-bg">
                  <div className="login-bg-overlay">
                        <div className="login-form">
                          <h3>{language_arr.my_account}</h3>
                          <form onSubmit={this.taccountSubmit}>
                                <div className="form-group">
                                  <input type="text" value={this.state.name} onChange={this.change} name="name" placeholder={language_arr.enter_name} className="form-control" required />
                                </div>
                                <div className="form-group">
                                  <input type="text" value={this.state.family_name} onChange={this.change} name="family_name" placeholder={language_arr.enter_family_name} className="form-control" required />
                                </div>
                                <div className="form-group">
                                  <input type="number" value={this.state.phone} onChange={this.change} name="phone" placeholder={language_arr.enter_tel_optional} className="form-control" />
                                </div>
                                <div className="form-group">
                                  <input type="email" value={this.state.currentUser.email} name="email" placeholder={language_arr.enter_email} className="form-control" readonly="readonly" />
                                </div>
                                <div className="form-group">
                                  <input type="password" name="password" onChange={this.change} placeholder={language_arr.enter_password} className="form-control" />
                                </div>	
                                <div className="form-group">
                                  <label className="container-checkbox condiction-link">{language_arr.notify_me_by_email}
                                      <input type="checkbox" name="mail_notify" onChange={this.conditionCheck} checked={wqmail_notify} />
                                      <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="form-group">
                                  <label className="container-checkbox condiction-link">{language_arr.notify_me_by_app}
                                      <input type="checkbox" name="app_notify" onChange={this.conditionCheck} checked={wqapp_notify} />
                                      <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="form-group">
                                  <label className="container-checkbox condiction-link">{language_arr.have_maturity}
                                      <input type="checkbox" name="maturity_value" onChange={this.mconditionCheck} checked={wqmaturity_value} />
                                      <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="form-group">
                                  <input type="submit" value={language_arr.submit} className="login-btn" />
                                  <div className="wqerror text-center">{this.state.error}</div>
                                  <div className="wqsuccess text-center">{this.state.success}</div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        );
      } else {
        return (
		<div>
		<Header />
          <div className="TeacherAccount">
            <div className="main">
              <div className="techreg-bg">
                  <div className="login-bg-overlay">
                    <div className="login-form">
                      <h3>{language_arr.page_not_access_msg}</h3>  
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        ); 
      }
    } else {
      return (
	  <div>
		<Header />
        <div className="TeacherAccount">
          <div className="main">
            <div className="techreg-bg">
                <div className="login-bg-overlay">
                  <div className="login-form">
                    <h3>{language_arr.page_not_access_msg}</h3>  
                  </div>
                </div>
            </div>
          </div>
        </div>
       </div>
      );      
    }
  }
}

export default TeacherAccount;
