import React, { Component } from 'react';
import './LessonDetails.css';
import DetailsBody from './DetailsBody';
import $ from 'jquery';
import axios from 'axios';
import Moment from 'react-moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import Header from '../Header/Header';
registerLocale("en-GB", enGB);

class LessonDetails extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			job_id: '',
			job_title: '',
			job_description: '',
			proposal_start: '',
			proposal_end: '',
			proposal_fascia: '',
			proposal_mandatory: '',
			start: '',
			start_time_show: '',
			end_time_show: '',
			end: '',
			creator_user_type: '',
			tel: '',
			nr_person: '',
			payment_status: '',
			id_region: '',
			location: '',
			is_urgent: '',
			final_urgent_price: '',
			price: '',
			actual_price: '',
			cost: '',
			creation_time: '',
			id_service: '',
			id_sector: '',
			id_subsector: '',
			id_creator: '',
			student_family_name: '',
			worker_family_name: '',
			user_email: '',
			worker_email: '',
			id_giver: '',
			id_worker: '',
			id_admin: '',
			feedback: '',
			offer_id: '',
			message: '',
			wq_file_gallery: '',
			job_user_creator: '',
			job_user_giver: '',
			job_user_worker: '',
			job_admin: '',
			job_region: '',
			job_service: '',
			job_sector: '',
			job_subsector: '',
			job_log_latest_status: '',
			all_chats: [],
			all_feedbacks: [],
			single_chat_body: [],
			show_pencil_icon_for_desc:'',
			show_pencil_icon_for_nr_person:'',
			show_pencil_icon_for_location:'',
			show_pencil_icon_for_telephone:'',
			desc_box_readonly:'',
			location_box_readonly:'',
			telephone_box_readonly:'',
			nr_dropdown_disabled:'',
			update_button:'',
			assign_button:'',
			approve_button:'',
			delete_button:'',
			close_button:'',
			startDate: new Date(),
			jobStartDate:'',
			jobEndDate: '',
			datepickerDisabled: "disabled",
			wqfeedback: '',
			wqpstatus: '',
			wqjobid: '',
			wqworker: '',
			wqgiver: '',
			urgent_day: '',
			wqsettings_discount: 0,
			wqprice_per_hour: '',
			wqurgent_cost: '',
			wqstart_date: '',
			wqcheck_date: '',
			wqprice: 0,
			wqdiscount: '',
			minDatecount: '',
			wqend_duration: '',
			current_user_type: '',
			msgSuccess:'',
			role_feedback_menu:[],
			role_message_menu:[],
			extra_student_status:'',
			extra_teacher_status:'',
			write_msg_box: 1,
			current_language:'',
			all_location_suggesion:[],
			location_textbox_setfocus:0,
			back_to_pool_button:[]
		}
		this.change = this.change.bind(this);
		this.lessonUpdate = this.lessonUpdate.bind(this);
		this.getLessonChat = this.getLessonChat.bind(this);
		//this.showSingleChat = this.showSingleChat.bind(this);
		this.backToMsg = this.backToMsg.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleStartChange = this.handleStartChange.bind(this);
		this.handleEndChange = this.handleEndChange.bind(this);
		this.convert = this.convert.bind(this);
		this.showPopup = this.showPopup.bind(this);
		this.feedbackSubmit = this.feedbackSubmit.bind(this);
		this.calculatePrice = this.calculatePrice.bind(this);
		this.getSettings = this.getSettings.bind(this);
		this.inputStartChange = this.inputStartChange.bind(this);
		this.inputEndChange = this.inputEndChange.bind(this);
		this.modalClose = this.modalClose.bind(this);
		this.changeStatusToApprove = this.changeStatusToApprove.bind(this);
		this.loadMessages = this.loadMessages.bind(this);
		this.getAllFeedback = this.getAllFeedback.bind(this);
		this.lessonUpdateFunc = this.lessonUpdateFunc.bind(this);
		this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
		this.getLocationSuggetion = this.getLocationSuggetion.bind(this);
		this.convert_for_location = this.convert_for_location.bind(this);
		this.getPageTitle = this.getPageTitle.bind(this);
		this.focus = this.focus.bind(this);
		this.backToLessonList = this.backToLessonList.bind(this);
	  }
	
	componentDidMount() {
		this.getPageTitle();
		this.getSingleLesson();
		this.getLessonChat();
		this.getSettings();
		this.getAllFeedback();
		this.getCurrentLanguage();
	}
	
	loadMessages(){
		this.getLessonChat();
	}
	
	modalClose(){
		$('.custom-pop-wrap').fadeOut();	
		$('.custom-pop').hide();
	}
	
	getPageTitle(){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj); 
		document.title = language_arr.page_title;
	  }
	
	getLocationSuggetion(e){
		var job_location = this.state.location;
		var job_start = this.state.jobStartDate;
		var job_end = this.state.jobEndDate;
		var userDetail = localStorage.getItem("loggedInUser");
		var user_obj = JSON.parse(userDetail);
		var current_user_type = user_obj.type;
		
		if(job_location!="" && job_start!="" && job_end!="" && current_user_type=='teacher'){
			const fd = new FormData();
			fd.append('location_search_key', job_location);
			fd.append('job_start_date', this.convert(job_start));
			fd.append('job_end_date', this.convert(job_end));
			$.ajax({
			  url: window.$apiBaseUrl+'job/search_job_location_by_start_end.php',
			  type: 'POST',
			  data: fd,
			  dataType: 'json',
			  cache: false,
			  contentType: false,
			  processData:false,
			  success: function(data) {
				//console.log(data);
				var records = data.records;
				var record_length = records.length;
				let location_suggesion_body = [];
				if(record_length > 0){
					//location_suggesion_body.push(<div>);
					for(var i=0; i<record_length ; i++){
						var single_record = records[i];
						location_suggesion_body.push(
							<div className="location_track text-left">
								{this.convert_for_location(single_record.start)} - {this.convert_for_location(single_record.end)}, {single_record.location}, {single_record.job_region}
							</div>
						);
					}
					//location_suggesion_body.push(</div>);
				}
				this.setState({all_location_suggesion : location_suggesion_body});
			  }.bind(this),
			  error: function(xhr, status, err) {
				console.log(err);
			  }
			});
		}else{
			this.setState({all_location_suggesion : ''});
		}
	}
	
	getCurrentLanguage(e){
		$.ajax({
          url: window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            var current_language = data.records[0].value;
			{ /* this.setState({'current_language':current_language}, function() {
				this.getCurrentLanguage();
			}); */ }
			this.setState({'current_language':current_language});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
	}
	
	getAllFeedback(){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
		var userDetail = JSON.parse(localStorage.getItem("loggedInUser"));
		var user_id = userDetail.id_user;
		var user_type = userDetail.type;
		let lesson_id = this.props.match.params.id;
		//console.log(userDetail);
		const fd = new FormData();
		fd.append('user_id', user_id);
		fd.append('user_type', user_type);
		fd.append('lesson_id', lesson_id);
		$.ajax({
			url: window.$apiBaseUrl+'email/read_feedback.php',
			type: 'POST',
			data: fd,
			dataType: 'json',
			cache: false,
			contentType: false,
			processData:false,
			success: function(data) {
				var records = data.records;
				var record_length = records.length;
				let total_feedbackbody = [];
				if(record_length > 0){
					for(var i=0; i<record_length ; i++){
						var single_record = records[i];
						//console.log(single_record);
						total_feedbackbody.push(
							<div className="feedback-box">
                                <div className="row">
								<div className="col-md-12 text-left">
									{/* <div className="feedback-text text-left">
									  <label className="label-name">{language_arr.user_email_teacher} :  </label>
									  <label className="feedback-data"><p> {single_record.worker_email}</p></label>
									</div> */ }
									<div className="feedback-text text-left">
									  <label className="label-name">{language_arr.user_name_teacher} :  </label>
									  <label className="feedback-data"><p> {single_record.teacher_name} {single_record.teacher_family_name}</p></label>
									</div>
									{ /* <div className="feedback-text text-left">
									  <label className="label-name">{language_arr.family_name_teacher} :  </label>
									  <label className="feedback-data"><p> {single_record.teacher_family_name}</p></label>
									</div>	 */	}							
									</div>
									<div className="col-md-12 text-justify">
									  <label className="feedback-data"><i className="fa fa-calendar" aria-hidden="true"></i></label>
									  <label className="feedback-data"><p><Moment format="DD.MM.YYYY HH:mm">{single_record.feedback_time}</Moment></p></label>
									</div>
									<div className="col-md-12 text-justify">
									  <label className="feedback-data"><i className="fa fa-comments" aria-hidden="true"></i></label>
									  <label className="feedback-data"><p>{single_record.feedback}</p></label>										
									</div>
                                </div>
                            </div>
						);
					}
				}
				this.setState({all_feedbacks : total_feedbackbody});
			}.bind(this),
			error: function(xhr, status, err) {
				console.log(err);
			}
		});
	}
	
	getSettings() {
        var wqprice = 0;
        $.ajax({
          url: window.$apiBaseUrl+'settings/read.php',
          type: 'POST',
          dataType: 'json',
          cache: false,
          contentType: false,
          processData:false,
          success: function(data) {
            //console.log(data.records);
            this.setState({'urgent_day': data.records[15].value});
            this.setState({'wqsettings_discount': data.records[4].value});
            this.setState({'wqprice_per_hour': data.records[14].value});
            this.setState({'wqurgent_cost': data.records[13].value});

            var myDate = new Date(new Date().getTime() + (this.state.urgent_day*24*60*60*1000));
            var d = myDate.getDate();
            var m = myDate.getMonth() + 1;
            var y = myDate.getFullYear();
            var wqdate =  ''+y+'-'+(m<=9 ? '0' + m : m)+'-'+(d <= 9 ? '0' + d : d);
            this.setState({'wqstart_date': wqdate});
			{ /* var myDate1 = new Date(new Date().getTime() + ((this.state.urgent_day - 1)*24*60*60*1000));
            var d1 = myDate1.getDate();
            var m1 = myDate1.getMonth() + 1;
            var y1 = myDate1.getFullYear();
            var wqdate1 =  ''+y1+'-'+(m1<=9 ? '0' + m1 : m1)+'-'+(d1 <= 9 ? '0' + d1 : d1); */ }
			var checkdate = new Date(new Date(this.state.creation_time).getTime() + (this.state.urgent_day*24*60*60*1000));
            this.setState({'wqcheck_date': this.convert(checkdate)});

            wqprice = (((1 * this.state.wqprice_per_hour * 1) + 0) - 0)
            this.setState({'wqprice': wqprice});
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
          }
        });
    }
	
	handleChange(date) {
		
		this.setState({
		  startDate: date,
		});
	}
	handleStartChange(date) {
		var start_timestamp = date.getTime();
		var end_timestamp = start_timestamp+(1*60*60*1000);
		this.setState({
		  jobStartDate: start_timestamp,
		});
		this.setState({
		  jobEndDate: end_timestamp,
		});
		//this.setState({start_time_show : 'showTimeSelect'});
		//console.log(this.state.job_log_latest_status);
		var current_timestamp = new Date().getTime();
		//var end_timestamp = this.state.jobEndDate;
		var wqcheck_date = new Date(this.state.wqcheck_date).getTime();
		var is_urgent = this.state.is_urgent;
		//console.log(start_timestamp+"=="+new Date(this.convert(date))+"=="+this.convert(date)+"=="+new Date(this.convert(date)).getTime());
		//alert(this.state.wqcheck_date)
		if(end_timestamp!='' && end_timestamp > start_timestamp && start_timestamp > current_timestamp){
			if(start_timestamp > wqcheck_date && is_urgent == '1'){
				this.setState({is_urgent : 0});
			}
			if(start_timestamp < wqcheck_date && is_urgent == '0'){
				this.setState({is_urgent : 1});
			}
			setTimeout(() => {
				this.calculatePrice();
				this.getLocationSuggetion();
			},150);
			
		}else{
			if(this.state.job_log_latest_status==='approved'){
				if(start_timestamp > wqcheck_date && is_urgent == '1'){
					this.setState({is_urgent : 0});
				}
				if(start_timestamp < wqcheck_date && is_urgent == '0'){
					this.setState({is_urgent : 1});
				}
				setTimeout(() => {
					this.calculatePrice();
					this.getLocationSuggetion();
				},150);
			}
		}
	}
	handleEndChange(date) {
		var end_timestamp = date.getTime();
		this.setState({
		  jobEndDate: end_timestamp,
		});
		this.setState({end_time_show : 'showTimeSelect'});
		var start_time = this.state.jobStartDate;
		var current_timestamp = new Date().getTime();
		
		var wqcheck_date = new Date(this.state.wqcheck_date).getTime();
		var is_urgent = this.state.is_urgent;
		//alert(end_timestamp+"=="+start_time+"=="+wqcheck_date+"=="+current_timestamp);
		//alert(start_time+"=="+wqcheck_date+"=="+is_urgent);
		if(end_timestamp>start_time && end_timestamp>current_timestamp){
			if(start_time > wqcheck_date && is_urgent == '1'){
				this.setState({is_urgent : 0});
			} 
			if(start_time < wqcheck_date && is_urgent == '0'){
				this.setState({is_urgent : 1});
			} 
			setTimeout(() => {
				this.calculatePrice();
				this.getLocationSuggetion();
			},150);
		}else{
			if(this.state.job_log_latest_status==='approved'){
				if(start_time > wqcheck_date && is_urgent == '1'){
					this.setState({is_urgent : 0});
				} 
				if(start_time < wqcheck_date && is_urgent == '0'){
					this.setState({is_urgent : 1});
				} 
				setTimeout(() => {
					this.calculatePrice();
					this.getLocationSuggetion();
				},150);
			}
		}
	}
	inputStartChange(e) {
		var result = e.target.value.split('.');
		
		var date = result[0];
		var month = result[1];
		var rest_time = result[2].split(" ");
		var year = rest_time[0];
		var input_time = rest_time[1];
		
		var final_time = year+"-"+month+"-"+date+"T"+input_time+":00";
		//console.log(final_time);
		var date_obj = new Date(final_time).getTime();
		var end_date_obj = date_obj+(1*60*60*1000);
		//alert(myDateTime+"=="+date_obj);
		this.setState({jobStartDate : date_obj});
		this.setState({jobEndDate : end_date_obj});
		
		this.setState({start_time_show : 'showTimeSelect'});
		var end_timestamp = end_date_obj;
		var current_timestamp = new Date().getTime();
		var wqcheck_date = new Date(this.state.wqcheck_date).getTime();
		var is_urgent = this.state.is_urgent;
		if(end_timestamp!='' && end_timestamp > date_obj && date_obj > current_timestamp){
			if(date_obj > wqcheck_date && is_urgent == '1'){
				this.setState({is_urgent : 0});
			}
			if(date_obj < wqcheck_date && is_urgent == '0'){
				this.setState({is_urgent : 1});
			}
			setTimeout(() => {
				this.calculatePrice();
				this.getLocationSuggetion();
			},150);
		}else{
			if(this.state.job_log_latest_status==='approved'){
				if(date_obj > wqcheck_date && is_urgent == '1'){
					this.setState({is_urgent : 0});
				}
				if(date_obj < wqcheck_date && is_urgent == '0'){
					this.setState({is_urgent : 1});
				}
				setTimeout(() => {
					this.calculatePrice();
					this.getLocationSuggetion();
				},150);
			}
		}
	}
	inputEndChange(e) {
		var result = e.target.value.split('.');
		var date = result[0];
		var month = result[1];
		var rest_time = result[2].split(" ");
		var year = rest_time[0];
		var input_time = rest_time[1];
		var final_time = year+"-"+month+"-"+date+"T"+input_time+":00";
		//console.log(final_time);
		var date_obj = new Date(final_time).getTime();
		this.setState({jobEndDate : date_obj});
		this.setState({end_time_show : 'showTimeSelect'});
		var start_time = this.state.jobStartDate;
		var current_timestamp = new Date().getTime();
		var wqcheck_date = new Date(this.state.wqcheck_date).getTime();
		var is_urgent = this.state.is_urgent;
		//alert(end_timestamp+"=="+start_time+"=="+wqcheck_date+"=="+current_timestamp);
		if(date_obj>start_time && date_obj>current_timestamp && this.state.job_log_latest_status!=='approved'){
			if(start_time > wqcheck_date && is_urgent == '1'){
				this.setState({is_urgent : 0});
			}
			if(start_time < wqcheck_date && is_urgent == '0'){
				this.setState({is_urgent : 1});
			}
			setTimeout(() => {
				this.calculatePrice();
				this.getLocationSuggetion();
			},150);
		}else{
			if(this.state.job_log_latest_status==='approved'){
				if(start_time > wqcheck_date && is_urgent == '1'){
					this.setState({is_urgent : 0});
				}
				if(start_time < wqcheck_date && is_urgent == '0'){
					this.setState({is_urgent : 1});
				}
				setTimeout(() => {
					this.calculatePrice();
					this.getLocationSuggetion();
				},150);
			}
		}
	}
	
	backToMsg(){
		$('.message-box').show();
		$('.message-details-box').hide();
	}
	
	showPopup(e) {
		e.preventDefault();
		var userDetail = JSON.parse(localStorage.getItem("loggedInUser"));
		var wqpstatus = e.target.getAttribute('data-status');
		var wqjobid = e.target.getAttribute('data-id');
		var wqworker = e.target.getAttribute('data-worker');
		if(typeof userDetail !== 'undefined' && userDetail != null) {
			var wqgiver = userDetail.id_user;
		} else {
			var wqgiver = e.target.getAttribute('data-giver');
		}

		this.setState({ 'wqpstatus': wqpstatus });
		this.setState({ 'wqjobid': wqjobid });
		this.setState({ 'wqworker': wqworker });
		this.setState({ 'wqgiver': wqgiver });

		$('.custom-pop-wrap').fadeIn();	
		$('.custom-pop').show();
	}
	
	feedbackSubmit(e) {
		const fd = new FormData();
		fd.append('id_job', this.state.wqjobid);
		fd.append('action', this.state.wqpstatus);
		fd.append('id_worker', this.state.wqworker);
		fd.append('id_giver', this.state.wqgiver);
		fd.append('feedback', this.state.wqfeedback);
		fd.append('current_language', this.state.current_language);
		this.setState({ payment_status: 1 });

		$.ajax({
			url: window.$apiBaseUrl+'job-log/create.php',
			type: 'POST',
			data: fd,
			dataType: 'json',
			cache: false,
			contentType: false,
			processData:false,
			success: function(data) {
				$('.custom-pop-wrap').fadeOut();	
				$('.custom-pop').hide();
				this.lessonUpdateFunc();
				{/* this.setState({'wqjobid':''});
				this.setState({'wqpstatus':''});
				this.setState({'wqworker':''});
				this.setState({'wqgiver':''});
				this.setState({'wqfeedback':''});
				$('#wqfeedback').val('');
				this.setState({close_button: ''});
				this.setState({update_button: ''});
				if(this.state.wqpstatus == 'passed'){
					window.location.href='#/teacherlesson';
				} */}
				
			}.bind(this),
			error: function(xhr, status, err) {
				console.log(err);
			}
		});
	}
	
	getLessonChat(e){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
		let lession_id = this.props.match.params.id;
		var userDetail = localStorage.getItem("loggedInUser");
		var user_obj = JSON.parse(userDetail);
		var current_user_id = user_obj.id_user;
		var current_user_email = user_obj.email;
		var current_user_type = user_obj.type;
		const fd = new FormData();
		fd.append('user_id', current_user_id);
		fd.append('job_id', lession_id);
		$.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: window.$apiBaseUrl+"email/read_messages.php",
            data: fd,
            processData: false,
            contentType: false,
            cache: false,
            success: function (data) {
				var chat_length = data.records.length;
				var receiver_id = '';
				
				var job_log_latest_status = this.state.job_log_latest_status;
				if(current_user_type == 'teacher'){
					receiver_id = this.state.id_giver;
				}else{
					receiver_id = this.state.id_worker;
				}
				let total_chatbody = [];
				//console.log(job_log_latest_status);
				if(job_log_latest_status!='pool'){
					total_chatbody.push(
							<div className="form-group" style={{display: this.state.write_msg_box==0 ? 'none' : 'block' }}>
								<label>{language_arr.reply}</label>
								<textarea name="message" className="form-control" id="msg_by_user" rows="3" onChange={this.change}>{this.state.message}</textarea>			
								<button type="submit" className="btn btn_message_send" sender_id={current_user_id} receiver_id={receiver_id} onClick={this.sendMessage}>{language_arr.send}</button>
								<span className="result_message">{this.state.msgSuccess}</span>
							</div>
					);
				}
				var message_menu = [];
				if(chat_length > 0){
					message_menu.push(<a className="nav-item nav-link" id="nav-Messages-tab" data-trigger="nav-Messages"  href="javascript:void(0)" role="tab" onClick={this.loadMessages}>{language_arr.messages}</a>);
					for(var i=0; i<chat_length; i++){
						var single_chat = data.records[i];
						//console.log(single_chat);
						var id_email = single_chat.id_email;
						var msg_sender_id = single_chat.id_sender;
						var message = single_chat.message;
						var datetime = single_chat.datetime;
						var sender_email = single_chat.sender_email;
						var receiver_email = single_chat.receiver_email;
						var color_cls = ""
						{ /* var shown_email = '';
						if(current_user_email == sender_email){
							shown_email = receiver_email;
						}else{
							shown_email = sender_email;
						} */ }
						//console.log(msg_sender_id+"==========="+current_user_id)
						if(msg_sender_id == current_user_id){
							color_cls = "message-content current_user_msg";
						}else{
							color_cls = "message-content other_user_msg";
						}
						
						total_chatbody.push(<div className={color_cls}>
                              <div className="row">
                                  <div className="col-md-2">
                                    <label className="message-name">{sender_email}</label>
                                  </div>
                                  <div className="col-md-7">
                                    <label className="message-data"><p>{message}</p></label>
                                  </div>
                                  <div className="col-md-2">													
                                    <label className="message-data"><p><Moment format="DD.MM.YYYY HH:mm">{datetime}</Moment></p></label>
                                  </div>
                                  { /* <div className="col-md-1">
                                    <a href="javascript:void(0);" className="show_dtls"><i className="fa fa-eye" aria-hidden="true" chat_id={id_email} onClick={this.showSingleChat}></i></a>									
                                  </div> */ }
                              </div>
                            </div>);
					}
					
				}
				this.setState({role_message_menu : message_menu});
				this.setState({all_chats : total_chatbody});
            }.bind(this),
            error: function (e) {
                console.log("ERROR : ", e);
            }
        }); 
	}
	
	showSingleChat(e){
		{ /* var single_chat_id = e.target.getAttribute('chat_id');
		var userDetail = localStorage.getItem("loggedInUser");
		var user_obj = JSON.parse(userDetail);
		var current_user_id = user_obj.id_user;
		const fd = new FormData();
		fd.append('emailid', single_chat_id);
		var single_chat = [];
		$.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: "https://mise1.area-test.ch/miseapi/email/read.php",
            data: fd,
            processData: false,
            contentType: false,
            cache: false,
            success: function (data) {
				var single_chat_obj = data.records[0];
				var id_email = single_chat_obj.id_email;
				var id_sender = single_chat_obj.id_sender;
				var id_receiver = single_chat_obj.id_receiver;
				var message = single_chat_obj.message;
				var datetime = single_chat_obj.datetime;
				var sender_email = single_chat_obj.sender_email;
				var receiver_email = single_chat_obj.receiver_email;
				var chat_receiver='';
				if(current_user_id == id_sender){
					chat_receiver = id_receiver;
				}else{
					chat_receiver = id_sender;
				}
				single_chat.push(<div className="row">
								  <div className="col-md-12 text-left">
									<div className="btnback">
									  <i className="fa fa-chevron-left" aria-hidden="true"  onClick={this.backToMsg}></i>
									</div>
								  </div>
								  <div className="col-md-6 text-left">
									<label className="message-name">Sender :</label>
									<label className="message-data"><p>{sender_email}</p></label>
								  </div>
								  <div className="col-md-6 text-right">
									<label className="message-name">Receiver :</label>
									<label className="message-data"><p>{receiver_email}</p></label>
								  </div>
								  <div className="col-md-12 text-left">
									<label className="message-name">Date and Time :</label>
									<label className="message-data"><p><Moment format="DD/MM/YYYY HH:mma">{datetime}</Moment></p></label>
								  </div>
								  <div className="col-md-12">
									<label className="message-data"><p>{message}</p></label>
								  </div>
								  <div className="col-md-12">
									
									  <div className="form-group">
										<label>Reply</label>
										<textarea name="message" className="form-control" rows="3" onChange={this.change}>{this.state.message}</textarea>
										</div>
										<div className="form-group">
									  <button type="submit" sender_id={current_user_id} receiver_id={chat_receiver} onClick={this.sendMessage} className="btn btn_message_send">Send</button>
									  </div>
																				
								  </div>
								  </div>);
				this.setState({single_chat_body : single_chat});
				$('.message-box').hide();
				$('.message-details-box').show();
            }.bind(this),
            error: function (e) {
                console.log("ERROR : ", e);
            }
		});  */}
	} 
	
	lessonUpdate(e){
		e.preventDefault();
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
		var job_status = this.state.job_log_latest_status;
		var job_start_dt = this.state.jobStartDate;
		var job_end_dt = this.state.jobEndDate;
		var current_user_type = this.state.current_user_type;
		var payment_status = this.state.payment_status;
		var current_timestamp = new Date().getTime();
		if(job_status == 'pending' && current_user_type =='teacher'){
			if(job_start_dt =='' || job_end_dt==''){
				alert(language_arr.start_end_blank);
				return false;
			}else if(job_start_dt <= current_timestamp){
				alert(language_arr.start_less_current);
				return false;
			}else if(job_end_dt <= job_start_dt){
				alert(language_arr.end_less_start);
				return false;
			}else{
				this.lessonUpdateFunc();
			}
		}else if(job_status == 'approved' && payment_status =='0' && current_user_type =='teacher'){
			if(job_start_dt =='' || job_end_dt==''){
				alert(language_arr.start_end_blank);
				return false;
			}else if(job_start_dt <= current_timestamp){
				alert(language_arr.start_less_current);
				return false;
			}else if(job_end_dt <= job_start_dt){
				alert(language_arr.end_less_start);
				return false;
			}else{
				this.lessonUpdateFunc();
			}
		}else{
			this.lessonUpdateFunc();
		}
	}
	
	backToLessonList(){
		var current_user_type = this.state.current_user_type;
		if(current_user_type =='teacher'){
			window.location.href='#/teacherlesson';
		}
		if(current_user_type =='student'){
			window.location.href='#/studentlesson';
		}
	}
	
	lessonUpdateFunc(){
		var current_user_type = this.state.current_user_type;
		const fd = new FormData();
        fd.append('id_job', this.state.job_id);
        fd.append('title', this.state.job_title);
        fd.append('description', this.state.job_description);
        fd.append('proposal_start', this.state.proposal_start);
        fd.append('proposal_end', this.state.proposal_end);
        fd.append('proposal_fascia', this.state.proposal_fascia);
        fd.append('proposal_mandatory', this.state.proposal_mandatory);
        fd.append('start',  this.convert(this.state.jobStartDate));
        fd.append('end',  this.convert(this.state.jobEndDate));
        fd.append('creator_user_type', this.state.creator_user_type);
        fd.append('tel', this.state.tel);
        fd.append('nr_person', this.state.nr_person);
        fd.append('payment_status', this.state.payment_status);
        fd.append('id_region', this.state.id_region);
        fd.append('location', this.state.location);
        fd.append('is_urgent', this.state.is_urgent);
        fd.append('price', this.state.actual_price);
        fd.append('cost', this.state.cost);
        fd.append('creation_time', this.state.creation_time);
        fd.append('id_service', this.state.id_service);
        fd.append('id_sector', this.state.id_sector);
        fd.append('id_subsector', this.state.id_subsector);
        fd.append('id_creator', this.state.id_creator);
        fd.append('id_giver', this.state.id_giver);
        fd.append('id_worker', this.state.id_worker);
        fd.append('id_admin', this.state.id_admin);
        fd.append('current_language', this.state.current_language);
		//console.log(this.state);
		$.ajax({
            type: "POST",
            //enctype: 'multipart/form-data',
            url: window.$apiBaseUrl+"job/update.php",
            data: fd,
            processData: false,
            contentType: false,
            cache: false,
            success: function (data) {
				//console.log(data);
				//alert(data.message);
				if(current_user_type =='teacher'){
					window.location.href='#/teacherlesson';
				}
				if(current_user_type =='student'){
					window.location.href='#/studentlesson';
				}
				
            }.bind(this),
            error: function (e) {
                console.log("ERROR : ", e);
            }
		});
	}
	
	convert(str) {
		if(str != ''){
			var date = new Date(str),
				mnth = ("0" + (date.getMonth()+1)).slice(-2),
				day  = ("0" + date.getDate()).slice(-2);
			var currentHours = ("0" + date.getHours()).slice(-2),
				currentMinute= ("0" + date.getMinutes()).slice(-2),
				currentSecond= ("0" + date.getSeconds()).slice(-2);
			var full_date = [ date.getFullYear() , mnth,  day].join("-");
			var full_time = [ currentHours , currentMinute,  currentSecond ].join(":");
			return [full_date, full_time].join(" ");
		}else{
			return '0000-00-00 00:00:00';
		}
	}
	
	convert_for_location(str) {
		if(str != ''){
			var date = new Date(str),
				mnth = ("0" + (date.getMonth()+1)).slice(-2),
				day  = ("0" + date.getDate()).slice(-2);
			var currentHours = ("0" + date.getHours()).slice(-2),
				currentMinute= ("0" + date.getMinutes()).slice(-2),
				currentSecond= ("0" + date.getSeconds()).slice(-2);
			var full_date = [ day , mnth,  date.getFullYear()].join(".");
			var full_time = [ currentHours , currentMinute].join(":");
			return [full_date, full_time].join(" ");
		}
	}
	
	change(e) {
		this.setState({[e.target.name]: e.target.value});
		if(e.target.name == 'nr_person'){
			setTimeout(() => {
				this.calculatePrice();
			},100);
					
		}
		if(e.target.name == 'location'){
			setTimeout(() => {
				this.getLocationSuggetion();
			},800);
					
		}
		//console.log(e.target.name);
	}
	
	focus(e) {
		var pencil_position = e.target.getAttribute('name');
		if(pencil_position=="location_pencil"){
			$('.location').focus();
		}
		if(pencil_position=="location_person"){
			$('.nr_person').focus();
		}
		if(pencil_position=="description_pencil"){
			$('.job_description').focus();
		}
		if(pencil_position=="telephone_pencil"){
			$('.lesson-telephone').focus();
		}
	}
	
	calculatePrice() {
		var wqdiscount = '';
		var wqstudent = this.state.nr_person;
        var wqprice = 0;
        var wqduration = 0;
		if(wqstudent > 1) {
			wqdiscount = (wqstudent * this.state.wqsettings_discount);
		} else {
			wqdiscount = 0;
		}
		var start_obj = this.state.jobStartDate;
		var end_obj = this.state.jobEndDate;
		var time_diff = end_obj-start_obj;
		var wqend_duration = time_diff/60000;
		var is_urgent = this.state.is_urgent
        wqduration = parseFloat(parseFloat(wqend_duration) / 60);
		if(wqduration!=0){
			if(is_urgent == '1'){
				wqprice = (((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour) * parseFloat(wqduration)) + parseFloat(this.state.wqurgent_cost)) - (parseFloat(wqdiscount) * parseFloat(wqduration)));
			}else{
				wqprice = ((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour) * parseFloat(wqduration)) - (parseFloat(wqdiscount) * parseFloat(wqduration)));
			}
			var wq_org_price = ((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour) * parseFloat(wqduration)) - (parseFloat(wqdiscount) * parseFloat(wqduration)));
		}else{
			if(is_urgent == '1'){
				wqprice = (((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour)) + parseFloat(this.state.wqurgent_cost)) - parseFloat(wqdiscount));
			}else{
				wqprice = ((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour)) - parseFloat(wqdiscount));
			}
			var wq_org_price = ((parseFloat(wqstudent) * parseFloat(this.state.wqprice_per_hour)) - parseFloat(wqdiscount));
		}
		console.log('price='+wqprice+', originalPrice='+wq_org_price+'');
		this.setState({'price': wqprice});
		this.setState({'actual_price': wq_org_price});
    }
	
	changeStatus(e) {
		e.preventDefault();
		var current_user_type = this.state.current_user_type;
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
		var wqpstatus = e.target.getAttribute('data-status');
		var wqjobid = e.target.getAttribute('data-id');
		var wqworker = e.target.getAttribute('data-worker');
		var wqgiver = e.target.getAttribute('data-giver');
		var wqadmin = e.target.getAttribute('data-admin');
		//alert(wqjobid+"=="+wqpstatus+"=="+wqworker+"=="+wqgiver+"=="+wqadmin+"=="+wqadmin+"=="+this.state.job_title+"=="+this.state.job_description+"=="+this.state.nr_person+"=="+this.state.price+"=="+this.state.tel+"=="+this.state.job_sector+"=="+this.state.job_subsector+"=="+this.state.job_service+"=="+this.state.location);
		const fd = new FormData();
		fd.append('id_job', wqjobid);
		fd.append('action', wqpstatus);
		fd.append('id_worker', wqworker);
		fd.append('id_giver', wqgiver);
		fd.append('id_admin', wqadmin);
		fd.append('title', this.state.job_title);
		fd.append('description', this.state.job_description);
		fd.append('nr_person', this.state.nr_person);
		fd.append('price', this.state.actual_price);
		fd.append('telephone', this.state.tel);
		fd.append('job_sector', this.state.job_sector);
		fd.append('job_subsector', this.state.job_subsector);
		fd.append('job_service', this.state.job_service);
		fd.append('location', this.state.location);
		fd.append('job_start_date', '0000-00-00 00:00:00');
		fd.append('job_end_date', '0000-00-00 00:00:00');
		fd.append('current_language', this.state.current_language);
		$.ajax({
			url: window.$apiBaseUrl+'job-log/create.php',
			type: 'POST',
			data: fd,
			dataType: 'json',
			cache: false,
			contentType: false,
			processData:false,
			success: function(data) {
				//console.log(data);
				if(wqpstatus=='pending'){
					alert(language_arr.successfully_assign);
					this.setState({assign_button: ''});
				}
				if(wqpstatus=='approved'){
					this.setState({approve_button: ''});
				}
				if(wqpstatus=='deleted'){
					this.setState({delete_button: ''});
					this.setState({update_button: ''});
				}
				{/* if(wqpstatus=='pool'){
					window.location.href='#/teacherlesson';
				} */}
				if(current_user_type =='teacher' && wqpstatus=='pending'){
					window.location.href='#/teacherlesson';
				}
				if(current_user_type =='teacher' && wqpstatus=='pool'){
					window.location.href='#/teacherlesson';
				}
				if(current_user_type =='student' && wqpstatus=='deleted'){
					window.location.href='#/studentlesson';
				}
			}.bind(this),
			error: function(xhr, status, err) {
				console.log(err);
			}
		});
	}
	
	changeStatusToApprove(e){
		e.preventDefault();
		var current_user_type = this.state.current_user_type;
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
		var job_start_dt = this.state.jobStartDate;
		var job_end_dt = this.state.jobEndDate;
		var current_timestamp = new Date().getTime();
		//alert(this.state.wqcheck_date);
		if(job_start_dt =='' || job_end_dt==''){
			alert(language_arr.start_end_blank);
			return false;
		}else if(job_start_dt <= current_timestamp){
			alert(language_arr.start_less_current);
			return false;
		}else if(job_end_dt <= job_start_dt){
			alert(language_arr.end_less_start);
			return false;
		}
		
		var wqpstatus = e.target.getAttribute('data-status');
		var wqjobid = e.target.getAttribute('data-id');
		var wqworker = e.target.getAttribute('data-worker');
		var wqgiver = e.target.getAttribute('data-giver');
		var wqadmin = e.target.getAttribute('data-admin');
		const fd = new FormData();
		fd.append('id_job', wqjobid);
		fd.append('action', wqpstatus);
		fd.append('id_worker', wqworker);
		fd.append('id_giver', wqgiver);
		fd.append('id_admin', wqadmin);
		fd.append('title', this.state.job_title);
		fd.append('description', this.state.job_description);
		fd.append('nr_person', this.state.nr_person);
		fd.append('price', this.state.actual_price);
		fd.append('telephone', this.state.tel);
		fd.append('job_sector', this.state.job_sector);
		fd.append('job_subsector', this.state.job_subsector);
		fd.append('job_service', this.state.job_service);
		fd.append('location', this.state.location);
		fd.append('job_start_date', this.convert(job_start_dt));
		fd.append('job_end_date', this.convert(job_end_dt));
		fd.append('current_language', this.state.current_language);
		//alert(wqjobid+"=="+wqpstatus+"=="+wqworker+"=="+wqgiver+"=="+wqadmin+"=="+this.state.job_title+"=="+this.state.job_description+"=="+this.state.nr_person+"=="+this.state.actual_price+"=="+this.state.tel+"=="+this.state.job_sector+"=="+this.state.job_subsector+"=="+this.state.job_service+"=="+this.state.location+"=="+this.convert(job_start_dt)+"=="+this.convert(job_end_dt));
		
		$.ajax({
			url: window.$apiBaseUrl+'job-log/create.php',
			type: 'POST',
			data: fd,
			dataType: 'json',
			cache: false,
			contentType: false,
			processData:false,
			success: function(data) {
				
				if(wqpstatus=='pending'){
					this.setState({assign_button: ''});
				}
				if(wqpstatus=='approved'){
					this.setState({approve_button: ''});
					//alert(language_arr.successfully_aprove);
					if(current_user_type =='teacher'){
						window.location.href='#/teacherlesson';
					}
					if(current_user_type =='student'){
						window.location.href='#/studentlesson';
					}
					
				}
			}.bind(this),
			error: function(xhr, status, err) {
				console.log(err);
			}
		});
	}
	
	getSingleLesson(e){
		var language_obj = localStorage.getItem("language_arr");
		var language_arr = JSON.parse(language_obj);
		var userDetail = localStorage.getItem("loggedInUser");
		if(typeof userDetail !== 'undefined' && userDetail != null) {
			var user_obj = JSON.parse(userDetail);
			var current_user_id = user_obj.id_user;
			var current_user_type = user_obj.type;
			let lession_id = this.props.match.params.id;
			this.setState({current_user_type: current_user_type});
			const fd = new FormData();
			fd.append('jobid', lession_id);
			$.ajax({
			url: window.$apiBaseUrl+'job/read.php',
			type: 'POST',
			dataType: 'json',
			data: fd,
			cache: false,
			contentType: false,
			processData:false,
			success: function(data) {
				var response = data.records[0];
				//console.log(response);
				this.setState({job_id : data.records[0].id});
				this.setState({job_title : data.records[0].title});
				this.setState({job_description : data.records[0].description});
				this.setState({proposal_start : data.records[0].proposal_start});
				this.setState({proposal_end : data.records[0].proposal_end});
				this.setState({proposal_fascia : data.records[0].proposal_fascia});
				this.setState({proposal_mandatory : data.records[0].proposal_mandatory});
				this.setState({start : data.records[0].start});
				this.setState({end : data.records[0].end});
				this.setState({creator_user_type : data.records[0].creator_user_type});
				this.setState({tel : data.records[0].tel});
				this.setState({nr_person : data.records[0].nr_person});
				this.setState({payment_status : data.records[0].payment_status});
				this.setState({id_region : data.records[0].id_region});
				this.setState({location : data.records[0].location});
				this.setState({is_urgent : data.records[0].is_urgent});
				this.setState({final_urgent_price : data.records[0].final_urgent_price});
				//this.setState({price : data.records[0].price});
				this.setState({cost : data.records[0].cost});
				this.setState({creation_time : data.records[0].creation_time});
				this.setState({id_service : data.records[0].id_service});
				this.setState({id_sector : data.records[0].id_sector});
				this.setState({id_subsector : data.records[0].id_subsector});
				this.setState({id_creator : data.records[0].id_creator});
				this.setState({student_family_name : data.records[0].student_family_name});
				this.setState({worker_family_name : data.records[0].worker_family_name});
				this.setState({user_email : data.records[0].user_email});
				this.setState({worker_email : data.records[0].worker_email});
				this.setState({id_giver : data.records[0].id_giver});
				this.setState({id_worker : data.records[0].id_worker});
				this.setState({id_admin : data.records[0].id_admin});
				this.setState({feedback : data.records[0].feedback});
				this.setState({offer_id : data.records[0].offer_id});
				this.setState({wq_file_gallery : data.records[0].wq_file_gallery});
				this.setState({job_user_creator : data.records[0].job_user_creator});
				this.setState({job_user_giver : data.records[0].job_user_giver});
				this.setState({job_user_worker : data.records[0].job_user_worker});
				this.setState({job_admin : data.records[0].job_admin});
				this.setState({job_region : data.records[0].job_region});
				this.setState({job_service : data.records[0].job_service});
				this.setState({job_sector : data.records[0].job_sector});
				this.setState({job_subsector : data.records[0].job_subsector});
				this.setState({job_log_latest_status : data.records[0].job_log_latest_status});
				//var date_obj = new Date(data.records[0].proposal_start).getTime()+24*60*60*1000;
				//var mydate = new Date(data.records[0].proposal_start).getTime();
				if(data.records[0].is_urgent == '1'){
					var total_price = parseFloat(data.records[0].price) + parseFloat(data.records[0].final_urgent_price);
				}else{
					var total_price = data.records[0].price;
				}
				this.setState({price : total_price});
				this.setState({actual_price : data.records[0].price});
				var mydate = new Date().getTime();
				this.setState({minDatecount: mydate});
				//console.log(data.records[0].start);
				if(data.records[0].start == '0000-00-00 00:00:00'){
					this.setState({jobStartDate : this.state.jobStartDate});
				}else{
					this.setState({jobStartDate : new Date(data.records[0].start).getTime()});
				}
				if(data.records[0].end == '0000-00-00 00:00:00' || data.records[0].end == ''){
					this.setState({jobEndDate : this.state.jobStartDate});
				}else{
					this.setState({jobEndDate : new Date(data.records[0].end).getTime()});
				} 
				var feedback_menu = [];
				if(current_user_type == 'teacher' && data.records[0].job_log_latest_status == 'approved'){
					feedback_menu.push(<a className="nav-item nav-link" id="nav-Feedback-tab" data-trigger="nav-Feedback"  href="javascript:void(0)" role="tab">{language_arr.feedback}</a>);
					this.setState({role_feedback_menu : feedback_menu});
				}
				{ /* if(current_user_type == 'student' && data.records[0].job_log_latest_status == 'approved'){
					feedback_menu.push(<a className="nav-item nav-link" id="nav-Feedback-tab" data-trigger="nav-Feedback"  href="javascript:void(0)" role="tab">{language_arr.feedback}</a>);
					this.setState({role_feedback_menu : feedback_menu});
				} */ }
				
				if(data.records[0].job_log_latest_status == 'pool' && current_user_type == 'student'){
					this.setState({show_pencil_icon_for_desc : <i className="fa fa-pencil" aria-hidden="true" name="description_pencil" onClick={this.focus}></i>});
					this.setState({show_pencil_icon_for_nr_person : <i className="fa fa-pencil" aria-hidden="true" name="location_person" onClick={this.focus}></i>});
					this.setState({show_pencil_icon_for_location : <i className="fa fa-pencil" aria-hidden="true" name="location_pencil" onClick={this.focus}></i>});
					this.setState({show_pencil_icon_for_telephone : <i className="fa fa-pencil" aria-hidden="true" name="telephone_pencil" onClick={this.focus}></i>});
					this.setState({desc_box_readonly : ''});
					this.setState({location_box_readonly : ''});
					this.setState({telephone_box_readonly : ''});
					this.setState({nr_dropdown_disabled : ''});
					this.setState({delete_button : <button className="btn btn_dtlsupdate btn_assign" data-admin={data.records[0].id_admin} data-worker={data.records[0].id_worker} data-giver={data.records[0].id_giver} data-id={data.records[0].id} data-status="deleted" onClick={(e) => { if (window.confirm(language_arr.delete_confirm)) this.changeStatus(e) } }>{language_arr.delete}</button>});
					this.setState({update_button : <button type="submit" className="btn btn_dtlsupdate" onClick= {this.lessonUpdate}>{language_arr.update}</button>});
					this.setState({extra_teacher_status : 0});
					this.setState({extra_student_status : 1});
				}else if(data.records[0].job_log_latest_status == 'pending' && current_user_type == 'student'){
					this.setState({show_pencil_icon_for_desc : <i className="fa fa-pencil" aria-hidden="true" name="description_pencil" onClick={this.focus}></i>});
					this.setState({show_pencil_icon_for_nr_person : <i className="fa fa-pencil" aria-hidden="true" name="location_person" onClick={this.focus}></i>});
					this.setState({show_pencil_icon_for_location : <i className="fa fa-pencil" aria-hidden="true"  name="location_pencil" onClick={this.focus}></i>});
					this.setState({show_pencil_icon_for_telephone : <i className="fa fa-pencil" aria-hidden="true" name="telephone_pencil" onClick={this.focus}></i>});
					this.setState({desc_box_readonly : ''});
					this.setState({location_box_readonly : ''});
					this.setState({telephone_box_readonly : ''});
					this.setState({nr_dropdown_disabled : ''});
					this.setState({update_button : <button type="submit" className="btn btn_dtlsupdate" onClick= {this.lessonUpdate}>{language_arr.update}</button>});
					this.setState({extra_teacher_status : 1});
					this.setState({extra_student_status : 1});
				}else if(data.records[0].job_log_latest_status == 'approved' && current_user_type == 'student'){
					var myDateTime = new Date().getTime();
					var jobDateTime = new Date(data.records[0].start).getTime();
					if(jobDateTime < myDateTime) {
						this.setState({desc_box_readonly : 'readOnly'});
						this.setState({location_box_readonly : 'readOnly'});
						this.setState({telephone_box_readonly : 'readOnly'});
						this.setState({nr_dropdown_disabled : 'disabled'});
					}else{
						this.setState({desc_box_readonly : 'readOnly'});
						this.setState({location_box_readonly : 'readOnly'});
						this.setState({telephone_box_readonly : 'readOnly'});
						this.setState({nr_dropdown_disabled : 'disabled'});
					}
					this.setState({extra_teacher_status : 1});
					this.setState({extra_student_status : 1});
				}else if(data.records[0].job_log_latest_status == 'deleted' && current_user_type == 'student'){
					this.setState({desc_box_readonly : 'readOnly'});
					this.setState({location_box_readonly : 'readOnly'});
					this.setState({telephone_box_readonly : 'readOnly'});
					this.setState({nr_dropdown_disabled : 'disabled'});
					this.setState({extra_teacher_status : 0});
					this.setState({extra_student_status : 1});
				}else if(data.records[0].job_log_latest_status == 'pool' && current_user_type == 'teacher'){
					this.setState({desc_box_readonly : 'readOnly'});
					this.setState({location_box_readonly : 'readOnly'});
					this.setState({telephone_box_readonly : 'readOnly'});
					this.setState({nr_dropdown_disabled : 'disabled'});
					this.setState({assign_button : <button className="btn btn_dtlsupdate btn_assign" data-admin={data.records[0].id_admin} data-worker={current_user_id} data-giver={data.records[0].id_giver} data-id={data.records[0].id} data-status="pending" onClick={(e) => { if (window.confirm(language_arr.assign_confirm)) this.changeStatus(e) } }>{language_arr.assign}</button>});
					this.setState({extra_teacher_status : 0});
					this.setState({extra_student_status : 1});
				}else if(data.records[0].job_log_latest_status == 'pending' && current_user_type == 'teacher'){
					this.setState({show_pencil_icon_for_nr_person : <i className="fa fa-pencil" aria-hidden="true" name="location_person" onClick={this.focus}></i>});
					this.setState({show_pencil_icon_for_location : <i className="fa fa-pencil" aria-hidden="true" name="location_pencil" onClick={this.focus}></i>});
					this.setState({desc_box_readonly : 'readOnly'});
					this.setState({telephone_box_readonly : 'readOnly'});
					this.setState({location_box_readonly : ''});
					this.setState({nr_dropdown_disabled : ''});
					this.setState({datepickerDisabled : ''});
					this.setState({back_to_pool_button : <button type="button" className="btn btn_dtlsupdate" data-admin={data.records[0].id_admin} data-worker={data.records[0].id_worker} data-giver={data.records[0].id_giver} data-id={data.records[0].id} data-status="pool" onClick={(e) => { if (window.confirm(language_arr.pool_confirm)) this.changeStatus(e) } }>BACK TO POOL</button>});
					this.setState({update_button : <button type="submit" className="btn btn_dtlsupdate" onClick= {this.lessonUpdate}>{language_arr.update}</button>});
					this.setState({approve_button : <button className="btn btn_dtlsupdate btn_assign"  data-admin={data.records[0].id_admin} data-worker={data.records[0].id_worker} data-giver={data.records[0].id_giver} data-id={data.records[0].id} data-status="approved" onClick={(e) => { if (window.confirm(language_arr.approve_confirm)) this.changeStatusToApprove(e) } }>{language_arr.approve}</button>});
					if(current_user_id == data.records[0].id_worker){
						this.setState({extra_teacher_status : 1});
						this.setState({extra_student_status : 1});
					}else{
						this.setState({extra_teacher_status : 0});
						this.setState({extra_student_status : 0});
					}
				}else if(data.records[0].job_log_latest_status == 'approved' && current_user_type == 'teacher'){
					var myDateTime = new Date().getTime();
					var jobDateTime = new Date(data.records[0].start).getTime();
					//console.log(myDateTime+'=='+jobDateTime);
					if(current_user_id == data.records[0].id_worker){
						this.setState({extra_teacher_status : 1});
						this.setState({extra_student_status : 1});
					}else{
						this.setState({extra_teacher_status : 0});
						this.setState({extra_student_status : 0});
					}
					if(jobDateTime < myDateTime) {
						this.setState({write_msg_box : 0});
						if(data.records[0].payment_status == '0'){
							this.setState({show_pencil_icon_for_nr_person : <i className="fa fa-pencil" aria-hidden="true" name="location_person" onClick={this.focus}></i>});
							this.setState({show_pencil_icon_for_location : <i className="fa fa-pencil" aria-hidden="true"  name="location_pencil" onClick={this.focus}></i>});
							this.setState({desc_box_readonly : 'readOnly'});
							this.setState({telephone_box_readonly : 'readOnly'});
							this.setState({location_box_readonly : ''});
							this.setState({nr_dropdown_disabled : ''});
							this.setState({datepickerDisabled : ''});
							this.setState({close_button : <button className="btn btn_dtlsupdate btn_assign" data-admin={data.records[0].id_admin} data-worker={data.records[0].id_worker} data-giver={data.records[0].id_giver} data-id={data.records[0].id} data-status="passed" onClick={(e) => { if (window.confirm(language_arr.close_confirm)) this.showPopup(e) } }>{language_arr.close}</button>});
							{ /* this.setState({update_button : <button type="submit" className="btn btn_dtlsupdate" onClick= {this.lessonUpdate}>{language_arr.update}</button>}); */ }
						}
						if(data.records[0].payment_status == '1'){
							this.setState({desc_box_readonly : 'readOnly'});
							this.setState({telephone_box_readonly : 'readOnly'});
							this.setState({location_box_readonly : 'readOnly'});
							this.setState({nr_dropdown_disabled : 'disabled'});
						}
					}else{
						this.setState({show_pencil_icon_for_nr_person : <i className="fa fa-pencil" aria-hidden="true" name="location_person" onClick={this.focus}></i>});
						this.setState({show_pencil_icon_for_location : <i className="fa fa-pencil" aria-hidden="true"  name="location_pencil" onClick={this.focus}></i>});
						this.setState({desc_box_readonly : 'readOnly'});
						this.setState({telephone_box_readonly : 'readOnly'});
						this.setState({location_box_readonly : ''});
						this.setState({nr_dropdown_disabled : ''});
						this.setState({datepickerDisabled : ''});
						this.setState({update_button : <button type="submit" className="btn btn_dtlsupdate" onClick= {this.lessonUpdate}>{language_arr.update}</button>});
					}
					
				}else if(data.records[0].job_log_latest_status == 'passed' && current_user_type == 'teacher'){
					if(data.records[0].payment_status == '0'){
						this.setState({show_pencil_icon_for_nr_person : <i className="fa fa-pencil" aria-hidden="true" name="location_person" onClick={this.focus}></i>});
						this.setState({show_pencil_icon_for_location : <i className="fa fa-pencil" aria-hidden="true"  name="location_pencil" onClick={this.focus}></i>});
						this.setState({desc_box_readonly : 'readOnly'});
						this.setState({telephone_box_readonly : 'readOnly'});
						this.setState({location_box_readonly : ''});
						this.setState({nr_dropdown_disabled : ''});
						this.setState({datepickerDisabled : ''});
						this.setState({close_button : <button className="btn btn_dtlsupdate btn_assign" data-admin={data.records[0].id_admin} data-worker={data.records[0].id_worker} data-giver={data.records[0].id_giver} data-id={data.records[0].id} data-status="passed" onClick={(e) => { if (window.confirm(language_arr.close_confirm)) this.showPopup(e) } }>{language_arr.close}</button>});
						{/*this.setState({update_button : <button type="submit" className="btn btn_dtlsupdate" onClick= {this.lessonUpdate}>{language_arr.update}</button>});*/}
					}
					if(data.records[0].payment_status == '1'){
						this.setState({desc_box_readonly : 'readOnly'});
						this.setState({telephone_box_readonly : 'readOnly'});
						this.setState({location_box_readonly : 'readOnly'});
						this.setState({nr_dropdown_disabled : 'disabled'});
					}
				}else if(data.records[0].job_log_latest_status == 'deleted' && current_user_type == 'teacher'){
					this.setState({desc_box_readonly : 'readOnly'});
					this.setState({location_box_readonly : 'readOnly'});
					this.setState({telephone_box_readonly : 'readOnly'});
					this.setState({nr_dropdown_disabled : 'disabled'});
					
					this.setState({extra_teacher_status : 0});
					this.setState({extra_student_status : 0});
					
				}
				
				//console.log(data.records[0]);
			}.bind(this),
			error: function(xhr, status, err) {
				console.log(err);
			}
			});
		}else{
			window.location.href='#/';
		}
	}
	
	sendMessage(e){
		//e.preventdefault();	
		var messageValid = true;
		var sender_id = e.target.getAttribute('sender_id');
		var receiver_id = e.target.getAttribute('receiver_id');
		var offer_id = this.state.offer_id;
		var message = this.state.message;
		if(message == ''){
			messageValid = false;
			$("#msg_by_user").css('border','2px solid red');
		}else {
            $("#msg_by_user").css('border','1px solid #ced4da');
        }
		if(messageValid){
			const fd = new FormData();
			fd.append('senderid', sender_id);
			fd.append('receiverid', receiver_id);
			fd.append('message', message);
			fd.append('offerid', offer_id);
			fd.append('current_language', this.state.current_language);
			$.ajax({
				type: "POST",
				enctype: 'multipart/form-data',
				url: window.$apiBaseUrl+"email/create.php",
				data: fd,
				processData: false,
				contentType: false,
				cache: false,
				success: function (data) {
					//alert(data.message);
					this.getLessonChat();
					this.setState({message : ''});
					this.setState({msgSuccess : data.message});
					$('#msg_by_user').val('');
					
				}.bind(this),
				error: function (e) {
					console.log("ERROR : ", e);
				}
			}); 
		}
	}
	
  render() {
	  var language_obj = localStorage.getItem("language_arr");
	  var language_arr = JSON.parse(language_obj);
	  let jobnrList = [];
	  for (var i = 1; i <= 25; i++) {
		jobnrList.push(<option value={i} selected={i == this.state.nr_person}>{i}</option>);
	  }
	  //console.log(this.state.jobStartDate);
    return (
	<div>
	<Header />
      <div className="LessonDetails">
        <div className="main">
          <div className="lession-bg">
            <div className="container-fluid">
                <div className="lession-body">
					<div className="backbutton-sec">
						<a href="javascript:void(0);" className="btn btn-back" onClick={this.backToLessonList}>Back</a>
					</div>
                    <div className="row">
                      <div className="col-md-12 text-center lession-title">
                        <h2>{language_arr.lesson_details}</h2>
                      </div>
                    </div>
                    <nav>
                    <div className="nav nav-tabs text-center" id="nav-tab" role="tablist">
                      <a className="nav-item nav-link active" id="nav-Basic-tab" data-trigger="nav-Basic" href="javascript:void(0)" role="tab">{language_arr.lesson_info}</a>
					  {this.state.role_message_menu}
					  {this.state.role_feedback_menu}
					  
                    </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-Basic" role="tabpanel" aria-labelledby="nav-Basic-tab">
					{ /*  <form method="post"> */ }
                      <div className="details-box">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.job_id} : </label>
                            <label className="label-data"><p>{this.state.job_id}</p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.job_title} : </label>
                            <label className="label-data"><p>{this.state.job_title}</p> </label>
                          </div>
                          <div className="col-md-12">
                            <label className="label-name description-label">{language_arr.job_description} : </label>
							<label className="label-data description-data">									
                            <input className="form-control job_description red-drescription " type="text" placeholder={language_arr.job_description} name="job_description" onChange={this.change} value={this.state.job_description} readOnly={this.state.desc_box_readonly}/>
                            </label>
							{this.state.show_pencil_icon_for_desc}
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">								
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.proposal_start} : </label>
                            <label className="label-data"><p><Moment format="DD.MM.YYYY HH:mm">{this.state.proposal_start}</Moment></p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.proposal_end} : </label>
                            <label className="label-data"><p><Moment format="DD.MM.YYYY HH:mm">{this.state.proposal_end}</Moment></p></label>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">		
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.job_start} : </label>
                            <label className="label-data"><p>
							<DatePicker disabled={this.state.datepickerDisabled}
								onChange={this.handleStartChange}
								locale="en-GB"
								//onFocus={this.inputStartChange}
								onBlur={this.inputStartChange}
								showTimeSelect ='showTimeSelect'
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="Time"
								selected ={this.state.jobStartDate}
								//minDate = {this.state.minDatecount}
								minTime={new Date(new Date().setHours(6,0,0))}
								maxTime={new Date(new Date().setHours(22,0,0))}
								dateFormat="dd.MM.yyyy HH:mm"
							/>
							</p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.job_end} : </label>
                            <label className="label-data"><p>
							<DatePicker disabled={this.state.datepickerDisabled}
								onChange={this.handleEndChange}
								locale="en-GB"
								//onFocus={this.inputEndChange}
								onBlur={this.inputEndChange}
								//showTimeSelect
								showTimeSelect= 'showTimeSelect'
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="time"
								selected ={this.state.jobEndDate}
								//minDate = {this.state.minDatecount}
								minTime={new Date(new Date().setHours(6,0,0))}
								maxTime={new Date(new Date().setHours(22,0,0))}
								dateFormat="dd.MM.yyyy HH:mm"
							/>
							</p></label>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.job_nr_person} : </label>
                            <label className="label-data">
                              <select className="form-control nr_person" name="nr_person" onChange={this.change} disabled={this.state.nr_dropdown_disabled ? true : null}>
							  {jobnrList}
                              </select>
                              </label>
                            {this.state.show_pencil_icon_for_nr_person}
                            
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.job_price} : </label>
                            <label className="label-data"><p>CHF {this.state.price} </p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.jobid_region} : </label>
                            <label className="label-data"><p>{this.state.job_region}</p></label>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.job_location} : </label>
                            <label className="label-data">									
                            <input className="form-control location" type="text" placeholder={language_arr.job_location} autofocus name="location" onChange={this.change} value={this.state.location} readOnly={this.state.location_box_readonly}/>
                            </label>
                            {this.state.show_pencil_icon_for_location}
								
								{this.state.all_location_suggesion}
								
                          </div>
						  
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.jobid_service} : </label>
                            <label className="label-data"><p>{this.state.job_service} </p></label>
                          </div>
						 
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.user_name_stu} : </label>
                            <label className="label-data"><p>{this.state.job_user_giver}</p></label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-name">{language_arr.family_name_stu} : </label>
                            <label className="label-data"><p>{this.state.student_family_name}</p></label>
                          </div>			
                         </div>
                        </div>
						<div className="details-box" style={{display: this.state.extra_student_status==0 ? 'none' : 'block' }}>
							<div className="row">
								<div className="col-md-12">
									<label className="label-name drtls-hdng">{language_arr.extra_stu_info} </label>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<label className="label-name">{language_arr.user_email_stu} : </label>
									<label className="label-data"><p>{this.state.user_email} </p></label>
								</div>
								<div className="col-md-6">
									<label className="label-name">{language_arr.job_tel} : </label>
									<label className="label-data">									
									<input className="form-control lesson-telephone" type="text" placeholder={language_arr.enter_phone} name="tel" onChange={this.change} value={this.state.tel} readOnly={this.state.telephone_box_readonly} />
									</label>
									{this.state.show_pencil_icon_for_telephone}								
								</div>
							</div>
						</div>
						<div className="details-box" style={{display: this.state.extra_teacher_status==0 ? 'none' : 'block' }}>
							<div className="row">
								<div className="col-md-12">
									<label className="label-name drtls-hdng">{language_arr.extra_techer_info} </label>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<label className="label-name">{language_arr.user_email_teacher} : </label>
									<label className="label-data"><p>{this.state.worker_email} </p></label>
								</div>
								<div className="col-md-6">
									<label className="label-name">{language_arr.user_name_teacher} : </label>
									<label className="label-data"><p>{this.state.job_user_worker} </p></label>
								</div>
								<div className="col-md-6">
									<label className="label-name">{language_arr.family_name_teacher} : </label>
									<label className="label-data"><p>{this.state.worker_family_name} </p></label>
								</div>
							</div>
						</div>
                        <div className="details-box">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            {this.state.back_to_pool_button}
                            {this.state.update_button}
							{this.state.assign_button}
							{this.state.approve_button}
							{this.state.delete_button}
							{this.state.close_button}
                          </div>
                        </div>
                        </div>
						{ /* </form> */ } 
                      </div>
                      <div className="tab-pane fade" id="nav-Messages" role="tabpanel" aria-labelledby="nav-Messages-tab">						
                        <div className="message-box">
							{this.state.all_chats}
                        </div>
                        
                        <div className="message-details-box">		
                          <div className="message-details-content">
							{this.state.single_chat_body}
                          </div>
                        </div>
                        
                      </div>
                      <div className="tab-pane fade" id="nav-Feedback" role="tabpanel" aria-labelledby="nav-Feedback-tab">
                        <div className="feedback-content">
							{this.state.all_feedbacks}
                        </div>
                      </div>
                    </div>             
                </div>
            </div>
          </div>
        </div>
		<div className="custom-pop-wrap">
			<div className="custom-pop">
			 <button type="button" className="close" onClick={this.modalClose} data-dismiss="modal">&times;</button>
				<h2>{language_arr.feedback}</h2>  
				<div className="custom-pop-btn">
					<textarea rows="4" placeholder={language_arr.enter_feedback} name="wqfeedback" id="wqfeedback" className="form-control" onChange={this.change}>{this.state.wqfeedback}</textarea> 
					<p>&nbsp;</p>
					<button type="button" onClick={this.feedbackSubmit} className="btn btn-success" id="pop-confirm">{language_arr.submit}</button>
				</div>
			</div>
		</div>
      </div>
     </div>
    );
  }
}

export default LessonDetails;
