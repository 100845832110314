import React, { Component } from 'react';
import axios from 'axios';
import './Header.css';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'id_option': '',
      'headerlogo': '',
      'loggedInStatus': false,
      'currentUser': ''
    }
    this.getHeaderOptions = this.getHeaderOptions.bind(this);
    this.getUserOptions = this.getUserOptions.bind(this);
    this.loggedOut = this.loggedOut.bind(this);
  }

  loggedOut(e){
    e.preventDefault();
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    const r = window.confirm(language_arr.logout_confirm);
    if(r == true) {
      localStorage.removeItem('loggedInUser');
      window.location.href='#/login';
	  
    }
  }

  getHeaderOptions() {
    const fd = new FormData();
    fd.append('id_option', '1');

    axios.post(window.$apiBaseUrl+'options/read.php', fd)
    .then(res => {
      this.setState({'headerlogo':res.data.records[0].option_value});
      //console.log(this.state);
    }).catch(error => {
       console.log(error);
    });
  }

  getUserOptions() {
    var userDetail = localStorage.getItem("loggedInUser");
    if(typeof userDetail !== 'undefined' && userDetail != null){
      this.setState({ loggedInStatus: true,  currentUser: JSON.parse(userDetail)});
    }
  }

  componentWillMount() {
    this.getHeaderOptions();
    this.getUserOptions();
  }

  componentDidMount() {
    this.getHeaderOptions();
    this.getUserOptions();
  }

  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
    if(this.state.loggedInStatus) {
      if(this.state.currentUser.type != 'student'){
        return (
          <div className="header fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <a className="navbar-brand" href="#/"><img src={this.state.headerlogo} className="logo-responsive" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ml-auto">
                    { /* <li className="nav-item active">
                      <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
						<a className="nav-link" href="javascript:void(0)">Become Teacher</a>
					</li> */ }
                    <li className="nav-item active">
                      <a className="nav-link" href="#/teacherlesson">{language_arr.my_lessons}</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#/lessonreport">{language_arr.report_cost}</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#/teacheraccount">{language_arr.my_account}</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#/contact">{language_arr.contact}</a>
                    </li>
                    <li><span className="devider">|</span></li>
                    <li>
                      <div className="login-sec">
                        <a href="javascript:void(0);" onClick={this.loggedOut}><i className="fa fa-sign-in" aria-hidden="true"></i> {language_arr.logout}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        );
      } else {
        return (
          <div className="header fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <a className="navbar-brand" href="#/"><img src={this.state.headerlogo} className="logo-responsive" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <a className="nav-link" href="#/"> {language_arr.home}<span className="sr-only">(current)</span></a>
                    </li>
                    { /* <li className="nav-item">
						<a className="nav-link" href="javascript:void(0)">Become Teacher</a>
					</li> */ }
                    <li className="nav-item">
                      <a className="nav-link" href="#/requestlesson">{language_arr.request_lesson}</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#/studentlesson">{language_arr.my_lessons}</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#/studentaccount">{language_arr.my_account}</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#/contact">{language_arr.contact}</a>
                    </li>
                    <li><span className="devider">|</span></li>
                    <li>
                      <div className="login-sec">
                        <a href="javascript:void(0);" onClick={this.loggedOut}><i className="fa fa-sign-in" aria-hidden="true"></i> {language_arr.logout}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        );
      }
    } else {
      return (
        <div className="header">
          <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
            <div className="container">
              <a className="navbar-brand" href="#/"><img src={this.state.headerlogo} className="logo-responsive" /></a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="#/">{language_arr.home} <span className="sr-only">(current)</span></a>
                  </li>
                  
                  <li className="nav-item">
                    <a className="nav-link" href="#/requestlesson">{language_arr.request_lesson}</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#/contact">{language_arr.contact}</a>
                  </li>
                  <li><span className="devider">|</span></li>
                  <li>
                    <div className="login-sec">
                      <a href="#/login"><i className="fa fa-sign-in" aria-hidden="true"></i> {language_arr.login_btn}</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      );
    }
  }
}

export default Header;
