import React, { Component } from 'react';
import $ from 'jquery';
import './LessonReport.css';
import Moment from 'react-moment';

class ReportList extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    
  }
  
   render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
	let lessons;
	if(this.props.lessons) {
		lessons = this.props.lessons.map((lesson,index) => {
			//console.log(lesson);
			return (
				<tr>
					<td className="text-center">{lesson.id}</td>
					<td className="text-center start-date"><Moment format="DD.MM.YYYY HH:mm">{lesson.start}</Moment></td>
					<td className="text-center start-date"><Moment format="DD.MM.YYYY HH:mm">{lesson.end}</Moment></td>
					<td className="text-center">{lesson.job_service}</td>
					<td className="text-center">{lesson.nr_person}</td>
					<td className="text-center">{lesson.job_user_giver}</td>
					<td className="text-center">{lesson.student_family_name}</td>
					<td className="text-center">{lesson.tel}</td>
					<td className="text-center cost-price">{lesson.price}</td>
					<td className="text-center cost-price">{lesson.cost}</td>
					<td className="text-center"><a href={window.$siteBaseUrl+"lessondetails/"+lesson.id}><i className="fa fa-sticky-note" aria-hidden="true"></i></a></td>
				</tr>
			);
		});
	}
	return (
		<div className="mylesson-content-box" id="tab12">
			<div id="divToPrint" className="mylesson-details">
			<div className="total|_price">{language_arr.price_values} : {this.props.total_records}, {this.props.total_price}</div>
			<div className="total|_price">{language_arr.cost_values} : {this.props.total_records}, {this.props.total_cost}</div>
			  <table className="table table-bordered table-secondary mylesson-custom-table">
				  <tr>
					  <th width="10%" className="text-center">{language_arr.job_id}</th>
						<th width="10%" className="text-center">{language_arr.start_date}</th>
						<th width="10%" className="text-center">{language_arr.end_date}</th>
						<th width="10%" className="text-center">{language_arr.subject}</th>
						<th width="10%" className="text-center">{language_arr.number_of_person}</th>
						<th width="10%" className="text-center">{language_arr.user_name}</th>
						<th width="10%" className="text-center">{language_arr.user_family_name}</th>
						<th width="10%" className="text-center">{language_arr.telephone_no}</th>
						<th width="10%" className="text-center">{language_arr.job_price}</th>
						<th width="10%" className="text-center">{language_arr.cost}</th>
						<th width="5%" className="text-center">{language_arr.view_details}</th>
					</tr>
					{lessons}
				</table>
            </div>
        </div>
	);
  }
}

export default ReportList;