/* global jsPDF */
import React, { Component } from 'react';
import $ from 'jquery';
import './LessonReport.css';
import 'react-input-range/lib/css/index.css';
import InputRange from 'react-input-range';
import ReportList from './ReportList';
import html2canvas from 'html2canvas';
import Header from '../Header/Header';

class LessonReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'lessons':[],
	  'value': { min: 0, max: 50000 },
	  'min_date': '',
	  'max_date': '',
	  'min_price': '',
	  'max_price': '',
	  'current_month':'',
	  'current_datetime':'',
	  'total_records':0,
	  'total_price':0,
	  'total_cost':0
    }
    this.getTeacherLesson = this.getTeacherLesson.bind(this);
    this.getCostrange = this.getCostrange.bind(this);
    this.getMonthId = this.getMonthId.bind(this);
    this.printDocument = this.printDocument.bind(this);
	this.getPageTitle = this.getPageTitle.bind(this);
  }
  
  getCostrange(){
	var min_price = this.state.value.min;  
	var max_price = this.state.value.max;
	this.setState({'min_price': min_price});
	this.setState({'max_price': max_price});
	setTimeout(() => {
		this.getTeacherLesson();
	},100)
  }
  
  printDocument() {
	var month_arr = [];
	var currrent_month_id = this.state.current_month; 
	month_arr = {
		'1' : 'January',
		'2' : 'February',
		'3' : 'March',
		'4' : 'April',
		'5' : 'May',
		'6' : 'June',
		'7' : 'July',
		'8' : 'August',
		'9' : 'September',
		'10': 'October',
		'11': 'November',
		'12': 'December'
	}
	var current_month = month_arr[currrent_month_id];
    const input = document.getElementById('divToPrint');
	var mydate = new Date().getTime();
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', [2000, 950]);
        //const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0,15);
		pdf.text(90, 7, "Cost Report For the month of "+current_month);
        // pdf.output('dataurlnewwindow');
        pdf.save("MISE-"+mydate+".pdf");
	});
  }
  
  getMonthId(e){
	e.preventDefault();
	var wqmonthid = e.target.getAttribute('month-id'); 
	var tempDate = new Date();
	var min_date = tempDate.getFullYear()+'-'+wqmonthid+'-01 00:00:00';
	this.setState({'current_month': wqmonthid});
	if(wqmonthid!=12){
		var next_month = Number.parseInt(wqmonthid, 10)+1;
		var max_date = tempDate.getFullYear()+'-'+next_month+'-01 00:00:00';
	}else{
		var max_date = (tempDate.getFullYear()+1)+'-01-01 00:00:00'
	}
	this.setState({'min_date': min_date});
	this.setState({'max_date': max_date});
	setTimeout(() => {
			this.getTeacherLesson();
	},100);
	
  }

  getTeacherLesson() {
		var min_date = this.state.min_date;
		var max_date = this.state.max_date;
		var min_price = this.state.min_price;
		var max_price = this.state.max_price;
		var current_datetime = this.state.current_datetime;
		//alert(min_date+"=="+max_date+"=="+min_price+"=="+max_price);
		var userDetail = JSON.parse(localStorage.getItem("loggedInUser"));
		if(typeof userDetail !== 'undefined' && userDetail != null) {
			var userType = userDetail.type;
			var userId = userDetail.id_user;
			if(userType == 'teacher'){
				const fd = new FormData();
				fd.append('min_date', min_date);
				fd.append('max_date', max_date);
				fd.append('current_datetime', current_datetime);
				fd.append('min_price', min_price);
				fd.append('max_price', max_price);
				fd.append('worker_id', userId);

				$.ajax({
					url: window.$apiBaseUrl+'job/read.php',
					type: 'POST',
					data: fd,
					dataType: 'json',
					cache: false,
					contentType: false,
					processData:false,
					success: function(data) {
						console.log(data);
						this.setState({'lessons':data.records});
						this.setState({'total_records':data.total_records});
						this.setState({'total_price':data.total_price});
						this.setState({'total_cost':data.total_cost});
							
					}.bind(this),
					error: function(xhr, status, err) {
						console.log(err);
					}
				});
			}
		}else{
			window.location.href='#/';
		}
  }
  
  getPageTitle(){
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj); 
	document.title = language_arr.page_title;
  }

  componentDidMount() {
      //this.getTeacherLesson();
		this.getPageTitle();
		var tempDate = new Date();
		var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
		var min_date = tempDate.getFullYear()+'-'+(tempDate.getMonth()+1)+'-01 00:00:00';
		var max_date = tempDate.getFullYear()+'-'+(tempDate.getMonth()+2)+'-01 00:00:00';
		var min_price = this.state.value.min;  
		var max_price = this.state.value.max;
		this.setState({'min_date': min_date});
		this.setState({'max_date': max_date});
		this.setState({'min_price': min_price});
		this.setState({'max_price': max_price});
		this.setState({'current_month': (tempDate.getMonth()+1)});
		this.setState({'current_datetime': date});
		setTimeout(() => {
			this.getTeacherLesson();
		},200)
		
  }

  render() {
	var language_obj = localStorage.getItem("language_arr");
	var language_arr = JSON.parse(language_obj);
	  //console.log(this.state.current_month);
    return (
	<div>
	<Header />
      <div className="LessonReport">
        <div className="main">
          <div className="mylesson-back mar-top">
              <div className="container-fluid">
                    <div className="mylesson-order-btn">
                      <a href="javascript:void(0)" onClick={this.printDocument}>{language_arr.print_as_pdf}</a>
                    </div>
                    <div className="mylesson-tab">
                        <div className="mylesson-nav">
                            <ul>
                              <li><a href="javascript:void(0)" month-id="1" onClick={this.getMonthId} className={(this.state.current_month=='1' ? 'tab-menu-active' : '')}>January</a></li>
                              <li><a href="javascript:void(0)" month-id="2" onClick={this.getMonthId} className={(this.state.current_month=='2' ? 'tab-menu-active' : '')}>February</a></li>
                              <li><a href="javascript:void(0)" month-id="3" onClick={this.getMonthId} className={(this.state.current_month=='3' ? 'tab-menu-active' : '')}>March</a></li>
                              <li><a href="javascript:void(0)" month-id="4" onClick={this.getMonthId} className={(this.state.current_month=='4' ? 'tab-menu-active' : '')}>April</a></li>
                              <li><a href="javascript:void(0)" month-id="5" onClick={this.getMonthId} className={(this.state.current_month=='5' ? 'tab-menu-active' : '')}>May</a></li>
                              <li><a href="javascript:void(0)" month-id="6" onClick={this.getMonthId} className={(this.state.current_month=='6' ? 'tab-menu-active' : '')}>June</a></li>
                              <li><a href="javascript:void(0)" month-id="7" onClick={this.getMonthId} className={(this.state.current_month=='7' ? 'tab-menu-active' : '')}>July</a></li>
                              <li><a href="javascript:void(0)" month-id="8" onClick={this.getMonthId} className={(this.state.current_month=='8' ? 'tab-menu-active' : '')}>August</a></li>
                              <li><a href="javascript:void(0)" month-id="9" onClick={this.getMonthId} className={(this.state.current_month=='9' ? 'tab-menu-active' : '')}>September</a></li>
                              <li><a href="javascript:void(0)" month-id="10" onClick={this.getMonthId} className={(this.state.current_month=='10' ? 'tab-menu-active' : '')}>October</a></li>
                              <li><a href="javascript:void(0)" month-id="11" onClick={this.getMonthId} className={(this.state.current_month=='11' ? 'tab-menu-active' : '')}>November </a></li>
                              <li><a href="javascript:void(0)" month-id="12" onClick={this.getMonthId} className={(this.state.current_month=='12' ? 'tab-menu-active' : '')}>December</a></li>
                          </ul>
                        </div>
                        <div className="mylesson-contentarea">
                          <div className="filter costslider">
								<InputRange
									maxValue={50000}
									minValue={0}
									value={this.state.value}
									onChange={value => this.setState({ value })} 
									onChangeComplete={this.getCostrange}
								/>
                            </div>
							<ReportList lessons={this.state.lessons} total_records={this.state.total_records} total_price={this.state.total_price} total_cost={this.state.total_cost} />
                        </div>
                    </div>	
                </div>
            </div>	
        </div>
      </div>
    </div>
    );
  }
}

export default LessonReport;
